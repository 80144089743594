/* eslint-disable */

import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Close from '../../../components/SvgIcons/Close';
import styles from './styles.module.scss';
import TripsOnMap from './trips-on-googlemap';
import displayNotification from '../../../utils/notification';

const { host, protocol } = window.location;
const dropOffUrl = `${protocol}//${host}/resources/images/dropoff_pin_same-size.png`;
const pickupOffUrl = `${protocol}//${host}/resources/images/pickup_pin_same-size.png`;
const RemoveIcon = `${protocol}//${host}/resources/images/Remove.svg`;

const RemoveSharedTrips = ({ close, bookings: propsBookings, handleNext }) => {
  // used in reording UI
  const [trips, setTrips] = useState([]);
  // used in google maps
  const [bookings, setBookings] = useState([]);

  const handleRemove = (booking) => {
    // remove pickup and dropoff booking
    let tempBookings = [...trips];
    tempBookings = [
      ...tempBookings.map((data) => ({
        ...data,
        bookings: [...data.bookings.filter(({ id }) => id !== booking.id)],
      })),
    ];

    // remove all trips where bookings legnth equal to O
    tempBookings = [...tempBookings.filter((data) => data.bookings.length > 0)];

    if (tempBookings.length > 2) {
      setTrips([...tempBookings]);
      setBookings([...tempBookings]);
    } else displayNotification('At least two or more trips are needed for this feature.', 'Delete failed');
  };

  const handleLockThisRoute = () => {
    const combinedDataByAddress = Object.values(
      trips.reduce((acc, { address, bookings, id, account_id, place_id, geoLocation }) => {
        console.log(acc);
        if (!acc[address]) {
          acc[address] = { address, bookings: [], id, account_id, place_id, geoLocation };
        }
        acc[address].bookings = acc[address].bookings.concat(bookings);

        return acc;
      }, {})
    );

    handleNext(combinedDataByAddress);
  };

  useEffect(() => {
    if (propsBookings) {
      setTrips(propsBookings);
      setBookings(propsBookings);
    }
  }, [propsBookings]);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.tripReorderContainer}>
            <div className={styles.mapContainer}>
              <TripsOnMap bookings={bookings} />
            </div>
            <div className={styles.tripsContainer}>
              <div className={styles.header}>
                <div>Edit Shared Trip</div>
                <div className={styles.closeIcon}>
                  <IconButton onClick={close}>
                    <Close className={styles['close-icon']} />
                  </IconButton>
                </div>
              </div>

              <div className={`mt-3 ${styles.tripReorder}`}>
                <div className={styles.subHeader}>Step 1: Remove booking if needed, or go directly to Step 2.</div>
                <p className="mt-2">
                  <strong className={`${styles.textError}`}>IMPORTANT NOTE:</strong>&nbsp;if updating the on-going trips
                  in real-time, please be aware that the time elapsed during the adjustment will result outdated
                  information being sent to drivers. It&apos;s you responsibility to communicate with drivers separately
                  about any further changes needed.
                </p>
                <p className="mt-3">
                  All trip status is captured at the moment when this window is opened, and will <strong>NOT</strong> be
                  updated while this window remains open and your&apos;re making changes.
                </p>

                <ul className={`mt-4 ${styles.tripList}`}>
                  {trips.map((trip, index) => {
                    const booking = trip.bookings[0];
                    const isPickup = booking.stop_type === 'pickup';
                    const time = isPickup ? booking.scheduled_pickup_time : booking.scheduled_dropoff_time;
                    const tripTime = new Date(time).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    });

                    return (
                      <li key={`${trip.id}${index}`} className={styles.tripItem}>
                        <div className={styles.tripIcon}>
                          <img src={isPickup ? pickupOffUrl : dropOffUrl} alt="tripIcon" />
                        </div>
                        <div className={styles.tripDetails}>
                          <div className={styles.tripName}>{trip.address}</div>
                          <div className={styles.tripAddress}>
                            {tripTime} {booking.passenger_firstname} {booking.passenger_lastname}{' '}
                            {booking.mobility_assistance.length
                              ? booking.mobility_assistance.map((mob) => `, ${mob}`)
                              : ''}
                          </div>
                        </div>
                        <div className={styles.removeIcon} onClick={() => handleRemove(booking)}>
                          <img src={RemoveIcon} alt="remove" />
                        </div>
                        <div className={styles.grabIcon}>
                          <img src="/resources/images/vertical-grab.svg" alt="grab" />
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="mt-4">
                  <button className="btn btn-secondary" onClick={close}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" style={{ float: 'right' }} onClick={handleLockThisRoute}>
                    User this route
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveSharedTrips;
