import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchTripsList: ["payload"],
    resetTripList: null,
    fetchPrograms: null,
    setSpin: null,
    removeSpin: null
  },
  { prefix: "TRIPS_" }
);

export const types = Types;
export default Creators;
