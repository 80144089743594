import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Info(props) {
  return (
    <SvgIcon width="90" height="90" fill="none" viewBox="0 0 90 90" {...props}>
      <g clipPath="url(#clip0_1835_607)">
        <path
          d="M45.1403 86.5264C22.3206 86.5264 3.82448 68.0258 3.82448 45.2106C3.82448 22.3953 22.3206 3.89477 45.1403 3.89477C67.9553 3.89477 86.4561 22.3955 86.4561 45.2106C86.4561 68.0257 67.9553 86.5264 45.1403 86.5264Z"
          stroke="#118BF0"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.1631 63.1914L45.1631 42.2593"
          stroke="#118BF0"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.1631 27.2305L45.1157 27.2305"
          stroke="#118BF0"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1835_607">
          <rect width="90" height="90" fill="white" transform="translate(90 90) rotate(-180)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
