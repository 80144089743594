/* eslint-disable no-lonely-if, react/no-array-index-key, react/jsx-closing-tag-location, react-hooks/exhaustive-deps,  no-nested-ternary, react-hooks/exhaustive-deps, camelcase,jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events  */

import React, { useEffect, useState, useRef, useMemo } from "react";
import IconButton from "@material-ui/core/IconButton";
import { notification, Select } from "antd";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
  InfoWindow
} from "react-google-maps";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import { Button } from "react-bootstrap";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localizedFormat from "dayjs/plugin/localizedFormat";

import "./fleet-routes-compare.css";
import "./color-status.css";
import RecommendationEngine from "../../../services/bookingRecommendationEngine";
import { rideStatus } from "./constant";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

const { host, protocol } = window.location;
const iconUrl = `${protocol}//${host}/resources/images/icon_van1x.png`;

const url = `https://maps.googleapis.com/maps/api/js?key=${
  process.env.REACT_APP_GOOGLEAPIKEY
}&v=3.exp&libraries=geometry,drawing,places`;

const color = [
  "#3ddcf7",
  "#3bafda",
  "#009886",
  "#ffaa00",
  "#f76397",
  "#c71612",
  "#7266ba"
];

const { Option } = Select;

const arrStatusFilter = [
  { key: "Booked", value: "Booked", style: "booked-text" },
  { key: "Dispatched", value: "Dispatched", style: "dispatched-text" },
  { key: "On route", value: "On route", style: "en-route-text" },
  { key: "Arrived", value: "Arrived", style: "arrived-text" },
  { key: "Pickup", value: "Pickup", style: "pick-up-text" },
  { key: "Completed", value: "Completed", style: "completed-text" },
  { key: "Canceled", value: "Canceled", style: "canceled-text" },
  { key: "No show", value: "No show", style: "no-show-text" }
];

const defaultArrStatus = [
  "Booked",
  "Dispatched",
  "On route",
  "Arrived",
  "Pickup"
];

const arrRankFilter = [
  { key: "Rank By", value: "" },
  { key: "Pickup Time", value: "pickup_time" },
  { key: "Recommendation", value: "recommendation" }
];

const selectedPickupIconMarker = new window.google.maps.MarkerImage(
  `${process.env.PUBLIC_URL}/resources/images/mappin_pickup_blue@3x.png`,
  null,
  null,
  null,
  new window.google.maps.Size(35, 40)
);

const selectedDropoffIconMarker = new window.google.maps.MarkerImage(
  `${process.env.PUBLIC_URL}/resources/images/mappin_dropoff_orange@3x.png`,
  null,
  null,
  null,
  new window.google.maps.Size(35, 40)
);

const pickupIconMarker = new window.google.maps.MarkerImage(
  `${process.env.PUBLIC_URL}/resources/images/mappin_pickup_blue@3x.png`,
  null,
  null,
  null,
  new window.google.maps.Size(25, 30)
);

const dropoffIconMarker = new window.google.maps.MarkerImage(
  `${process.env.PUBLIC_URL}/resources/images/mappin_dropoff_orange@3x.png`,
  null,
  null,
  null,
  new window.google.maps.Size(25, 30)
);

const iconMarker = new window.google.maps.MarkerImage(
  iconUrl,
  null,
  null,
  null,
  new window.google.maps.Size(18, 27)
);

const customStyles = [
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.school",
    elementType: "labels.text",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.business",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }]
  }
];

const displayNotification = description => {
  notification.warning({
    message: "Alert",
    description
  });
};

const DirectionMap = withGoogleMap(
  ({ directions, bookings, listOfBookings, composePickupTime, shuttles }) => {
    const mapRef = useRef(null);

    // Fit bounds function
    const fitBounds = () => {
      const bounds = new window.google.maps.LatLngBounds();
      listOfBookings.map(booking => {
        if (booking.pickup_latitude && booking.pickup_longitude) {
          const pickupCoordinate = new window.google.maps.LatLng(
            booking.pickup_latitude,
            booking.pickup_longitude
          );
          const dropoffCoordinate = new window.google.maps.LatLng(
            booking.dropoff_latitude,
            booking.dropoff_longitude
          );

          bounds.extend(pickupCoordinate);
          bounds.extend(dropoffCoordinate);
        }

        return booking;
      });
      setTimeout(() => {
        if (mapRef.current) {
          mapRef.current.fitBounds(bounds);
        }
      }, 500);
    };

    useEffect(() => {
      if (isEmpty(shuttles)) {
        fitBounds();
      }
    }, [listOfBookings, shuttles]);

    return (
      <GoogleMap
        ref={mapRef}
        mapTypeId="roadmap"
        options={{
          zoomControl: true,
          mapTypeControl: false,
          fullscreenControl: false,
          scaleControl: false,
          streetViewControl: false,
          controlSize: 27,
          styles: customStyles,
          trackViewChanges: false
        }}
      >
        {shuttles.map(doc => {
          const shuttle = doc.data();
          const { latitude: lat, longitude: lng, timestamp: tmp } = shuttle;
          const timestamp = tmp ? tmp.toDate() : new Date();
          const geocodeTimestamp = dayjs(new Date()).diff(timestamp, "m");

          return (
            <Marker
              key={shuttle.shuttle_id}
              position={{ lat, lng }}
              icon={iconMarker}
            >
              <InfoWindow options={{ disableAutoPan: true }}>
                <span style={{ textAlign: "center" }}>
                  <div>{shuttle.fleet_shuttle_id}</div>
                  <div>{shuttle.driver}</div>
                  <div>
                    {geocodeTimestamp} min{geocodeTimestamp > 1 ? "s" : ""} ago
                  </div>
                </span>
              </InfoWindow>
            </Marker>
          );
        })}
        {directions &&
          directions.map((direction, key) => {
            return (
              <React.Fragment key={key}>
                <DirectionsRenderer
                  directions={direction}
                  options={{
                    polylineOptions: {
                      strokeColor:
                        key === 0
                          ? "#000"
                          : color[Math.floor(Math.random() * color.length)],
                      strokeOpacity: 1,
                      strokeWeight: key === 0 ? 6 : 3,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      zIndex: key === 0 ? 999 : 9999
                    },
                    preserveViewport: true,
                    suppressMarkers: true
                  }}
                />

                <Marker
                  position={{
                    lat: bookings[key].pickup_latitude,
                    lng: bookings[key].pickup_longitude
                  }}
                  // label={`Pickup ${bookings[key].title}`}
                  zIndex={999}
                  icon={key === 0 ? selectedPickupIconMarker : pickupIconMarker}
                >
                  <InfoWindow>
                    <span style={{ textAlign: "center", fontWeight: "bold" }}>
                      <div>
                        {bookings[key].pickup_time &&
                        bookings[key].pickup_time.split(" ").length === 2
                          ? bookings[key].pickup_time
                          : `${dayjs(
                              composePickupTime(bookings[key].pickup_time)
                            ).format("LT")}`}
                      </div>
                    </span>
                  </InfoWindow>
                </Marker>

                <Marker
                  position={{
                    lat: bookings[key].dropoff_latitude,
                    lng: bookings[key].dropoff_longitude
                  }}
                  // label={`DropOff ${bookings[key].title}`}
                  zIndex={999}
                  icon={
                    key === 0 ? selectedDropoffIconMarker : dropoffIconMarker
                  }
                >
                  {!isEmpty(bookings[key]?.scheduled_dropoff_time) && (
                    <InfoWindow>
                      <span style={{ textAlign: "center", fontWeight: "bold" }}>
                        <div>{bookings[key].scheduled_dropoff_time}</div>
                      </span>
                    </InfoWindow>
                  )}
                </Marker>
              </React.Fragment>
            );
          })}
      </GoogleMap>
    );
  }
);

const BookingList = ({
  bookingList,
  handleCheckToggle,
  composePickupTime,
  vehicleTrips,
  isDriverRoute,
  viewedBooking,
  viewedRoutes
}) => {
  return (
    <List disablePadding>
      {bookingList.map((booking, idx) => {
        const vehicle = vehicleTrips.find(({ trips }) => {
          return trips.find(({ booking_ids }) =>
            booking_ids.includes(booking.booking_id)
          );
        });

        let driverName = "";
        let vehicleDriverName = "";

        if (isDriverRoute) {
          if (booking.vehicle.driver_firstname) {
            driverName = `${booking.vehicle.driver_firstname} ${
              booking.vehicle.driver_lastname
            }`;
          } else if (booking.vehicle.driver_team_name) {
            driverName = `${booking.vehicle.driver_team_name}`;
          }
        }
        if (vehicle) {
          if (vehicle.driver_team_name && vehicle.driver_team_name !== "") {
            driverName = vehicle.driver_team_name;
            vehicleDriverName = vehicle.driver_team_name;
          } else if (vehicle.driver_firstname && vehicle.driver_lastname) {
            vehicleDriverName = `${vehicle.driver_firstname} ${
              vehicle.driver_lastname
            }`;
            driverName = `${vehicle.driver_firstname} ${
              vehicle.driver_lastname
            }`;
          } else {
            driverName = "";
            vehicleDriverName = "";
          }
        }

        const timeAllowance = Math.abs(Math.round(booking.time_allowance)) || 1;
        const primary = (
          <React.Fragment>
            <Typography style={{ fontSize: "14px" }} component="span">
              {booking?.booking_id ? (
                <div>
                  Probably{" "}
                  <b>
                    {timeAllowance} minute{timeAllowance > 1 ? "s " : " "}
                    {booking.time_allowance >= 0 ? (
                      "early"
                    ) : (
                      <span style={{ color: "#ef5350" }}>late</span>
                    )}
                  </b>
                </div>
              ) : (
                <div>Available now</div>
              )}
            </Typography>
            <Typography
              className="routes-side-title"
              component="span"
              style={{
                fontSize: booking.booking_id === viewedBooking.booking_id && 18
              }}
            >
              {isDriverRoute
                ? `${booking.vehicle.shuttle_name}, ${driverName}`
                : `${dayjs(composePickupTime(booking.pickup_time)).format(
                    "LT"
                  )} ${booking.title}`}
            </Typography>
          </React.Fragment>
        );

        const secondary = (
          <React.Fragment>
            <Typography
              style={{ fontSize: "14px" }}
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
            >
              {booking?.booking_id && isDriverRoute && (
                <span style={{ display: "block" }}>
                  {"After the trip below"} <br />
                  {booking.pickup_time} - {booking.scheduled_dropoff_time}
                  <br />
                  {booking.title}
                </span>
              )}
              {booking.mobility} <br />
              {!isDriverRoute && (
                <span>
                  {vehicle
                    ? `${vehicle.shuttle_name},
                    ${vehicleDriverName}`
                    : ""}
                  <br />
                  <span style={{ display: "block" }}>
                    {(booking.travel_distance * 0.000621371).toFixed(2)} miles,{" "}
                    {(booking.travel_time / 60).toFixed()} mins
                  </span>
                  <span
                    className={
                      arrStatusFilter[
                        arrStatusFilter.findIndex(
                          ({ value }) => value === booking.status
                        )
                      ]?.style || ""
                    }
                    style={{ display: "block" }}
                  >
                    {booking.status}
                  </span>
                </span>
              )}
            </Typography>
          </React.Fragment>
        );

        return (
          <ListItem key={idx} style={{ alignItems: "flex-start" }}>
            <ListItemIcon>
              <div
                className="checkbox-container"
                style={{ position: "relative" }}
              >
                <input
                  id={isDriverRoute ? idx : booking.booking_id}
                  type="checkbox"
                  aria-label="Single checkbox Two"
                  onChange={e => handleCheckToggle(e, booking)}
                  value={isDriverRoute ? idx : booking.booking_id}
                  checked={
                    booking.booking_id
                      ? viewedRoutes.some(
                          data => data.booking_id === booking.booking_id
                        )
                      : null
                  }
                  disabled={booking.booking_id === viewedBooking.booking_id}
                />
                <label
                  key={isDriverRoute ? idx : booking.booking_id}
                  className={`checkbox-label  ${
                    booking.booking_id === viewedBooking.booking_id
                      ? "selected"
                      : ""
                  }`}
                  onClick={e => handleCheckToggle(e, booking)}
                />
              </div>
            </ListItemIcon>

            <ListItemText
              style={{
                marginTop: "-5px",
                fontSize: "14px",
                fontWeight: "bold",
                marginLeft: "-20px"
              }}
              primary={primary}
              secondary={secondary}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

const FleetRouteCompare = ({
  bookings,
  shuttles,
  composePickupTime,
  viewedBooking,
  viewedVehicleTrip,
  handleAddBooking,
  vehicleTrips,
  isDriverRoute,
  tripsOfEachVehicle,
  isMobileView,
  close
}) => {
  const DirectionsService = new window.google.maps.DirectionsService({
    suppressMarkers: true
  });

  const currLastTrip = tripsOfEachVehicle.map(trip => {
    const viewedBookingPickupTime = dayjs(viewedBooking.pickup_time, "HH:mm");
    const compareBookings = trip.bookings
      .filter(({ pickup_time }) => {
        return viewedBookingPickupTime.isSameOrAfter(
          dayjs(pickup_time, "h:mm A")
        );
      })
      .sort(
        (a, b) =>
          dayjs(b.pickup_time, "h:mm A").unix() -
          dayjs(a.pickup_time, "h:mm A").unix()
      );

    const sameBookingPickupTime = compareBookings.filter(({ pickup_time }) => {
      return viewedBookingPickupTime.isSame(dayjs(pickup_time, "h:mm A"));
    });

    let lastBooking = compareBookings[0];

    if (!isEmpty(sameBookingPickupTime)) {
      // Run recommendation engine for same pickup time;
      const nearestBookings = new RecommendationEngine({
        bookings: sameBookingPickupTime,
        reverse: isDriverRoute
      })
        .getNearestLocation(viewedBooking)
        .sort((a, b) => {
          return b.time_allowance - a.time_allowance;
        });

      [lastBooking] = nearestBookings;
    }

    const cloneTrip = { ...trip };

    delete cloneTrip.bookings;

    return {
      ...cloneTrip,
      ...lastBooking,
      checked: false,
      title: lastBooking
        ? `${lastBooking.passenger_firstname} ${lastBooking.passenger_lastname}`
        : "",
      status: lastBooking ? rideStatus(lastBooking) : "",
      mobility: lastBooking ? lastBooking.mobility_assistance.toString() : "",
      ...lastBooking
    };
  });

  const [directions, setDirections] = useState(null);
  const [viewedRoutes, setViewedRoutes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [recommendedBookingList, setRecommendedBookingList] = useState([]);
  const recommendationEngine = useMemo(
    () =>
      new RecommendationEngine({
        bookings: isDriverRoute ? currLastTrip : bookings,
        reverse: isDriverRoute
      }),
    []
  );
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(
    defaultArrStatus
  );
  const [selectedRankFilter, setSelectedRankFilter] = useState("");
  // const [conViewedBooking, setConViewedBooking] = useState({});

  useEffect(() => {
    if (viewedBooking && !isBookingInArray(viewedRoutes)) {
      const recBookings = recommendationEngine.getNearestLocation(
        viewedBooking
      );
      viewedBooking = {
        ...viewedBooking,
        title: `${viewedBooking.passenger_firstname} ${
          viewedBooking.passenger_lastname
        }`
      };
      viewedRoutes.push(viewedBooking);
      setViewedRoutes([...viewedRoutes]);
      getRoutes(viewedBooking);
      // setConViewedBooking(
      //   isDriverRoute
      //     ? viewedBooking
      //     : recBookings.find(
      //         data => data.booking_id === viewedBooking.booking_id
      //       )
      // );

      let bookingList = isEmpty(selectedStatusFilter)
        ? recBookings
        : recBookings.filter(({ status }) =>
            selectedStatusFilter.includes(status)
          );

      if (selectedRankFilter === "recommendation") {
        bookingList = bookingList.sort(
          (a, b) => a.time_allowance - b.time_allowance
        );
      } else {
        bookingList = bookingList.sort((a, b) => {
          if (a.pickup_time === undefined) return -1;
          if (b.pickup_time === undefined) return 1;

          return (
            dayjs(a.pickup_time, "h:mm A").unix() -
            dayjs(b.pickup_time, "h:mm A").unix()
          );
        });
      }

      setRecommendedBookingList(bookingList);
    }
  }, [viewedBooking]);

  const isBookingInArray = booking => {
    return (
      viewedRoutes.findIndex(
        ({ booking_id }) => booking_id === booking.booking_id
      ) > -1
    );
  };

  const getRoutes = booking => {
    DirectionsService.route(
      {
        origin: new window.google.maps.LatLng(
          booking.pickup_latitude,
          booking.pickup_longitude
        ),
        destination: new window.google.maps.LatLng(
          booking.dropoff_latitude,
          booking.dropoff_longitude
        ),
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          if (directions === null) {
            setDirections([result]);
          } else {
            directions.push(result);
            setDirections([...directions]);
          }
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const handleCheckToggle = (event, booking) => {
    if (event.target.checked && !isBookingInArray(booking)) {
      viewedRoutes.push(booking);
      setViewedRoutes([...viewedRoutes]);
      getRoutes(booking);
    } else {
      const index = viewedRoutes.findIndex(
        ({ booking_id }) => booking_id === booking.booking_id
      );

      if (index > -1) {
        viewedRoutes.splice(index, 1);
        directions.splice(index, 1);

        setViewedRoutes([...viewedRoutes]);
        setDirections([...directions]);
      }
    }
  };

  return (
    <div className="route-compare-container" role="button" tabIndex="0">
      <div className="route-compare-content">
        <div className="routes-title" style={{ zIndex: 999 }}>
          <span onClick={close} onKeyPress={close} role="button" tabIndex="0">
            <IconButton>
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              </svg>
            </IconButton>
          </span>
          <div className="map-description">
            {!isMobileView &&
              (isDriverRoute ? (
                <span>
                  The availability of each vehicle for this pickup is calculated
                  by the time it takes for the vehicle to arrive at the pickup
                  location.
                  <br />
                  <b>Note:</b> assigning this pickup may affect the later
                  pickups of a vehicle. Please adjust them accordingly.
                </span>
              ) : (
                <span>
                  The availability of this vehicle for each of the bookings is
                  calculated by the time it takes for this vehicle to travel
                  from the current drop-off location to the pickup location of
                  the booking.
                </span>
              ))}
          </div>
        </div>
        <div className="map-routes-container">
          {directions && directions.length > 0 ? (
            <DirectionMap
              shuttles={shuttles}
              composePickupTime={composePickupTime}
              listOfBookings={recommendedBookingList.concat(viewedBooking)}
              bookings={viewedRoutes}
              directions={directions}
              googleMapURL={url}
              loadingElement={<div className="map-container" />}
              containerElement={<div className="map-container" />}
              mapElement={<div style={{ height: `100%`, width: "100%" }} />}
            />
          ) : null}

          <div className="routes-container">
            <div className="routes-side-header">
              {/* Removed as per Jing's request */}
              {/* <div className="routes-side-title-container">
                {!isDriverRoute && (
                  <Typography className="routes-side-title" component="span">
                    {dayjs(
                      composePickupTime(conViewedBooking.pickup_time)
                    ).format("LT")}{" "}
                    {conViewedBooking.passenger_firstname}{" "}
                    {conViewedBooking.passenger_lastname}
                  </Typography>
                )}
                <Typography
                  style={{ fontSize: "14px" }}
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                >
                  {isDriverRoute ? (
                    <span>
                      {dayjs(
                        composePickupTime(conViewedBooking.pickup_time)
                      ).format("LT")}
                      {conViewedBooking?.scheduled_dropoff_time && " - "}
                      {conViewedBooking.scheduled_dropoff_time || ""}
                      <br />
                      {conViewedBooking.passenger_firstname}{" "}
                      {conViewedBooking.passenger_lastname}
                    </span>
                  ) : (
                    <span>
                      {isEmpty(viewedVehicleTrip)
                        ? ""
                        : `${viewedVehicleTrip.shuttle_name},
                          ${
                            viewedVehicleTrip.driver_team_name
                              ? viewedVehicleTrip.driver_team_name
                              : `${viewedVehicleTrip.driver_firstname} ${
                                  viewedVehicleTrip.driver_lastname
                                }`
                          }`}
                    </span>
                  )}
                  <span style={{ display: "block" }}>
                    {conViewedBooking.mobility}
                  </span>
                  {!isDriverRoute && (
                    <span
                      className={
                        arrStatusFilter[
                          arrStatusFilter.findIndex(
                            ({ value }) => value === conViewedBooking.status
                          )
                        ]?.style || ""
                      }
                    >
                      {conViewedBooking.status}
                    </span>
                  )}
                </Typography>
              </div> */}
              <div style={{ display: "flex" }}>
                <div
                  style={{ display: "block", marginRight: 10, width: "65%" }}
                >
                  <div
                    style={{
                      paddingBottom: "0.5em",
                      display:
                        isMobileView && !isDriverRoute ? "flex" : "block",
                      position:
                        isMobileView && !isDriverRoute ? "absolute" : "inherit"
                    }}
                  >
                    <input
                      name="search"
                      className="normal form-control"
                      onChange={e => {
                        const { value } = e.target;
                        const bookingList =
                          recommendationEngine.getCachedRecommendedBookingList;
                        const filterData = bookingList.filter(data => {
                          let search = data?.title
                            .toLowerCase()
                            .includes(value.toLowerCase());

                          if (isDriverRoute) {
                            const { vehicle } = data;
                            const driverName = vehicle.driver_id
                              ? vehicle.driver_team_name
                              : `${vehicle.driver_firstname} ${
                                  vehicle.driver_lastname
                                }`;
                            const title = `${
                              vehicle.shuttle_name
                            }, ${driverName}`;

                            search = title
                              .toLowerCase()
                              .includes(value.toLowerCase());
                          }

                          return search;
                        });

                        setSearchValue(value);
                        setRecommendedBookingList(filterData);
                      }}
                      value={searchValue}
                      placeholder={`Search ${
                        isDriverRoute ? "vehicle" : "passenger"
                      }`}
                      style={{
                        marginBottom: 5,
                        marginRight: isMobileView && !isDriverRoute ? 5 : 0
                      }}
                    />
                    <select
                      name="rankFilter"
                      className="normal form-control"
                      onChange={e => {
                        const { value } = e.target;

                        setSelectedRankFilter(value);
                        setRecommendedBookingList(
                          value === "pickup_time"
                            ? recommendedBookingList.sort((a, b) => {
                                if (a.pickup_time === undefined) return -1;
                                if (b.pickup_time === undefined) return 1;

                                return (
                                  dayjs(a.pickup_time, "h:mm A").unix() -
                                  dayjs(b.pickup_time, "h:mm A").unix()
                                );
                              })
                            : recommendedBookingList.sort(
                                (a, b) => a.time_allowance - b.time_allowance
                              )
                        );
                      }}
                      value={selectedRankFilter}
                      style={
                        selectedRankFilter
                          ? {}
                          : { color: "#9398a0", paddingLeft: 8 }
                      }
                    >
                      {arrRankFilter.map((item, idx) => {
                        return (
                          <option value={item.value} key={idx}>
                            {item.key}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    style={{
                      display: isDriverRoute ? "none" : "block",
                      marginTop: isMobileView && !isDriverRoute ? 45 : 0
                    }}
                  >
                    <Select
                      name="statusFilter"
                      mode="multiple"
                      allowClear
                      size="large"
                      placeholder="All Status"
                      bordered={false}
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                        zIndex: 9999999
                      }}
                      onChange={value => {
                        let bookingList =
                          recommendationEngine.getCachedRecommendedBookingList;

                        bookingList = isEmpty(value)
                          ? bookingList
                          : bookingList.filter(({ status }) =>
                              value.includes(status)
                            );

                        if (selectedRankFilter === "recommendation") {
                          bookingList = bookingList.sort(
                            (a, b) => a.time_allowance - b.time_allowance
                          );
                        } else {
                          bookingList = bookingList.sort((a, b) => {
                            if (a.pickup_time === undefined) return -1;
                            if (b.pickup_time === undefined) return 1;

                            return (
                              dayjs(a.pickup_time, "h:mm A").unix() -
                              dayjs(b.pickup_time, "h:mm A").unix()
                            );
                          });
                        }

                        setSelectedStatusFilter(value);
                        setRecommendedBookingList(bookingList);
                      }}
                      value={selectedStatusFilter}
                      style={{
                        width: "100%",
                        color: selectedStatusFilter ? "inherit" : "#9398a0"
                      }}
                    >
                      {arrStatusFilter.map((item, key) => (
                        <Option value={item.value} key={key}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <Button
                  variant="primary"
                  style={{
                    height: "fit-content",
                    marginTop: "auto",
                    marginBottom: 5
                  }}
                  onClick={async () => {
                    if (
                      !isDriverRoute &&
                      (viewedRoutes.length <= 1 ||
                        viewedRoutes.find(
                          ({ status }, idx) => idx !== 0 && status !== "Booked"
                        ))
                    ) {
                      displayNotification(
                        "Please select one or more bookings with the Booked status."
                      );
                    } else if (
                      isDriverRoute &&
                      (viewedRoutes.length <= 1 || viewedRoutes.length > 2)
                    ) {
                      displayNotification("Please select only one vehicle.");
                    } else if (
                      isDriverRoute &&
                      viewedBooking.ride_status !== 0
                    ) {
                      displayNotification(
                        "The booking can't be dispatched because it's either already dispatched or completed."
                      );
                      close();
                    } else {
                      let vehicleIndex = viewedVehicleTrip.index;

                      if (isDriverRoute) {
                        const getBookingIndex = bookings.findIndex(
                          data => data.booking_id === viewedBooking?.booking_id
                        );
                        bookings[getBookingIndex].checked = true;
                        vehicleIndex = viewedRoutes[1].vehicle.index;
                      }

                      const selectedBookings = isDriverRoute
                        ? bookings
                        : bookings.map(booking => {
                            const bookingIndex = viewedRoutes.findIndex(
                              ({ booking_id }) =>
                                booking.booking_id === booking_id
                            );

                            // Index 0 is not included
                            if (bookingIndex > 0) {
                              booking.checked = true;
                            }

                            return booking;
                          });

                      close();
                      await handleAddBooking(vehicleIndex, selectedBookings);
                    }
                  }}
                >
                  Dispatch
                </Button>
              </div>
            </div>
            <BookingList
              isDriverRoute={isDriverRoute}
              viewedBooking={viewedBooking}
              viewedRoutes={viewedRoutes}
              bookingList={recommendedBookingList}
              handleCheckToggle={handleCheckToggle}
              composePickupTime={composePickupTime}
              vehicleTrips={vehicleTrips}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetRouteCompare;
