/* eslint-disable import/prefer-default-export */

import axios from "axios";

export const handleChangeAddress = async value => {
  if (!value || value.trim() === "") {
    throw new Error("Please enter correct and complete address.");
  }

  try {
    const address = {
      userplace_id: null,
      addressLine1: "",
      city: "",
      state: "",
      zipcode: "",
      latitude: 0,
      longitude: 0,
      location_type: 2
    };

    const query = `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=${
      process.env.REACT_APP_GOOGLEAPIKEY
    }`.replace("#", "");
    const resp = await axios.get(encodeURI(query));

    let addressLine1 = "";
    let latitude = 0;
    let longitude = 0;
    let zipcode = "";
    let city = "";
    let state = "";

    if (resp.data.results.length) {
      latitude = resp.data.results[0].geometry.location.lat;
      longitude = resp.data.results[0].geometry.location.lng;
      resp.data.results[0].address_components.forEach(element => {
        if (element.types[0] === "street_number") {
          addressLine1 = element.long_name;
        }
        if (element.types[0] === "route") {
          addressLine1 += ` ${element.long_name}`;
        }
        if (element.types[0] === "postal_code") {
          zipcode = element.short_name;
        }
        if (
          element.types.find(elem => elem === "political") !== undefined &&
          (element.types.find(elem => elem === "locality") ||
            element.types.find(elem => elem === "sublocality") ||
            element.types.find(elem => elem === "neighborhood"))
        ) {
          city = element.long_name;
        }
        // this is a patch for now ask by Jing
        if (city.toLocaleLowerCase() === "los angeles") {
          const neighborhood = resp.data.results[0].address_components.filter(
            el => el.types.includes("neighborhood")
          );
          if (neighborhood.length > 0 && neighborhood[0].long_name) {
            city = neighborhood[0].long_name;
          }
        }

        if (
          element.types[0] === "administrative_area_level_1" &&
          element.types[1] === "political"
        ) {
          state = element.short_name;
        }
      });

      if (!addressLine1 || addressLine1 === "") {
        throw new Error("Please enter correct and complete address.");
      }

      address.addressLine1 = addressLine1;
      address.zipcode = zipcode;
      address.city = city;
      address.state = state;
      address.latitude = latitude;
      address.longitude = longitude;

      return address;
    }

    throw new Error("Please enter correct address.");
  } catch (e) {
    throw new Error(e);
  }
};

export const contractAddressAndLabel = addressObject => {
  return {
    locationType: addressObject.location_type,
    label: addressObject.label,
    addressValue: `${
      addressObject.addressLine1 ? `${addressObject.addressLine1}, ` : ""
    }${
      addressObject.city ? `${addressObject.city}, ` : ""
    } ${addressObject.state || ""} ${addressObject.zipcode || ""}`
  };
};

export const constractAddressName = addressObject => {
  return `${addressObject.label ? `${addressObject.label}, ` : ""}${
    addressObject.addressLine1 ? `${addressObject.addressLine1}, ` : ""
  }${
    addressObject.city ? `${addressObject.city}, ` : ""
  } ${addressObject.state || ""} ${addressObject.zipcode || ""}`;
};
