import React, { useState } from "react";
import CustomTextInput2 from "./CustomTextInput2";
import ReservationPortalModal from "./ReservationPortalModal";

export default function ReservationPortalLink({ account }) {
  const [showReservationPortal, setShowReservationPortal] = useState(false);

  const firstPortalUrl =
    account?.portal && account?.portal.length > 0 ? account?.portal[0].url : "";

  return (
    <>
      <CustomTextInput2
        label="Reservation Portal Link (URL)"
        value={firstPortalUrl}
        disabled
        clickable={Boolean(firstPortalUrl)}
        onClick={() => setShowReservationPortal(true)}
        style={{ marginBottom: "10px" }}
        icon={
          <div
            variant="secondary"
            style={{ color: "#118bf0", fill: "#118bf0", fontSize: "20px" }}
            className="fa fa-copy"
            tabIndex={0}
            role="button"
            onKeyDown={() => {}}
          />
        }
      />
      <ReservationPortalModal
        show={showReservationPortal}
        account={account}
        hide={() => setShowReservationPortal(false)}
      />
    </>
  );
}
