import dayjs from 'dayjs';

const bookingWidth = 194;
const hourHeightInPixel = 200;
const converterToHeight = 3.333;

const hours = [
  '12:00 am',
  '1:00 am',
  '2:00 am',
  '3:00 am',
  '4:00 am',
  '5:00 am',
  '6:00 am',
  '7:00 am',
  '8:00 am',
  '9:00 am',
  '10:00 am',
  '11:00 am',
  '12:00 pm',
  '1:00 pm',
  '2:00 pm',
  '3:00 pm',
  '4:00 pm',
  '5:00 pm',
  '6:00 pm',
  '7:00 pm',
  '8:00 pm',
  '9:00 pm',
  '10:00 pm',
  '11:00 pm',
];

const itemBackground = `
repeating-linear-gradient(
  to bottom,
  rgb(224 224 224),
  rgb(224 224 224) 1px,
  #ffffff 1px,
  #fafafa 33px
)`;

const riseStatus = [
  'booked',
  'dispatched',
  'arrived',
  'completed',
  'cancelled',
  'no-show',
  'pick-up',
  'canceled',
  'break',
  'timeblockstatus',
  'driverActionStatus',
  'arriveddo',
];

const rideStatus = (booking) => {
  if (booking.ride_status === 0 || booking.ride_status === 8) {
    return 'Booked';
  }
  if (booking.ride_status === 1) {
    if (booking.driver_arrival_time !== null && booking.driver_enroute_time !== null) return 'Arrived';
    if (booking.driver_arrival_time === null && booking.driver_enroute_time !== null) return 'On Route';
    if (booking.driver_arrival_time !== null && booking.driver_enroute_time === null) return 'Arrived';
    if (booking.driver_arrival_time === null && booking.driver_enroute_time === null) return 'Dispatched';
  }

  if (booking.ride_status === 2) {
    return 'Dispatched';
  }

  if (booking.ride_status === 3) {
    return 'Completed';
  }

  if (booking.ride_status === 6 && !booking.driver_arrival_dropoff_time) {
    return 'Pickup';
  }

  if (booking.ride_status === 6 && booking.driver_arrival_dropoff_time) {
    return 'Arrived';
  }

  if (booking.ride_status === 4 || booking.ride_status === 7) {
    return 'Canceled';
  }

  if (booking.ride_status === 5) {
    return 'No show';
  }

  if (booking.ride_status === 8) {
    return 'Time Block';
  }

  return '';
};

export const getStatusAndColor = (
  statusCode,
  driveEnrouteTime = null,
  driverArrivalTime = null,
  driverArrivalDropoffTime = null
) => {
  let obj = {};

  if (statusCode === 0 || statusCode === 8) {
    obj = {
      status: 'Booked',
      color: '#009886',
    };
  }
  if (statusCode === 1) {
    if (driverArrivalTime !== null && driveEnrouteTime !== null)
      obj = {
        status: 'Arrived',
        color: '#EE6600',
      };

    if (driverArrivalTime === null && driveEnrouteTime !== null)
      obj = {
        status: 'On route',
        color: '#FFAA00',
      };

    if (driverArrivalTime !== null && driveEnrouteTime === null)
      obj = {
        status: 'Arrived',
        color: '#EE6600',
      };

    if (driverArrivalTime === null && driveEnrouteTime === null)
      obj = {
        status: 'Dispatched',
        color: '#f76397',
      };
  }
  if (statusCode === 2) {
    obj = {
      status: 'Dispatched',
      color: '#f76397',
    };
  }

  if (statusCode === 3) {
    obj = {
      status: 'Completed',
      color: '#d7dce5',
    };
  }

  if (statusCode === 6 && !driverArrivalDropoffTime) {
    obj = {
      status: 'Pickup',
      color: '#3ddcf7',
    };
  }

  if (statusCode === 6 && driverArrivalDropoffTime) {
    obj = {
      status: 'Arrived',
      color: '#3bafda',
    };
  }

  if (statusCode === 4 || statusCode === 7) {
    obj = {
      status: 'Canceled',
      color: '#9398a0',
    };
  }

  if (statusCode === 5) {
    obj = {
      status: 'No show',
      color: '#805580',
    };
  }

  return obj;
};

const draggableBooking = [0, 1, 2];

const convertTime = (time) => {
  const now = new Date();
  const stringTime = time.split(':');
  stringTime[1] = stringTime['1'].split(' ');
  now.setHours(stringTime[0], stringTime[1]);

  return now;
};

const setTimeWithDate = (date, time) =>
  dayjs(date)
    .set('hour', dayjs(time).get('hour'))
    .set('minute', dayjs(time).get('minute'));

const dateRangeValid = (start, end) => {
  if (new Date(dayjs(start)).getTime() >= new Date(dayjs(end)).getTime()) {
    return false;
  }

  return true;
};

const chargedAtToText = (id) => {
  if (id === 1) {
    return 'At booking';
  }
  if (id === 2) {
    return 'At drop-off';
  }
  if (id === 3) {
    return 'Determined later by admin';
  }

  return '';
};

export {
  hours,
  riseStatus,
  itemBackground,
  rideStatus,
  bookingWidth,
  hourHeightInPixel,
  converterToHeight,
  draggableBooking,
  convertTime,
  setTimeWithDate,
  dateRangeValid,
  chargedAtToText,
};
