import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchFleetShuttleList: null,
    createNewShuttle: ["payload"],
    updateExistShuttle: ["payload"],
    fetchFleetShuttleInfoById: ["payload"],
    fetchLabel: null,
    fetchPrograms: null,
    resetShuttleInfo: null
  },
  { prefix: "VEHICLE_" }
);

export const types = Types;
export default Creators;
