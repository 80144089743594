/* eslint-disable no-unused-vars */

import React from "react";
import classNames from "classnames";
import { notification } from "antd";
import { Button, Modal } from "react-bootstrap";
import { useDeepCompareMemo } from "use-deep-compare";
import styles from "./styles/style.module.scss";
import { getProgramsMap } from "./utils/accounts";

export default function ReservationPortalModal({ show, hide, account }) {
  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      className={styles["bootstrap-modal"]}
      dialogClassName={styles["bootstrap-modal-dialog"]}
      backdropClassName={styles["bootstrap-modal-backdrop"]}
    >
      <Modal.Body>
        <ReservationPortal hide={hide} account={account} />
      </Modal.Body>
    </Modal>
  );
}

const ReservationPortal = ({ hide, account }) => {
  const accountPrograms = account?.program || [];

  const programsMap = useDeepCompareMemo(
    () => getProgramsMap(accountPrograms),
    [accountPrograms]
  );

  const displayNotification = description => {
    notification.warning({
      message: "Alert",
      description
    });
  };

  const copyTrackingLink = value => {
    navigator.clipboard.writeText(value);
    displayNotification("Link copied.");
  };

  return (
    <div
      className="card-box"
      style={{ padding: "50px 30px", margin: 0, fontSize: "16px" }}
    >
      <div className="text-center">
        <img
          src={`${process.env.PUBLIC_URL}/resources/images/icon_info_blue.png`}
          alt=""
        />
      </div>
      <div style={{ marginTop: "50px" }}>
        {account?.is_insurance
          ? "The reservation url below is only for its designated pay account, i.e., the account which you bill trips to. You can send this url to your account contacts for them to book trips with you. "
          : "The reservation portal url below is for private pay customers to book trips with you. You can send this url to your private pay client or post it on your service's marketing website."}
      </div>
      <div style={{ marginTop: "20px" }}>
        {account?.is_insurance ? "Pay Account" : "Account"}:{" "}
        <strong>{account?.name}</strong>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch"
        }}
      >
        {account?.portal.map(portal => (
          <div
            key={portal.program_id}
            className={classNames(styles["url-container"])}
          >
            <strong>
              URL for {programsMap.get(portal.program_id).program_name} program
            </strong>
            <div
              className={classNames(styles.url)}
              onClick={() => copyTrackingLink(portal.url)}
              tabIndex={0}
              role="button"
              onKeyDown={() => {}}
            >
              <span className={styles["url-text"]}>{portal.url}</span>

              <div className={styles["copy-button"]}>
                <div
                  variant="secondary"
                  style={{ fill: "#118bf0", fontSize: "20px" }}
                  className="fa fa-copy"
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => {}}
                />{" "}
                <span style={{ marginLeft: "8px", fontWeight: 500 }}>
                  Copy url
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: "20px" }}>
        <strong className="text-bold">Note:</strong>&nbsp;{" "}
        {account?.is_insurance
          ? "Each of your pay account has its own url. Please do NOT mix the pay account’ urls. Please do not send this url to your private pay customers or post it publicly. "
          : "Please only use this url for the private pay customers or any account that is not a pay account. All your pay account will have a different and account-specific url."}
      </div>
      <div className="text-right" style={{ marginTop: "60px" }}>
        <Button onClick={hide} variant="primary">
          Close
        </Button>
      </div>
    </div>
  );
};
