/* eslint-disable eqeqeq, consistent-return, no-nested-ternary, jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions, no-restricted-globals */

import React, { useEffect, useState, useRef } from 'react';
import { notification } from 'antd';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import styles from './customInput.module.scss';
import dropdown from './customSelect.module.scss';
import { getAvailableBookingDates } from '../../../services/rider-services/bookings';

dayjs.extend(isToday);
dayjs.extend(customParseFormat);

const displayNotification = (desc, type = 'warning') => {
  notification[type]({
    message: 'Alert',
    description: desc,
  });
};

const TimeInput = ({
  label,
  inputType = 'text',
  value: propValue,
  full = false,
  isEdit = false,
  icon,
  inputCustomClass = '',
  isRequired = false,
  readOnly = true,
  onChangeEvent = () => {},
  IconCustomClass = '',
  disabled = false,
  subLabel = '',
  useAPITime = false,
  programId = null,
  selectedDate = new Date(),
}) => {
  const [time, setTIme] = useState('');
  const [value, setValue] = useState('');
  const [showList, setShowList] = useState(false);
  const [iconClicked, setIconClicked] = useState(false);
  const [preservedValue, setPreservedValue] = useState();
  const [apiTime, setApiTime] = useState([]);

  const elemRef = useRef();

  const handleInputChange = (event) => {
    setTIme(event.target.value);
  };

  const handleSelectTime = (selectedTime) => {
    setTIme(selectedTime);
    onChangeEvent(selectedTime);
    setShowList(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      convertTime();
    } else {
      onChangeEvent();
    }
  };

  const handleOnblur = () => {
    convertTime();
    setShowList(false);
    setIconClicked(false); // fixed the double click issue on Icon
  };

  const convertTime = () => {
    if (!time || time === '') return;
    try {
      if ((parseInt(time, 10) > 24 && parseInt(time, 10) < 100) || parseInt(time, 10) < 0) {
        onChangeEvent(preservedValue || '1:00 PM');
        return displayNotification('Invalid Time.');
      }

      let match = time.match(/(\d{1,2}):?(\d{0,2})?\s?([APMapm]{2})?/);

      // 123 => 1:23 AM
      // 123a => 1:23 AM
      // 123p => 1:23 PM
      // 1234a => 12:34 AM
      // 1234p => 12:34: PM

      if (!isNaN(time) && time.length === 3) {
        match = time.match(/(\d{1,1}):?(\d{0,2})?\s?([APMapm]{2})?/);
      } else if (time.length <= 5) {
        if (!isNaN(time.substring(0, 3)) && isNaN(time.substring(3, 4))) {
          match = time.match(/(\d{1,1}):?(\d{0,2})?\s?([APMapm]{1})?/);
        } else if (!isNaN(time.substring(0, 4)) && time.substring(4, 5) !== '' && isNaN(time.substring(4, 5))) {
          match = time.match(/(\d{1,2}):?(\d{0,2})?\s?([APMapm]{1})?/);
        }
      }

      if (!match) {
        onChangeEvent(preservedValue || '1:00 PM');
        return;
      }

      const [, hours, minutes, ampm] = match;
      let hr = hours;
      let min = minutes;

      if (min == 60) {
        min = '00';
        hr = parseInt(hr, 10) + 1;
      } else if (min > 60) {
        onChangeEvent(preservedValue || '1:00 PM');
        return displayNotification('Invalid Time.');
      }

      // check hour, convert 630 => 06:30
      if (hr > 24) {
        min = `${hr[1]}${min[0]}`;
        hr = `0${hr[0]}`;
      }

      // Convert to 12-hour format
      let hours24 = parseInt(hr, 10);
      const its24 = hours24 === 24;
      if (ampm) {
        if (ampm.toLowerCase() === 'pm' || ampm.toLowerCase() === 'p') {
          hours24 = (hours24 % 12) + 12;
        } else if (ampm.toLowerCase() === 'am' || ampm.toLowerCase() === 'a') {
          hours24 %= 12;
        }
      }

      // Convert to 12-hour format
      const ampmResult = its24 ? 'AM' : hours24 >= 12 ? 'PM' : 'AM';
      const hours12 = hours24 % 12 || 12;

      // Display the converted time
      const outputTime = `${hours12}:${min?.padStart(2, '0') || '00'} ${ampmResult}`;

      onChangeEvent(outputTime);
    } catch (e) {
      console.log(e);

      onChangeEvent(preservedValue || '1:00 PM');
    }
  };

  const generateTimeSlots = () => {
    const timeSlots = [];
    const timeInterval = 15;

    for (let hour = 0; hour <= 23; hour += 1) {
      for (let minute = 0; minute < 60; minute += timeInterval) {
        const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        const period = hour < 12 ? 'AM' : 'PM';
        const formattedMinute = minute === 0 ? '00' : minute;
        const timeSlot = `${formattedHour}:${formattedMinute} ${period}`;
        timeSlots.push(timeSlot);
      }
    }
    return timeSlots;
  };

  const handleShowList = () => {
    elemRef.current.focus();
    if (!iconClicked) {
      setShowList(true);
      setIconClicked(true);
    } else {
      setShowList(false);
      setIconClicked(false);
    }
  };

  const handleGetTimeFromApi = async () => {
    try {
      const params = {
        day_of_month: selectedDate.getDate(),
        month: selectedDate.getMonth() + 1,
        program_id: programId,
        year: selectedDate.getFullYear(),
      };
      const resp = await getAvailableBookingDates(params);

      if (resp.status === 'success') {
        console.log(resp);
        const timeArray = resp.data?.available_booking_dates?.[0].time;
        if (timeArray) {
          setApiTime(timeArray);
        }
      } else {
        displayNotification(resp.message?.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  useEffect(() => {
    if (showList) {
      const e = document.getElementById('time__list');
      if (e) {
        e.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [showList]);

  useEffect(() => {
    if (!preservedValue) {
      setPreservedValue(value);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if ((useAPITime, programId)) {
      handleGetTimeFromApi();
    }
    // eslint-disable-next-line
  }, [programId, useAPITime]);

  return (
    <div
      ref={elemRef}
      tabIndex="-1"
      className={`${styles.input} ${full ? styles['input-full'] : ''}`}
      onBlur={handleOnblur}
    >
      {label && label !== '' && (
        <div className={styles.label}>
          {label} {subLabel.trim() !== '' && <span className={styles.subLabel}>{subLabel}</span>}{' '}
          {isRequired && <span className={styles.required}>*</span>}
        </div>
      )}
      <div className={` ${dropdown.dropdown__check_list} ${showList ? dropdown.visible : ''}`}>
        {icon && isEdit ? (
          <>
            <input
              type={inputType}
              className={`${styles['text-input']} ${inputCustomClass}`}
              value={value}
              onPaste={(e) => e.preventDefault()}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              readOnly={readOnly}
              disabled={disabled}
            />
            {icon !== '' && (
              <span
                className={`${styles['input-icon']} ${IconCustomClass}`}
                onClick={handleShowList}
                role="button"
                tabIndex={-1}
                onKeyDown={() => {}}
              >
                <img src={icon} alt="icon" />
              </span>
            )}
          </>
        ) : (
          <input
            type={inputType}
            className={`${styles['text-input']} ${inputCustomClass}`}
            value={value}
            readOnly={readOnly}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
        )}

        <ul className={`${dropdown.items} ${dropdown.time__dropdown}`}>
          {!useAPITime &&
            !programId &&
            generateTimeSlots().map((timeSlot) => (
              <li key={`${timeSlot}`} onMouseDown={() => handleSelectTime(timeSlot)}>
                {timeSlot}
              </li>
            ))}
          {useAPITime &&
            programId &&
            apiTime.map((tValue) => {
              const timeNow = dayjs(tValue.booking_startTime, 'YYYY-MM-DD HH:mm').format('h:mm A');
              return (
                <li key={`${timeNow}`} onMouseDown={() => handleSelectTime(timeNow)}>
                  {timeNow}
                </li>
              );
            })}
        </ul>
        <div id="time__list">&nbsp;</div>
      </div>
    </div>
  );
};

export default TimeInput;
