import { createReducer } from "reduxsauce";

import { types } from "./actions";

const INITIAL_STATE = {
  loading: false,
  fleetShuttleList: [],
  fleetShuttleInfoById: {},
  newFleetStatus: false,
  label: "",
  labelError: false,
  labelErrContent: "",
  programItems: []
};

export default createReducer(INITIAL_STATE, {
  [types.SET_STATE]: (state, { payload }) => ({ ...state, ...payload })
});
