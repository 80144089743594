import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import PlacesAutocomplete, { geocodeByPlaceId } from "react-places-autocomplete";
import styles from "./customInput.module.scss";


const displayNotification = desc => {
  notification.warning({
    message: "Alert",
    description: desc
  });
};


const PlacesAutoComplete = ( { label, updateLocation, value: defaultVal }) => {

  const [location, setLocation] = useState('')

  const onChange = async (value, placeId = null) => {
    let add = value;
    if (placeId) {
      const completeLoc = await geocodeByPlaceId(placeId);
      const zipCode = completeLoc[0].address_components.find(
        addr => addr.types[0] === "postal_code"
      )?.short_name;
      if (zipCode) { // append zip code
        add = `${value}, ${zipCode}`;
      }
    }

    setLocation(add);
    updateLocation(add);
  };


  const handleError = (e) => {
    if (e === 'ZERO_RESULTS') {
      displayNotification('Please enter correct address.');
      updateLocation(null);
    }
  }
  useEffect(() => {
    setLocation(defaultVal)
  }, [defaultVal])
  return (
    <>
      <PlacesAutocomplete
        onError={handleError}
        value={location}
        onChange={onChange}
        onSelect={onChange}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps
        }) => (
          <div className={`${styles.input} ${styles["input-full"]}`}>
            <div className={styles.label}>
              {label}
            </div>
            <input
              {...getInputProps({
                className: `${styles["text-input"]}`
              })}
            />
            <div
              className=""
              style={{
                position: "absolute",
                zIndex: 99999,
                width: "100%"
              }}
            >
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      padding: 5
                    }
                  : {
                      backgroundColor: "rgb(236 232 232)",
                      cursor: "pointer",
                      padding: 5
                    };
                return (
                  <div
                    id="address"
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                    key={suggestion.description}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  )
}

export default PlacesAutoComplete