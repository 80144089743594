import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './style.module.scss';

export default function NewReservationButton() {
  return (
    <Link to="/rider-services/reservations/new">
      <button type="button" className={classNames('btn', 'btn-primary', styles['new-reservation-button'])}>
        <span className={styles['new-reservation-plus']}>+</span>
        <span className={styles['new-reservation-text']}>New reservation</span>
      </button>
    </Link>
  );
}
