import React from "react";

import styles from "./customInput.module.scss";

const CustomTextArea = ({
  label,
  value,
  full = false,
  isEdit = false,
  icon,
  iconClick = () => {},
  inputCustomClass = "",
  rows = 2,
  onChangeEvent = () => { },
  readOnly = true,
  disablePaste = true,
}) => {
  return (
    <div
      className={`${styles.input} ${full ? styles["input-full"] : ""}`}
      style={{ marginBottom: "-5px" }}
    >
      <div className={styles.label}>{label}</div>
      <textarea
        name="Text1"
        rows={rows}
        className={`${styles["text-area"]} ${inputCustomClass}`}
        defaultValue={value}
        onPaste={(e) => { if (disablePaste) e.preventDefault() }}
        readOnly={readOnly}
        onChange={onChangeEvent}
      />
      {icon && isEdit && (
        <span
          className={styles["input-icon"]}
          onClick={iconClick}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <img src={icon} alt="icon" />
        </span>
      )}
    </div>
  );
};

export default CustomTextArea;
