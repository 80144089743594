import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchCommunityMemberList: null,
    fetchMemberInfoById: ["payload"],
    updateExistMember: ["payload"],
    // createNewMember: ["payload"],
    resetMemberList: null,
    resetMemberInfo: null,
    setSpinner: null,
    unsetSpinner: null
  },
  { prefix: "MEMBERS_" }
);

export const types = Types;
export default Creators;
