import React, { useEffect, useContext, useState } from 'react';

import { ToggleSwipeContext } from '../../../../hooks/useIsMobile';
import styles from './style.module.scss';

const ToggleSwipe = () => {
  const [label, setLabel] = useState('');
  const { isSwipe, isMobile, toggleSwipe } = useContext(ToggleSwipeContext);

  useEffect(() => {
    if (isSwipe) {
      setLabel('Activate drag & drop');
    } else {
      setLabel('De-activate drag & drop');
    }
  }, [isSwipe]);

  const handleToggle = () => {
    toggleSwipe();
  };

  const isDispatchBoard =
    window.location.href.endsWith('/fleet/dispatch') || window.location.href.endsWith('/fleet/dispatch/');

  return (
    <>
      {isMobile && isDispatchBoard && (
        <div className={styles.container}>
          <button
            type="button"
            onClick={handleToggle}
            className={` ${styles.toggle__button} ${!isSwipe && styles.toggle__deactivate}`}
          >
            {label}
          </button>
        </div>
      )}
    </>
  );
};

export default ToggleSwipe;
