import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchBookingsList: ["payload"],
    createNewBooking: ["payload"],
    updateExistBooking: ["payload"],
    fetchBookingInfoById: ["payload"],
    checkValidateBookingEditTime: ["payload"],
    fetchLabel: null,
    resetBookingInfo: null,
    sendRequestPhoneVerifyCode: ["payload"],
    verifyUserPhone: ["payload"],
    populateBookingEditTime: ["payload"],
    resetBookingChangeStatus: null,
    initLocalStorageNewUserInfo: null,
    resetBookingList: null,
    cancelBooking: ["payload"],
    cancelBookingForReschedule: ["payload"],
    recreateBooking: ["payload"],
    setSpinner: null,
    unsetSpinner: null,
    resetGotoBookinglistFlag: null,
    setUserInfo: ["payload"]
  },
  { prefix: "BOOKINGS_" }
);

export const types = Types;
export default Creators;
