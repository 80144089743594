/* eslint-disable no-nested-ternary, consistent-return, camelcase */

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { notification } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import DuetModal, { DuetLeftRightButtons } from '../../../components/Modals/modal';
import styles from '../../../components/Modals/modal.module.scss';
import CustomTextArea from '../../../components/Elements/TextInput/CustomTextArea';
import DriverActionInfo from './driver-action-info';
import PlacesAutoComplete from '../../../components/Elements/TextInput/PlacesAutoComplete';
import { handleChangeAddress } from '../../../lib/googleApiAddress.';
import { saveDriverAction } from '../../../services/drivers';
import { adjustTimeRange, setDBTime } from '../../../lib/common';
import { dateRangeValid } from './constant';
import TimeInput from '../../../components/Elements/TextInput/TimeInput';

dayjs.extend(duration);

const displayNotification = (desc) => {
  notification.warning({
    message: 'Alert',
    description: desc,
  });
};

const DriverAction = ({
  close,
  cancel,
  show,
  tripId,
  driverId,
  driverTeamId,
  shuttleId,
  programId,
  dispatchDate,
  combineAddedVehicle,
  vehicleTrips,
  booking, // if this got a value it means its an update flow
  setSpinner,
  unsetSpinner,
}) => {
  const [details, setDetails] = useState(booking?.admin_note || '');
  const [location, setLocation] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [hasError, setHasError] = useState(false);

  const [time, setTime] = useState({
    start: null,
    end: null,
  });

  const reset = () => {
    setLocation('');
    setDetails('');
    setShowInfo(false);
    setStartTime('');
    setEndTime('');
    setTime({
      start: null,
      end: null,
    });
  };

  const handleSaveStartTime = (val) => {
    try {
      setTime({ ...time, start: setDBTime(val, dispatchDate) });
      setHasError(false);
    } catch {
      setHasError(true);
    }
    setStartTime(val);
  };

  const handleSaveEndTime = (val) => {
    try {
      setTime({ ...time, end: setDBTime(val, dispatchDate) });
      setHasError(false);
    } catch {
      setHasError(true);
    }
    setEndTime(val);
  };

  const validateTimeValues = (start, end) => {
    if (!start || !end) {
      displayNotification('Please input a valid time range.');
      return false;
    }
    if (!dateRangeValid(start, end)) {
      displayNotification('Please input a valid time range.');
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    // validate time range
    if (!validateTimeValues(time.start, time.end)) {
      return;
    }
    if (hasError) {
      return displayNotification('Please input a valid time.');
    }
    if (location === '') {
      return displayNotification('Please add a valid location.');
    }

    if (details.trim() === '') {
      return displayNotification('Please add an action details.');
    }

    setSpinner();
    try {
      const address = await handleChangeAddress(location);

      const params = {
        trip_id: tripId,
        to_delete: false,
        start_time: dayjs(time.start).format('YYYY-MM-DDTHH:mm'),
        end_time: dayjs(time.end).format('YYYY-MM-DDTHH:mm'),
        addressLine: address.addressLine1,
        city: address.city,
        state: address.state,
        zipcode: address.zipcode,
        latitude: address.latitude,
        longitude: address.longitude,
        action_detail: details,
      };

      if (!tripId) {
        // create new driver action, include required params
        params.shuttle_id = shuttleId;
        params.program_id = programId;
        params.driver_id = driverId;
        params.driver_team_id = driverTeamId;
      }

      const resp = await saveDriverAction(params);
      if (resp.status === 'success') {
        const { trip } = resp.data;
        if (tripId) {
          // update current booking trip
          const shuttleIndex = vehicleTrips.findIndex(
            ({ shuttle_id, driver_id, driver_team_id }) =>
              shuttle_id === trip.shuttle?.shuttle_id &&
              driver_id === trip.driver_id &&
              driver_team_id === trip.driver_team_id
          );

          if (shuttleIndex > -1) {
            const tripIndex = vehicleTrips[shuttleIndex].trips.findIndex(({ trip_id }) => trip_id === trip.trip_id);

            if (tripIndex > -1) {
              vehicleTrips[shuttleIndex].trips[tripIndex] = {
                ...vehicleTrips[shuttleIndex].trips[tripIndex],
                first_pickup_time: trip.first_pickup_time,
                last_dropoff_time: trip.last_dropoff_time,
                action_detail: trip.action_detail,
                first_pickup_address: trip.first_pickup_address,
              };
              vehicleTrips[shuttleIndex].trips[tripIndex].bookings[0] = {
                ...vehicleTrips[shuttleIndex].trips[tripIndex].bookings[0],
                admin_note: trip.admin_note,
                scheduled_pickup_time: startTime,
                scheduled_dropoff_time: endTime,
              };
              vehicleTrips[shuttleIndex].trips = [...vehicleTrips[shuttleIndex].trips];
              combineAddedVehicle([...vehicleTrips]);
            }
          }
          displayNotification('Successfully updated Driver action.');
        } else {
          const tripIndex = vehicleTrips.findIndex(
            ({ shuttle_id, driver_id, driver_team_id }) =>
              shuttle_id === trip.shuttle?.shuttle_id &&
              driver_id === trip.driver_id &&
              driver_team_id === trip.driver_team_id
          );

          if (tripIndex > -1) {
            const { trips: newTrips } = vehicleTrips[tripIndex];
            trip.driver_action = 'exist';
            trip.booking_ids = [trip.bookings[0].booking_id];
            delete trip.shuttle;
            trip.drivershifts = [];
            trip.program_timezone = 'America/Los_Angeles';
            const newBooking = [...trip.bookings];
            if (newBooking.length > 0) {
              const pickUpHour = trip.bookings[0].scheduled_pickup_time.split(':'); // remove 0 from hour , 01:12 PM => 1:12 PM
              const dropOffHour = trip.bookings[0].scheduled_dropoff_time.split(':');
              let pTime = `${parseInt(pickUpHour[0], 10)}:${pickUpHour[1]}`;
              if (pickUpHour[0] === '00' || pickUpHour[0] === '0') {
                pTime = `12:${pickUpHour[1]}`;
              }
              newBooking[0].pickup_time = pTime;
              trip.bookings[0].scheduled_pickup_time = pTime;
              trip.bookings[0].scheduled_dropoff_time = `${parseInt(dropOffHour[0], 10)}:${dropOffHour[1]}`;
              trip.bookings = [...newBooking];
            }

            newTrips.push({ ...trip });

            vehicleTrips[tripIndex].trips = [...newTrips];
            vehicleTrips[tripIndex] = { ...vehicleTrips[tripIndex] };

            combineAddedVehicle([...vehicleTrips]);
          }
          displayNotification('Successfully created Driver action.');
        }

        reset();
        close();
      } else {
        displayNotification(resp.message.message);
      }
    } catch (e) {
      if (e.message) {
        displayNotification(e.message);
      }
    }

    return unsetSpinner();
  };

  useEffect(() => {
    if (booking && booking.trip) {
      console.log('BOOKING', booking);
      setSpinner();
      setDetails(booking.admin_note);
      setStartTime(booking.scheduled_pickup_time);
      setEndTime(booking.scheduled_dropoff_time);
      setTime({
        start: setDBTime(booking.scheduled_pickup_time, dispatchDate),
        end: setDBTime(booking.scheduled_dropoff_time, dispatchDate),
      });
      setLocation(booking?.trip?.first_pickup_address);
      setTimeout(() => {
        unsetSpinner();
      }, 500);
    }
    // eslint-disable-next-line
  }, [booking]);

  useEffect(() => {
    if (startTime && endTime) {
      let originalBooking = null;
      if (booking) {
        const { scheduled_dropoff_time, scheduled_pickup_time } = booking;
        originalBooking = {
          start: setDBTime(scheduled_pickup_time, dispatchDate),
          end: setDBTime(scheduled_dropoff_time, dispatchDate),
        };
      }

      const newEndTime = adjustTimeRange(startTime, endTime, 'start', dispatchDate, originalBooking);
      if (newEndTime && newEndTime !== 'reset') {
        setTimeout(() => {
          handleSaveEndTime(newEndTime); // hh:mm AM/PM
        }, 100);
      }
    }

    // eslint-disable-next-line
  }, [startTime]);

  useEffect(() => {
    if (startTime && endTime) {
      const newStartTime = adjustTimeRange(startTime, endTime, 'end', dispatchDate);
      if (newStartTime && newStartTime !== 'reset') {
        setTimeout(() => {
          handleSaveStartTime(newStartTime); // hh:mm AM/PM
        }, 100);
      } else if (newStartTime === 'reset') {
        handleSaveStartTime('12:00 AM');
        handleSaveEndTime(`1:00 AM`); // hh:mm AM/PM
      }
    }

    // eslint-disable-next-line
  }, [endTime]);

  return (
    <>
      {showInfo ? (
        <DriverActionInfo show={showInfo} close={() => setShowInfo(false)} />
      ) : !showInfo ? (
        <DuetModal show={show} dialogClassName={styles.modal_width} close={close}>
          <div className="edit-modal-content">
            <div className="driver-action-title">
              Add or edit a driver action{' '}
              <InfoOutlined className={styles.icon} onClick={() => setShowInfo(!showInfo)} />
            </div>
            <br />
            <div className="driver-action-title notes">
              <b>Note:</b> this is visible to driver.
            </div>
            <div className={styles.form__content}>
              <div
                style={{
                  marginBottom: '20px',
                  marginTop: '40px',
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <TimeInput
                  label="Start time"
                  subLabel="(enter a value or use the drop-down list)"
                  value={startTime}
                  icon="/resources/images/Time_Square.png"
                  isEdit
                  placeHolder=""
                  readOnly={false}
                  onChangeEvent={(value) => handleSaveStartTime(value)}
                />
                <TimeInput
                  label="End time"
                  subLabel="(enter a value or use the drop-down list)"
                  value={endTime}
                  icon="/resources/images/Time_Square.png"
                  isEdit
                  placeHolder=""
                  readOnly={false}
                  onChangeEvent={(value) => handleSaveEndTime(value)}
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <CustomTextArea
                  disablePaste={false}
                  readOnly={false}
                  label="Action detail (This will be displayed as a note in the Driver app.)"
                  value={details}
                  isEdit
                  iconClick={() => {}}
                  onChangeEvent={(e) => setDetails(e.target.value)}
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <PlacesAutoComplete
                  value={location}
                  updateLocation={(loc) => {
                    setLocation(loc);
                  }}
                  label="Location (if you don't have one, enter your office address)"
                />
              </div>
            </div>
          </div>
          <DuetLeftRightButtons customStyles={{ marginTop: '40px' }}>
            <div className={styles.left__button}>
              <Button variant="secondary" className={styles.button} onClick={cancel}>
                Cancel
              </Button>
            </div>
            <div className={styles.right__button}>
              <Button variant="primary" className={styles.button} style={{ marginLeft: 10 }} onClick={handleSave}>
                Save
              </Button>
            </div>
          </DuetLeftRightButtons>
        </DuetModal>
      ) : null}
    </>
  );
};

export default DriverAction;
