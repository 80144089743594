import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Question(props) {
  return (
    <SvgIcon width="87" height="87" fill="none" viewBox="0 0 87 87" {...props}>
      <path
        d="M43.5004 52.2004V43.5004H46.4004C48.7078 43.5004 50.9207 42.5838 52.5522 40.9522C54.1838 39.3207 55.1004 37.1078 55.1004 34.8004V34.2204C55.1004 32.0668 54.2449 30.0015 52.7221 28.4787C51.1993 26.9559 49.134 26.1004 46.9804 26.1004H43.5004C41.193 26.1004 38.9801 27.017 37.3486 28.6486C35.717 30.2801 34.8004 32.493 34.8004 34.8004M40.6004 60.9004H46.4004M43.5004 84.1004C38.1687 84.1004 32.8893 83.0502 27.9634 81.0099C23.0376 78.9696 18.5619 75.979 14.7919 72.2089C11.0218 68.4389 8.03122 63.9632 5.99088 59.0373C3.95054 54.1115 2.90039 48.8321 2.90039 43.5004C2.90039 38.1687 3.95054 32.8893 5.99088 27.9634C8.03122 23.0376 11.0218 18.5619 14.7919 14.7919C18.5619 11.0218 23.0376 8.03122 27.9634 5.99088C32.8893 3.95054 38.1687 2.90039 43.5004 2.90039C54.2682 2.90039 64.5949 7.17788 72.2089 14.7919C79.8229 22.4058 84.1004 32.7326 84.1004 43.5004C84.1004 54.2682 79.8229 64.5949 72.2089 72.2089C64.5949 79.8229 54.2682 84.1004 43.5004 84.1004Z"
        stroke="#118BF0"
        strokeWidth="5"
      />
    </SvgIcon>
  );
}
