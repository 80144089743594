import React from 'react';

import RemoveSharedTrips from './remove-trip';

const EditSharedTripFlow = () => {
  return (
    <>
      <RemoveSharedTrips />
    </>
  );
};

export default EditSharedTripFlow;
