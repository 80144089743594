import { all, put, call } from "redux-saga/effects";

import { getLeftMenuData } from "services/menu";
import { getTopData } from "services/topData";
import { types } from "./actions";

export function* GET_DATA() {
  const menuLeftData = yield call(getLeftMenuData);
  const topData = yield call(getTopData);
  yield put({
    type: types.SET_STATE,
    payload: {
      menuLeftData,
      topData
    }
  });
}

export default function* rootSaga() {
  yield all([
    GET_DATA() // run once on app load to fetch menu data
  ]);
}
