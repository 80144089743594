/* eslint-disable */
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import DuetModal2, { DuetLeftRightButtons, DuetIcon } from '../../../components/Modals/modal2';
import modalStyles from '../../../components/Modals/modal2.module.scss';
import './update-booking-time.css';

export default function UpdateBookingTripTimeModal({
  show,
  viewedBooking,
  updateBooking,
  updateAdminNote,
  availableTBookingTime,
  closeModal,
}) {
  return (
    <DuetModal2 show={show} onClickClose={closeModal}>
      <UpdateBookingTripTime
        viewedBooking={viewedBooking}
        updateBooking={updateBooking}
        updateAdminNote={updateAdminNote}
        availableTBookingTime={availableTBookingTime}
        closeModal={closeModal}
      />
    </DuetModal2>
  );
}

const UpdateBookingTripTime = ({
  viewedBooking,
  updateBooking,
  updateAdminNote,
  availableTBookingTime,
  closeModal,
}) => {
  const [selectedTime, setSelectedTime] = useState(' ');
  const [originalSelectedTime, setOriginalSelectedTime] = useState('');
  const [adminNote, setAdminNote] = useState(' ');

  const handleSave = () => {
    if (availableTBookingTime && originalSelectedTime !== selectedTime) {
      if (selectedTime === ' ') {
        setSelectedTime(null);
      }

      // check if selected time is with "will call"
      const sTime = JSON.parse(selectedTime);
      if (sTime.txt.includes('Will call')) {
        sTime.txt = '11:59 PM';
        setSelectedTime(JSON.stringify({ ...sTime }));
      }

      if (sTime) {
        if (availableTBookingTime && availableTBookingTime[0].time.length < 2) {
          // this will be triggerd on pass bookings
          updateAdminNote(viewedBooking, adminNote);
        } else {
          updateBooking({ sTime, adminNote });
        }
      }
    } else {
      updateAdminNote(viewedBooking, adminNote);
    }
  };

  useEffect(() => {
    if (originalSelectedTime === '' && selectedTime.trimRight() !== '') {
      setOriginalSelectedTime(selectedTime);
    }
  }, [selectedTime]);

  useEffect(() => {
    setAdminNote(viewedBooking?.admin_note);
    const lastTimeOfTheDay = {
      val: '2022-01-21T23:59:00PDT',
      txt: 'Will call - 11:59 PM',
    };
    if (viewedBooking && availableTBookingTime && availableTBookingTime[0]) {
      setAdminNote(viewedBooking.admin_note);

      const selected = availableTBookingTime[0].time.filter((t) =>
        t.txt.startsWith(`${dayjs(`Jan 20 1988 ${viewedBooking.pickup_time}`).format('h:mm A')} -`)
      );

      if (selected[0]) {
        setSelectedTime(JSON.stringify(selected[0]));
      } else {
        const temp = {
          val: `${viewedBooking.pickup_time} - ${viewedBooking.scheduled_dropoff_time}`,
          txt: `${dayjs(`Jan 20 1988 ${viewedBooking.pickup_time}`).format('h:mm A')} ${
            viewedBooking.scheduled_dropoff_time
              ? ` - ${dayjs(`Jan 20 1988 ${viewedBooking.scheduled_dropoff_time}`).format('h:mm A')}`
              : ''
          }`,
        };

        availableTBookingTime[0].time.push(temp);

        availableTBookingTime[0].time.sort((a, b) => {
          const now = new Date(`Jan 20 1988 ${a.txt.split(' -')[0]}`);
          const next = new Date(`Jan 20 1988 ${b.txt.split(' -')[0]}`);

          return now.getTime() - next.getTime();
        });
        setSelectedTime(JSON.stringify(temp));
      }

      // insert will call  data
      if (
        !availableTBookingTime[0].time.includes(JSON.stringify(lastTimeOfTheDay)) &&
        availableTBookingTime[0].time.length >= 2
      ) {
        const lastIndex = availableTBookingTime[0].time.length - 1;

        // if the last value is one of these two, just change it with "will call"
        // to avoid duplicates.
        if (
          availableTBookingTime[0].time[lastIndex].val.includes('11:59 PM') ||
          availableTBookingTime[0].time[lastIndex].val.includes('23:59')
        ) {
          availableTBookingTime[0].time[lastIndex] = lastTimeOfTheDay;
          setSelectedTime(JSON.stringify(lastTimeOfTheDay));
        } else {
          availableTBookingTime[0].time.push(lastTimeOfTheDay);
        }
      }
      // insert will call
      const indexOfWillCall = availableTBookingTime[0].time.findIndex(({ txt }) => txt === 'Will call - 11:59 PM');

      if (indexOfWillCall === -1) {
        availableTBookingTime[0].time.push(lastTimeOfTheDay);
      }
    } else if (!availableTBookingTime) {
      availableTBookingTime = [];
      availableTBookingTime[0] = {
        time: lastTimeOfTheDay,
      };
    }
  }, [viewedBooking]);

  return (
    <>
      <div style={{ fontSize: '16px' }}>
        <DuetIcon type="question" />

        <div>
          <div className="form-group">
            <label htmlFor="select-time">Pickup Time</label>
            <select
              disabled={availableTBookingTime && availableTBookingTime[0] && availableTBookingTime[0].time.length === 1}
              id="select-time"
              name="select-time"
              className={selectedTime ? 'normal form-control duetBlue' : 'warning border-danger form-control duetBlue'}
              onChange={(e) => {
                setSelectedTime(e.target.value);
              }}
              value={selectedTime}
              style={{ color: 'rgba(0, 0, 0, 0.65)' }}
              disabled={!availableTBookingTime}
            >
              {availableTBookingTime && availableTBookingTime[0] && availableTBookingTime[0].time.length > 1 && (
                <option value="">Select from the dropdown list</option>
              )}

              {availableTBookingTime?.map((bookingTime) => {
                return bookingTime.time.map((time) => {
                  return (
                    <option key={Math.random()} value={JSON.stringify(time)}>
                      {time.txt}
                    </option>
                  );
                });
              })}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="tripAdminNote">Admin to Driver Note</label>
            <textarea
              className="normal form-control duetBlue"
              rows="3"
              id="tripAdminNote"
              onChange={(e) => {
                setAdminNote(e.target.value);
              }}
              value={adminNote}
              style={{ color: 'rgba(0, 0, 0, 0.65)' }}
            />
          </div>
        </div>

        <DuetLeftRightButtons>
          <Button
            variant="secondary"
            className={classNames(modalStyles.button, modalStyles['first-button'], 'w-80')}
            onClick={closeModal}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className={classNames(modalStyles.button, modalStyles['second-button'], 'w-80', 'm-l-12')}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </DuetLeftRightButtons>
      </div>
    </>
  );
};
