import React from 'react';
import { connect } from 'react-redux';
import settingsAction from '../../../redux/settings/actions';
import styles from './style.module.scss';

const Overlay = ({ isMainLayout, isMenuCollapsed, isMobileView, changeSetting }) => {
  const showOverlay = isMainLayout && isMobileView && !isMenuCollapsed;

  const handleClickOverlay = () => {
    changeSetting({
      setting: 'isMenuCollapsed',
      value: true,
    });
  };

  if (!showOverlay) {
    return null;
  }

  return (
    <div onClick={handleClickOverlay} role="button" onKeyDown={() => {}} tabIndex={0} className={styles.overlay} />
  );
};

const mapStateToProps = ({ settings }) => ({
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
});

const mapDispatchToProps = {
  changeSetting: settingsAction.changeSetting,
};

const withReduxConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withReduxConnect(Overlay);
