import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import configureStore, { history, bootstrapSaga } from './configureStore';
import Router from './route';

import './global.scss';
import './lib/firebase';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const target = document.querySelector('#root');
const store = configureStore();
bootstrapSaga();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Router history={history} />
    </BrowserRouter>
  </Provider>,
  target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

// Being used in MainLayout redux
export { store, history };
