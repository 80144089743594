import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchBookingHistoryList: ["payload"],
    fetchUserProgramName: null,
    fetchBookingInfoById: ["payload"],
    resetBookingHistoryList: null,
    getDailyRideshipData: ["payload"],
    setSpinner: null,
    unsetSpinner: null
  },
  { prefix: "BOOKINGHISTORY_" }
);

export const types = Types;
export default Creators;
