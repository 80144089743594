import React, { useEffect, useState } from "react";

import styles from "./style.module.scss";
import customInput from "../../../components/Elements/TextInput/customInput.module.scss";

const SelectFeePlan = ({
  save,
  handleClose,
  feePlans,
  accountName,
  selectedFee
}) => {
  const [selectedFeePlan, setSelectedFeePlan] = useState();

  const handleSave = () => {
    if (!selectedFeePlan) {
      save(null);
    } else {
      save(selectedFeePlan);
    }
  };

  useEffect(() => {
    if (selectedFee && selectedFee.length > 0) {
      setSelectedFeePlan(selectedFee[0].pricing_id);
    }
  }, [selectedFee]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          className={styles["content-data-box"]}
          style={{ marginTop: 0, textAlign: "center" }}
        >
          <img
            className={styles.info__icon}
            src={`${
              process.env.PUBLIC_URL
            }/resources/images/icon_info_blue.svg`}
            alt=""
          />
          <div className={styles.fee__plan_info}>
            A pay account is an account that you have a contract with and can
            bill for the trips. Each pay account can have its own fee plan or
            share a fee plan with other pay accounts. If a pay account is not
            assigned with a fee plan, we will apply the default fee plan to it.
            The default fee plan is typically used for the private pay clients
            of the service program.
            <br />
            <br />
            <strong>Note:</strong> if you need to create a new fee plan, please
            go to the menu &gt; Programs &gt; Fee Plans.
            <br /> <br />
            Assign a fee plan to the account <strong>{accountName}</strong>
          </div>

          <div
            className={styles["inputs-container"]}
            style={{ textAlign: "left", fontSize: "16px", marginTop: "30px" }}
          >
            <div className={customInput.input}>
              <div className={customInput.label}>Fee Plan</div>
              <select
                className="form-control gray"
                style={{ width: "100%" }}
                value={selectedFeePlan}
                name="fieldName"
                onChange={e => {
                  setSelectedFeePlan(e.target.value);
                }}
                onSelect={e => {
                  setSelectedFeePlan(e.target.value);
                }}
              >
                <option value="">Select a fee plan</option>
                {feePlans.map(fee => {
                  return (
                    <option key={fee.pricing_id} value={fee.pricing_id}>
                      {fee.pricing_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div
            className={styles.button__container}
            style={{ marginTop: "30px", marginBottom: "30px" }}
          >
            <button
              type="button"
              className={styles.add__more}
              style={{ float: "unset" }}
              onClick={() => handleClose(true)}
            >
              Cancel
            </button>
            <button type="button" className={styles.close} onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectFeePlan;
