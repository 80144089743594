/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import parse from "./_parse";

export async function fetchTripsList(params) {
  try {
    const lists = await parse.Cloud.run("v1_webapp_get_trip_history", params);
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("fetchTripsList() = ", JSON.stringify(error));
    return error;
  }
}

export async function fetchPrograms() {
  try {
    const data = await parse.Cloud.run("v1_webapp_get_user_program_name");
    return { status: 1, data };
  } catch (error) {
    console.log("fetchPrograms() = ", JSON.stringify(error));
    return { status: 0, data: error };
  }
}

export async function updateCurrentTrip(param) {
  try {
    const data = await parse.Cloud.run("v1_webapp_update_current_trip", param);
    return data;
  } catch (error) {
    console.log("updateCurrentTrip() = ", JSON.stringify(error));
    return { status: 0, data: error };
  }
}

export async function getCurrentTripData(param) {
  try {
    const data = await parse.Cloud.run(
      "v1_webapp_get_current_trip_data",
      param
    );
    return data;
  } catch (error) {
    console.log("getCurrentTripData() = ", JSON.stringify(error));
    return { status: 0, data: error };
  }
}
