/* eslint-disable camelcase */
import { all, takeEvery, put, call } from "redux-saga/effects";
import { fetchUsersList } from "../../../services/account-management/users";
import { types } from "./actions";
import { types as commonTypes } from "../../common/actions";

export function* FETCH_USERS_LIST({ payload }) {
  // console.log(Date.now());
  if (!payload.bIsIterate)
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        loading: true
      }
    });

  const response = yield call(fetchUsersList);

  if (response) {
    if (response.status === "success") {
      yield put({
        type: types.SET_STATE,
        payload: {
          userList: response.data
        }
      });
    } else {
      yield put({
        type: commonTypes.SET_STATE,
        payload: {
          isError: true,
          errContent: response.message.message
        }
      });
    }
  }

  if (!payload.bIsIterate)
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        loading: false
      }
    });
}

export function* RESET_USERS_LIST() {
  yield put({
    type: types.SET_STATE,
    payload: {
      userList: {}
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_USERS_LIST, FETCH_USERS_LIST),
    takeEvery(types.RESET_USERS_LIST, RESET_USERS_LIST),
    null
  ]);
}
