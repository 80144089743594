import React from 'react';
import dayjs from 'dayjs';
import { IconButton } from '@material-ui/core';
import "./booking-details.css";

const LunchBreakDetails = ({
  booking,
  deleteLunchBreak,
  updateLunchBreak,
  close,
  selectedDate
}) => {
  const handleOverLayClick = e => {
    if (e.target.className === "booking__details--overlay") {
      close();
    }
  };
  return (
    <div
      className="booking__details--overlay"
      onClick={handleOverLayClick}
      onKeyPress={handleOverLayClick}
      role="button"
      tabIndex="0"
    >
      <div className="booking__details--modals">
        <div className="booking__details_view">
          <div className="booking_details_container">
            <div
              className="booking__details--header"
              style={{ paddingTop: 0 }}
            >
              <span
                onClick={() => deleteLunchBreak(booking)}
                onKeyPress={() => deleteLunchBreak(booking)}
                role="button"
                tabIndex="0"
              >
                <IconButton>
                  <i className="fa fa-trash-o" />
                </IconButton>
              </span>
              <span
                onClick={() => updateLunchBreak(booking)}
                onKeyPress={() => updateLunchBreak(booking)}
                role="button"
                tabIndex="0"
              >
                <IconButton>
                  <i className="fa fa-edit" />
                </IconButton>
              </span>
              <span
                onClick={close}
                onKeyPress={close}
                role="button"
                tabIndex="0"
              >
                <IconButton>
                  <svg
                    className="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                </IconButton>
              </span>
            </div>
            <div className="booking__details--content">
              <div className="booking__details__name">
                {`${booking.passenger_firstname} ${booking.passenger_lastname
                  }`}
              </div>

              <div className="booking__details__date">
                {dayjs(selectedDate).format("dddd, LL")}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LunchBreakDetails;