import React from "react";

export default class SidebarCollapseTrigger extends React.Component {
  render() {
    const { onTrigger } = this.props;

    return (
      <button
        className="button-menu-mobile open-left waves-light waves-effect"
        type="button"
        onClick={onTrigger}
      >
        <i className="mdi mdi-menu" />
      </button>
    );
  }
}
