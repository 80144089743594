/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import store from 'store';
// import { Scrollbars } from "react-custom-scrollbars";
import _ from 'lodash';
import classNames from 'classnames';
import settingsAction from 'redux/settings/actions';
import styles from './style.module.scss';
// import styles from "./old-style.module.scss";

const { Sider } = Layout;
const { Divider } = Menu;

const mapStateToProps = ({ menu, settings, auth }) => ({
  menuData: menu.topData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  is_admin: auth.is_admin,
});
const mapDispatchToProps = {
  changeSetting: settingsAction.changeSetting,
};

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class MenuLeftBlue extends React.Component {
  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.menu.openedKeys') || [],
  };

  componentWillMount() {
    this.setSelectedKeys(this.props);
  }

  componentDidMount() {
    // const { isMenuCollapsed } = this.props;
    // $.Sidemenu.init(); // eslint-disable-line
    document.body.classList.toggle('fixed-left', true);
    // document.body.classList.toggle("fixed-left", isMenuCollapsed);
  }

  componentWillReceiveProps(newProps) {
    // const { isMenuCollapsed } = newProps;
    if (newProps.isMenuCollapsed && !newProps.isMobileView) {
      this.setState({
        openedKeys: [],
      });
    }

    this.setSelectedKeys(newProps);

    // document.body.classList.toggle("fixed-left", !isMenuCollapsed);
    // document.body.classList.toggle("fixed-left", isMenuCollapsed);
  }

  setSelectedKeys = (props) => {
    const { menuData } = this.props;
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);
    const selectedItem = _.find(flattenItems(menuData, 'children'), ['url', props.location.pathname]);
    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    });
  };

  isCollapse = (is_admin, privilege) => {
    for (let index = 0; index < privilege.length; index++) {
      if (is_admin.includes(privilege[index])) return true;
    }
    return false;
  };

  onCollapse = (value, type) => {
    const { changeSetting, isMenuCollapsed } = this.props;
    if (type === 'responsive' && isMenuCollapsed) {
      return;
    }

    changeSetting({
      setting: 'isMenuCollapsed',
      value: !isMenuCollapsed,
    });

    this.setState({
      openedKeys: [],
    });
  };

  handleDropClick = (menu) => {
    const { openedKeys } = this.state;

    this.onOpenChange(openedKeys.includes(menu.key) ? [] : [menu.key]);
  };

  onOpenChange = (openedKeys) => {
    store.set('app.menu.openedKeys', openedKeys);
    this.setState({
      openedKeys,
    });
  };

  handleClick = (e) => {
    const { changeSetting, isMobileView, isMobileMenuOpen } = this.props;
    store.set('app.menu.selectedKeys', [e.key]);
    // close menu if mobile menu opened
    if (isMobileView) {
      setTimeout(() => {
        changeSetting({
          setting: 'isMobileMenuOpen',
          value: !isMobileMenuOpen,
        });
      }, 500);
    }

    this.setState({
      selectedKeys: [e.key],
      // openedKeys: e.keyPath
    });
  };

  generateMenuItems = (selectedKeys, openedKeys) => {
    const { menuData = [], isMenuCollapsed, is_admin } = this.props;
    const generateItem = (item) => {
      const { key, title, icon, badge } = item;
      if (item.divider) {
        return <Divider key={Math.random()} />;
      }
      if (item.groupHeader) {
        return (
          <li key={key} className="menu-title">
            {title}
          </li>
        );
      }

      if (item.url) {
        const activedClass = classNames({
          active: selectedKeys.includes(key),
        });

        return (
          <li key={key} className={activedClass}>
            {!item.toLinkOutsource ? (
              <Link
                to={item.url}
                className={`${activedClass} waves-effect waves-primary`}
                onClick={() => this.handleClick(item)}
              >
                {icon && <i className={`${icon}`} />}
                <span> {title} </span>
                {badge && <span className={`badge-${badge.type} badge pull-right`}>{badge.title}</span>}
              </Link>
            ) : (
              <a href={item.url} className={`${activedClass} waves-effect waves-primary`} target="_new">
                {icon && <i className={`${icon}`} />}
                <span> {title} </span>
                {badge && <span className={`badge-${badge.type} badge pull-right`}>{badge.title}</span>}
              </a>
            )}
          </li>
        );
      }

      return (
        <li key={key}>
          <Link to="javascript:void(0)" className="waves-effect waves-primary">
            {icon && <span className={`${icon}`} />}
            <span> {title} </span>
            {badge && <span className={`badge-${badge.type} badge pull-right`}>{badge.title}</span>}
          </Link>
        </li>
      );
    };

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <a key={menuItem.key} className="waves-effect waves-primary">
              {menuItem.icon && <i className={`${menuItem.icon} ${styles.icon}`} />}
              <span className={styles.title}>{menuItem.title}</span>
            </a>
          );
          return (
            <ul className="list-unstyled" title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </ul>
          );
        }
        return generateItem(menuItem);
      });

    return menuData.map((menuItem) => {
      if (menuItem.children) {
        const isOpened = openedKeys.includes(menuItem.key);
        const isActive = menuItem.children.find((c) => selectedKeys.includes(c.key));
        const active = classNames({
          active: isActive,
        });
        const subdrop = classNames({
          subdrop: isOpened,
        });
        const subdropStyle = isOpened && !isMenuCollapsed ? { display: 'block' } : {};

        return this.isCollapse(is_admin, menuItem.is_admin) ? (
          <li key={menuItem.key} className={`${active} has_sub`}>
            <a
              href="javascript:void(0);"
              className={`${subdrop} ${active} waves-effect waves-primary`}
              onClick={() => this.handleDropClick(menuItem)}
            >
              {menuItem.icon && <i className={`${menuItem.icon}`} />}
              <span>{menuItem.title}</span>
              {menuItem.badge && (
                <span className={`badge-${menuItem.badge.type} badge pull-right`}>{menuItem.badge.title}</span>
              )}
              {!menuItem.badge && <span className="menu-arrow" />}
            </a>
            <ul className="list-unstyled" style={subdropStyle}>
              {generateSubmenu(menuItem.children)}
            </ul>
          </li>
        ) : null;
      }
      return this.isCollapse(is_admin, menuItem.is_admin) ? generateItem(menuItem) : null;
    });
  };

  render() {
    const { selectedKeys, openedKeys } = this.state;
    const { isMobileView, isMenuCollapsed } = this.props;
    const menuSettings = /* isMobileView
      ? {
          width: 240,
          collapsible: false,
          collapsed: false,
          collapsedWidth: 0,
          onCollapse: this.onCollapse
        }
      : */ {
      width: 240,
      collapsible: true,
      collapsed: isMenuCollapsed,
      collapsedWidth: 70,
      onCollapse: this.onCollapse,
      breakpoint: 'lg',
      trigger: null,
    };

    const menu = this.generateMenuItems(selectedKeys, openedKeys);
    const { addPadding } = this.props;
    return (
      <Sider
        {...menuSettings}
        className="left side-menu"
        style={{ zIndex: '900', paddingTop: addPadding ? '53px' : 'auto' }}
      >
        {/* <Scrollbars
          className={
            isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop
          }
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: "4px",
                borderRadius: "inherit",
                backgroundColor: "#c5cdd2",
                left: "1px"
              }}
            />
          )}
          autoHide
        > */}
        <div
          className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
          style={{ overflow: isMenuCollapsed ? 'inherit' : 'auto' }}
        >
          <div id="sidebar-menu">
            <ul>{menu}</ul>
            {/* <Menu
              onClick={this.handleClick}
              selectedKeys={selectedKeys}
              openKeys={openedKeys}
              onOpenChange={this.onOpenChange}
              mode="inline"
              className={styles.navigation}
            >
              {menu}
            </Menu> */}
          </div>
        </div>
        {/* </Scrollbars> */}
      </Sider>
    );
  }
}

export default MenuLeftBlue;
