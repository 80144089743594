import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchNemsList: null,
    fetchNemInfoById: ["payload"],
    resetNemList: null
  },
  { prefix: "NEM_" }
);

export const types = Types;
export default Creators;
