import { createReducer } from "reduxsauce";

import { types } from "./actions";

const INITIAL_STATE = {
  loading: false,
  bookingList: [],
  bookingInfoById: {},
  newBookingStatus: false,
  label: "",
  bookingObj: {},
  bCancelQuestionSection: null,
  bPhoneVerifyQuestionSection: null,
  bCancelComfirmSection: null,
  bCancelBookingForReschedule: null,
  bGotoBookingList: null,
  bookingIDForReschedule: null,
  existingUserInfo: {},
  selectedMobilityAssistance: ""
};

export default createReducer(INITIAL_STATE, {
  [types.SET_STATE]: (state, { payload }) => ({ ...state, ...payload })
});
