import React from "react";

import "./styles.css";

const LoaderVersionOne = ({ message }) => {
  return (
    <div className="loading">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        />
      </svg>

      <div>{message}</div>
    </div>
  );
};

export default LoaderVersionOne;
