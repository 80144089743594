import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

class RecommendationEngine {
  constructor(config) {
    this.bookings = config?.bookings?.sort(
      (a, b) =>
        dayjs(a.pickup_time, "HH:mm").unix() -
        dayjs(b.pickup_time, "HH:mm").unix()
    );
    this.isHaversine = config.isHaversine || false;
    this.reverse = config.reverse || false;
    this.closest = [];
    this.mode = config.mode || null;
  }

  // Convert Degress to Radians
  Deg2Rad = deg => (deg * Math.PI) / 180;

  PythagorasEquirectangular = (lat1, lon1, lat2, lon2) => {
    lat1 = this.Deg2Rad(lat1);
    lat2 = this.Deg2Rad(lat2);
    lon1 = this.Deg2Rad(lon1);
    lon2 = this.Deg2Rad(lon2);

    const R = 6371; // km
    const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    const y = lat2 - lat1;
    const d = Math.sqrt(x * x + y * y) * R;

    return d;
  };

  // Haversine formula
  HaversineFormula = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = this.Deg2Rad(lat2 - lat1);
    const dLon = this.Deg2Rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.Deg2Rad(lat1)) *
        Math.cos(this.Deg2Rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km

    return d;
  };

  get getCachedRecommendedBookingList() {
    return [...this.closest];
  }

  getNearestLocation = currBooking => {
    const computeLocation = this[
      this.isHaversine ? "HaversineFormula" : "PythagorasEquirectangular"
    ];
    const latitude =
      currBooking[this.reverse ? "pickup_latitude" : "dropoff_latitude"];
    const longitude =
      currBooking[this.reverse ? "pickup_longitude" : "dropoff_longitude"];
    let minDif = computeLocation(
      latitude,
      longitude,
      currBooking[this.reverse ? "dropoff_latitude" : "pickup_latitude"],
      currBooking[this.reverse ? "dropoff_longitude" : "pickup_longitude"]
    );
    const closest = [];
    const bookings = [];

    for (let index = 0; index < this.bookings?.length; index += 1) {
      const data = this.bookings[index];
      const dif = computeLocation(
        latitude,
        longitude,
        data[this.reverse ? "dropoff_latitude" : "pickup_latitude"],
        data[this.reverse ? "dropoff_longitude" : "pickup_longitude"]
      );
      // add 1 day on dropoff time if booking pickup time is 11:59 PM
      const timeAdj =
        (this.reverse && currBooking.pickup_time === "11:59 PM") ||
        data.pickup_time === "11:59 PM"
          ? 1
          : 0;
      const dropoffTime = dayjs(
        this.reverse
          ? data.scheduled_dropoff_time
          : currBooking.scheduled_dropoff_time,
        "h:mm A"
      )
        .add(timeAdj, "day")
        .unix();
      const pickupTime = dayjs(
        this.reverse ? currBooking.pickup_time : data.pickup_time,
        "H:mm"
      ).unix();
      const timeAllowance = (pickupTime - dropoffTime) / 60;

      // add 8km for distance allowance
      // based on 15 mins late allowance
      minDif = dif + 8;

      // average speed of car is 20mi/hr or 32 km/hr or 0.53km/min
      // 15 mins delay is allowed
      // max time allowance is 2 hours
      const newBookingObj = {
        ...data,
        status: data.status,
        // convert distance km to miles
        distance: +(dif * 0.6214).toFixed(2) || 0,
        time_allowance: +(timeAllowance - dif / 0.53).toFixed(2) || 0,
        r_time_travel: +(dif / 0.53).toFixed(2) || 0
      };

      if (
        this.mode === "restricted" &&
        dif < minDif &&
        dropoffTime < pickupTime &&
        dif / 0.53 <= timeAllowance + 15 &&
        timeAllowance <= 120
      ) {
        closest.push(newBookingObj);
      } else {
        bookings.push(newBookingObj);
      }
    }

    this.closest =
      this.mode === "restricted"
        ? [
            ...closest.sort(
              (a, b) =>
                Math.abs(a.time_allowance) - Math.abs(b.time_allowance) ||
                a.distance - a.distance
            ),
            ...bookings
          ]
        : [...closest, ...bookings].sort((a, b) => {
            if (a.pickup_time === undefined) return -1;
            if (b.pickup_time === undefined) return 1;

            return (
              dayjs(a.pickup_time, "HH:mm").unix() -
                dayjs(b.pickup_time, "HH:mm").unix() ||
              Number(a.distance) - Number(b.distance)
            );
          });

    return this.closest;
  };
}

export default RecommendationEngine;
