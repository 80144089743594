import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchBillingsList: null,
    resetBillingsList: null
  },
  { prefix: "BILLINGS_" }
);

export const types = Types;
export default Creators;
