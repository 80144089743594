import { notification } from 'antd';

const displayNotification = (description, message = 'Alert', type = 'warning') => {
  if (notification[type]) {
    notification[type]({
      message,
      description,
    });
  }
};

export default displayNotification;
