import { createReducer } from "reduxsauce";

import { types } from "./actions";

const INITIAL_STATE = {
  isError: false,
  errContent: "",
  loading: false
};

export default createReducer(INITIAL_STATE, {
  [types.SET_STATE]: (state, { payload }) => ({ ...state, ...payload })
});
