import React from 'react';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Form } from 'react-bootstrap';

const HeatMapButtons = ({ handleClick, showHeatMap, handleClickInfo, iconClass = '', toggleClass = '' }) => {
  return (
    <>
      <InfoOutlined className={`heat__map_info_icon ${iconClass}`} onClick={handleClickInfo} />

      <div className={`heatmap-icon ${toggleClass}`}>
        <Form.Check // prettier-ignore
          type="radio"
          id="heatMapToggle"
          label=" Heatmap"
          checked={showHeatMap}
          onClick={handleClick}
          onChange={handleClick}
        />
      </div>
    </>
  );
};

export default HeatMapButtons;
