import React from "react";
import moment from "moment";
import styles from "./style.module.scss";

const year = moment().year();

const Footer = () => (
  <footer className={styles.footer}>
    {year} © Dashboard Story, Inc. All rights reserved.{" "}
    <span className="hide-phone">www.duetinc.com</span>
    <div>{process.env.REACT_APP_Ver}</div>
  </footer>
);

export default Footer;
