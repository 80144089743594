import React from "react";
// import { Dropdown } from "antd";
import styles from "./style.module.scss";

export default class NotificationMenu extends React.Component {
  constructor(props) {
    super(props);

    this.menu = (
      <div className={`${styles.menu} notification-list`}>
        <div className="dropdown-item noti-title">
          <h5 className="font-16">
            <span className="badge badge-danger float-right">5</span>
            Notification
          </h5>
        </div>

        <a href="javascript:void(0);" className="dropdown-item notify-item">
          <div className="notify-icon bg-success">
            <i className="mdi mdi-comment-account" />
          </div>
          <p className="notify-details">
            Robert S. Taylor commented on Admin
            <small className="text-muted">1 min ago</small>
          </p>
        </a>

        <a href="javascript:void(0);" className="dropdown-item notify-item">
          <div className="notify-icon bg-info">
            <i className="mdi mdi-account" />
          </div>
          <p className="notify-details">
            New user registered.<small className="text-muted">1 min ago</small>
          </p>
        </a>

        <a href="javascript:void(0);" className="dropdown-item notify-item">
          <div className="notify-icon bg-danger">
            <i className="mdi mdi-airplane" />
          </div>
          <p className="notify-details">
            Carlos Crouch liked <b>Admin</b>
            <small className="text-muted">1 min ago</small>
          </p>
        </a>

        <a
          href="javascript:void(0);"
          className="dropdown-item notify-item notify-all"
        >
          View All
        </a>
      </div>
    );
  }

  render() {
    return (
      // <Dropdown overlay={this.menu} trigger={["click"]}>
      <div className="nav-link dropdown-toggle arrow-none waves-light waves-effect">
        <a
          // className="nav-link dropdown-toggle arrow-none waves-light waves-effect"
          // data-toggle="dropdown"
          href="https://dashboardstory.zendesk.com/hc/en-us/articles/360037344114-What-s-new"
          target="_new"
          // role="button"
          // aria-haspopup="false"
          // aria-expanded="false"
        >
          <i className="mdi mdi-bell noti-icon" />
          <span className="badge badge-pink noti-icon-badge">4</span>
        </a>
      </div>
      // </Dropdown>
    );
  }
}
