/* eslint-disable import/prefer-default-export */

import React from "react";

export const renderReservationPortalLink = handleClickReservationPortalLink => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
      }}
    >
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          handleClickReservationPortalLink();
        }}
      >
        <i className="fa fa-link" />
      </a>
    </div>
  );
};

export const getProgramsMap = programs => {
  const programsMap = new Map();

  programs.forEach(program => {
    programsMap.set(program.program_id, { ...program });
  });

  return programsMap;
};
