/* eslint-disable  no-unneeded-ternary, react/no-array-index-key, camelcase, no-nested-ternary, consistent-return */
/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { notification } from 'antd';

import styles from './style.module.scss';
import CustomInput from '../../../components/Elements/TextInput/CustomInput';
import CustomTextArea from '../../../components/Elements/TextInput/CustomTextArea';
import ReservationPortalLink from './ReservationPortalLink';

import { fetchAccountInfoByID, UpdateAccountByID } from '../../../services/rider-services/bookings';
import customInput from '../../../components/Elements/TextInput/customInput.module.scss';
import Loader from '../../../components/LayoutComponents/Loader';
import AddNewContact from './AddNewContact';
import AddressModal from './AddressModal';
import { constractAddressName, contractAddressAndLabel, handleChangeAddress } from '../../../lib/googleApiAddress.';
import SelectFeePlan from './SelectFeePlan';

import { fetchPrograms } from '../../../services/vehicle';
import { getProgramFeePlans } from '../../../services/programs/services';

const EditIcon = '/resources/images/Edit.png';

const defaultDataUpdate = {
  fieldName: '',
  value: '',
  label: '',
  indexValue: 0,
};

const ModifyAccountModal = ({ close, accountId, programs: progs, updateList }) => {
  const [account, setAccount] = useState();
  const [userPrograms, setPrograms] = useState(progs);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(defaultDataUpdate);
  const [isLoading, setIsLoading] = useState(false);
  const [inputType, setInputType] = useState('text');
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [isAddNewContact, setIsAddNewContact] = useState(false);
  const [feePlans, setFeePlans] = useState([]);
  const [showSelectFeePlan, setShowSelectFeePlan] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState();
  const [payType, setPayType] = useState('no');
  const [selectPayType, setSelectPayType] = useState(false);

  const payTypeRef = useRef(null);

  useEffect(() => {
    if (!progs) {
      handleGetProgram();
    }
  }, [progs]);

  useEffect(() => {
    if (accountId && userPrograms?.length) {
      handleGetAccount();
    }

    // eslint-disable-next-line
  }, [accountId, userPrograms]);

  const checkIfProgramUser = (programArr) =>
    programArr.filter(
      (program) => userPrograms.findIndex((userProgram) => userProgram.program_id === program.program_id) > -1
    );

  const handleGetProgram = async () => {
    setIsLoading(true);
    try {
      const resp = await fetchPrograms();

      setPrograms(resp);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const handleGetFeePlans = async (program_id) => {
    try {
      setIsLoading(true);
      const resp = await getProgramFeePlans({ program_id });

      if (resp.status === 'success') {
        setFeePlans(resp.data.pricings);
      } else {
        displayNotification(resp.message.message);
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const handleGetAccount = async () => {
    setIsLoading(true);
    try {
      const resp = await fetchAccountInfoByID({ account_id: accountId });

      if (resp.status === 'success') {
        // set empty array value if alt_address not  set from api
        if (!resp.data.alt_address) {
          resp.data.alt_address = [];
        }

        if (resp.data.insurance_id !== '' && resp.data.pricing.length > 0) {
          setSelectedPricing(resp.data.pricing[0].pricing_id);
          setPayType('yes');
        }
        const accnt = resp.data;
        accnt.program = [...checkIfProgramUser(accnt.program)];

        if (accnt.program.length > 0) {
          handleGetFeePlans(accnt.program[0].program_id);
        }

        setAccount(accnt);
        updateList(accnt);
      } else {
        displayNotification('Failed to fetch this account.');
        close();
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const displayNotification = (description) => {
    notification.warning({
      message: 'Account',
      description,
    });
  };

  const updateValue = (data) => {
    setUpdateData({
      fieldName: data.name,
      value: data.value,
      label: data.label,
      indexValue: data.indexValue || 0,
      objectValue: data.objectValue || {},
    });
    setIsUpdate(true);
    setIsAddNewAddress(false);
    setIsAddNewContact(false);
  };

  const handleChangeValue = async (value, placeId = null) => {
    if (inputType === 'address') {
      let add = value;

      if (placeId) {
        const completeLoc = await geocodeByPlaceId(placeId);
        const zip_code = completeLoc[0].address_components.find((addr) => addr.types[0] === 'postal_code')?.short_name;
        if (zip_code) {
          add = `${value}, ${zip_code}`;
        }
      }

      setUpdateData({ ...updateData, value: add });
    } else {
      setUpdateData({ ...updateData, value });
    }
  };

  const handleAddNewContactToAccount = async (newContact) => {
    if (newContact.trim() === '') {
      return displayNotification('Please add valid contact.');
    }
    try {
      setIsLoading(true);
      account.contact.push(newContact.trim());
      const params = {
        account_id: account.account_id,
        contact: [...account.contact],
      };
      const resp = await UpdateAccountByID(params);
      if (resp.status === 'success') {
        displayNotification('Successfully added new contact.');
        setIsAddNewContact(false);
      } else {
        displayNotification(`Error adding new contact: ${resp.message.message}`);
      }
    } catch (e) {
      displayNotification(e.message);
    }

    setIsLoading(false);
  };

  const handleAddNewAddress = () => {
    setIsUpdate(false);
    setIsAddNewAddress(true);
    setIsAddNewContact(false);
  };

  const handleAddNewContact = () => {
    setIsUpdate(false);
    setIsAddNewAddress(false);
    setIsAddNewContact(true);
  };

  const handleCancelUpdate = () => {
    setIsUpdate(false);
    setUpdateData(defaultDataUpdate);
  };

  const handleSaveNewAddress = async (newAddress, label, locationType = 3) => {
    try {
      setIsLoading(true);
      const newAddr = await handleChangeAddress(newAddress);
      newAddr.label = label;
      newAddr.location_type = parseInt(locationType, 10);
      account.alt_address.push(newAddr);

      const params = {
        account_id: account.account_id,
        alt_address: [...account.alt_address],
      };
      const resp = await UpdateAccountByID(params);
      if (resp.status === 'success') {
        displayNotification('Successfully added new address.');

        setIsAddNewAddress(false);
      } else {
        displayNotification(`Error adding new alt_address: ${resp.message.message}`);
      }
    } catch (e) {
      displayNotification(e.message || 'Error adding new address.');
    }

    setIsLoading(false);
  };

  const updateAccountFeePlan = async (value) => {
    try {
      if (value === '') {
        return displayNotification('Please select a fee plan.');
      }
      setIsLoading(true);
      setSelectedPricing(value);
      setShowSelectFeePlan(false);
      const params = {
        account_id: account.account_id,
      };
      if (value && value !== '' && value !== 'no') {
        params.is_insurance = true;
        params.pricing_id = value;
      } else {
        params.is_insurance = false;
        params.pricing_id = null;
      }

      const resp = await UpdateAccountByID(params);
      if (resp.status === 'success') {
        handleGetAccount();
        setIsUpdate(false);
        setInputType('text');

        displayNotification('Successfully updated account.');
      } else {
        displayNotification('API error: ', resp?.message?.message);
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }

    setSelectPayType(false);
  };

  const handleUpdate = async (addressVal = null, label = null, locationType = 3) => {
    setIsLoading(true);
    try {
      const params = {
        account_id: account.account_id,
        [updateData.fieldName]: updateData.value,
      };

      if (!addressVal || (addressVal.trim && addressVal.trim() === '')) {
        setIsLoading(false);
        return displayNotification('Please enter correct and complete address.');
      }

      if (updateData.fieldName === 'contact') {
        params.contact = [...account.contact];
        params.contact[updateData.indexValue] = updateData.value;
      }

      if (updateData.fieldName === 'alt_address') {
        let value = account.alt_address[updateData.indexValue];
        // check if the address has changed,
        const oldValue = contractAddressAndLabel(updateData.objectValue);

        if (addressVal !== oldValue.addressValue) {
          value = { ...value, ...(await handleChangeAddress(addressVal)) };
        }

        if (label !== null) {
          value.label = label;
        }
        value.location_type = parseInt(locationType, 10);

        params.alt_address = [...account.alt_address];
        params.alt_address[updateData.indexValue] = value;
      }

      if (inputType === 'address' && updateData.fieldName !== 'alt_address') {
        let value = { ...account.main_address };

        // check if the address has changed,
        const oldValue = contractAddressAndLabel(updateData.objectValue);

        if (addressVal !== oldValue.addressValue) {
          value = { ...value, ...(await handleChangeAddress(addressVal)) };
        }

        if (label !== null) {
          value.label = label;
        }
        value.location_type = locationType ? parseInt(locationType, 10) : 3;

        params.main_address = { ...value };
      }

      const resp = await UpdateAccountByID(params);

      if (resp.status === 'success') {
        handleGetAccount();
        setIsUpdate(false);
        setInputType('text');

        displayNotification('Successfully updated account.');
      } else {
        displayNotification('API error: ', resp?.message?.message);
      }
    } catch (e) {
      displayNotification(e.message);
    }

    setIsLoading(false);
  };

  const handelPayTypeChange = (value) => {
    if (value === 'yes') {
      if (account.program.length === 0) {
        return displayNotification('Please select a program service first.');
      }
      setShowSelectFeePlan(true);
      setAccount({ ...account, is_insurance: true });
    } else {
      setShowSelectFeePlan(false);
      updateAccountFeePlan('no');
    }
    setPayType(value);
  };

  useEffect(() => {
    if (selectPayType && payTypeRef.current) {
      const event = new MouseEvent('mousedown');
      payTypeRef.current.dispatchEvent(event);
      console.log(payTypeRef.current.focus());
      console.log('CALLED');
    }
  }, [selectPayType]);

  // get contact values and sort it
  let contact = account?.contact || [];
  const hasLeading = (s) => /^\S+\s\S+\s\S+$/.test(s);

  contact = [...contact.sort((a, b) => hasLeading(b) - hasLeading(a) || a > b || -(a < b))];

  // get program values
  const programs = account?.program.map(
    ({ program_name: programName }, index) => `${index === 0 ? '' : ' '}${programName}`
  );

  const locationType = ' (Added to Booking Address List)';

  return (
    <>
      <Loader fullScreen spinning={isLoading} />
      {isAddNewContact && (
        <AddNewContact
          number={account.contact.length + 1 || 1}
          handleClose={() => setIsAddNewContact(false)}
          handleSave={handleAddNewContactToAccount}
        />
      )}
      {isAddNewAddress && (
        <AddressModal handleClose={() => setIsAddNewAddress(false)} handleSave={handleSaveNewAddress} />
      )}
      {isUpdate && (
        <>
          {inputType === 'address' ? (
            <AddressModal
              handleClose={handleCancelUpdate}
              handleSave={handleUpdate}
              defaultValue={contractAddressAndLabel(updateData.objectValue)}
            />
          ) : (
            <UpdateAccountField
              handleCancel={handleCancelUpdate}
              handleSave={handleUpdate}
              data={updateData}
              handleChangeValue={handleChangeValue}
              inputType={inputType}
            />
          )}
        </>
      )}

      {showSelectFeePlan && (
        <SelectFeePlan
          save={(value) => updateAccountFeePlan(value)}
          feePlans={feePlans}
          handleClose={() => setShowSelectFeePlan(false)}
          accountName={account?.name}
          selectedFee={account?.pricing}
        />
      )}
      {!isUpdate && !isAddNewAddress && !isAddNewContact && !showSelectFeePlan ? (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div>Account Profile</div>
              <div>You can edit the existing account information or add new address or contact</div>
            </div>

            <div className={styles['content-data-box']}>
              <div className={styles['inputs-container']}>
                <CustomInput
                  key={account?.name}
                  label="Account Name"
                  icon={EditIcon}
                  isEdit
                  value={account?.name}
                  iconClick={() => {
                    updateValue({
                      name: 'name',
                      value: account?.name,
                      label: 'Name',
                    });
                  }}
                  inputClickable={false}
                />
              </div>

              <div className={styles['inputs-container']}>
                <CustomInput label="Created On" value={account?.created_on} />
                <CustomInput label="Updated On" value={account?.updated_on} />
              </div>

              <div className={styles['inputs-container']}>
                <CustomInput key={programs} label="Service Program" value={programs} />
              </div>

              <div className={styles['inputs-container']}>
                <div className={customInput.input} style={{ paddingBottom: 0 }}>
                  {!selectPayType ? (
                    <CustomInput
                      key={`${account?.main_address?.addressLine1} ${account?.main_address?.label}`}
                      label="Fee Plan Assigned"
                      icon={EditIcon}
                      isEdit
                      inputClickable={false}
                      value={account?.pricing[0] ? account?.pricing[0].pricing_name : "No, it's not a pay account"}
                      iconClick={() => setSelectPayType(true)}
                    />
                  ) : (
                    <>
                      <div className={customInput.label}>Fee Plan Assigned</div>
                      <div className={styles.select__container} style={{ marginBottom: '10px' }}>
                        <div className={styles.select} onClick={() => handelPayTypeChange('no')}>
                          No, it&apos;s not a pay account
                        </div>
                        <div className={styles.select} onClick={() => handelPayTypeChange('yes')}>
                          This is a pay account that we will bill.
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <ReservationPortalLink account={account} />

              <div className={styles['inputs-container']}>
                <CustomInput
                  key={account?.hours}
                  label="Hours"
                  icon={EditIcon}
                  isEdit
                  value={account?.hours}
                  inputClickable={false}
                  iconClick={() => {
                    updateValue({
                      name: 'hours',
                      value: account?.hours,
                      label: 'Hours',
                    });
                  }}
                />
              </div>

              <div className={styles['inputs-container']}>
                <CustomInput
                  key={`${account?.main_address?.addressLine1} ${account?.main_address?.label}`}
                  label={`Main Address ${account?.main_address?.location_type === 3 ? locationType : ''}`}
                  icon={EditIcon}
                  isEdit
                  inputClickable={false}
                  value={
                    account?.main_address
                      ? account?.main_address
                        ? account?.main_address
                          ? constractAddressName(account?.main_address)
                          : ''
                        : ''
                      : ''
                  }
                  iconClick={() => {
                    setInputType('address');
                    updateValue({
                      name: 'main_address',
                      value: account?.main_address
                        ? account?.main_address
                          ? constractAddressName(account?.main_address)
                          : ''
                        : '',
                      label: 'Main Address',
                      objectValue: account?.main_address,
                    });
                  }}
                />
              </div>

              {account?.alt_address.map((add, index) => {
                return (
                  <div key={add.userplace_id} className={styles['inputs-container']}>
                    <CustomInput
                      label={`Alternative Address ${index + 1} ${
                        parseInt(add.location_type, 10) === 3 ? locationType : ''
                      }`}
                      icon={EditIcon}
                      isEdit
                      inputClickable={false}
                      value={constractAddressName(add)}
                      iconClick={() => {
                        setInputType('address');
                        updateValue({
                          name: 'alt_address',
                          value: constractAddressName(add),
                          label: `Alternative Address ${index + 1}`,
                          indexValue: index,
                          objectValue: add,
                        });
                      }}
                    />
                  </div>
                );
              })}

              {
                // show single value
              }
              {contact.length === 0 && (
                <div className={styles['inputs-container']}>
                  <CustomInput
                    label="Contact 1"
                    icon={EditIcon}
                    isEdit
                    value=""
                    inputClickable={false}
                    iconClick={() => {
                      updateValue({
                        name: `contact`,
                        value: '',
                        label: `Contact 1`,
                        indexValue: 0,
                      });
                      setInputType('text');
                    }}
                  />
                </div>
              )}

              {contact.map((c, index) => {
                return (
                  <div key={c} className={styles['inputs-container']}>
                    <CustomInput
                      key={c}
                      label={`Contact ${index + 1}`}
                      icon={EditIcon}
                      isEdit
                      value={c}
                      inputClickable={false}
                      iconClick={() => {
                        updateValue({
                          name: `contact`,
                          value: c,
                          label: `Contact ${index + 1}`,
                          indexValue: index,
                        });
                        setInputType('text');
                      }}
                    />
                  </div>
                );
              })}

              <div className={styles['inputs-container']}>
                <CustomTextArea
                  key={account?.note}
                  label="Note"
                  icon={EditIcon}
                  isEdit
                  value={account?.note}
                  inputClickable={false}
                  iconClick={() => {
                    setInputType('textArea');
                    updateValue({
                      name: 'note',
                      value: account?.note,
                      label: 'Note',
                    });
                  }}
                />
              </div>
            </div>

            <div className={styles.button__container}>
              <button type="button" className={styles.add__more} data-toggle="dropdown">
                Add more
              </button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  role="button"
                  onKeyDown={() => {}}
                  tabIndex={0}
                  onClick={handleAddNewAddress}
                >
                  Add a new address
                </a>
                <a
                  className="dropdown-item"
                  role="button"
                  onKeyDown={() => {}}
                  tabIndex={0}
                  onClick={handleAddNewContact}
                >
                  Add a new contact
                </a>
              </div>
              <button type="button" className={styles.close} onClick={close}>
                Close
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const UpdateAccountField = ({ handleCancel, handleSave, data, handleChangeValue, inputType = 'text' }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="card-box">
          <div className={styles['inputs-container']}>
            <div className={customInput.input}>
              <div className={customInput.label}>{data.label}</div>
              {inputType === 'text' && (
                <input
                  className={customInput['text-input']}
                  name={data.name}
                  type="text"
                  defaultValue={data.value}
                  onChange={(e) => handleChangeValue(e.target.value)}
                />
              )}
              {inputType === 'textArea' && (
                <textarea
                  rows="4"
                  className={customInput['text-area']}
                  name={data.name}
                  defaultValue={data.value}
                  onChange={(e) => handleChangeValue(e.target.value)}
                />
              )}
              {inputType === 'address' && (
                <PlacesAutocomplete value={data.value} onChange={handleChangeValue} onSelect={handleChangeValue}>
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div
                      style={{
                        position: 'relative',
                        zIndex: 9999,
                        width: '100%',
                      }}
                    >
                      <input
                        {...getInputProps({
                          className: `normal form-control ${styles['select-input']}`,
                        })}
                      />
                      <div
                        className=""
                        style={{
                          position: 'absolute',
                          zIndex: 99999,
                          width: '100%',
                        }}
                      >
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: '#fafafa',
                                cursor: 'pointer',
                                padding: 5,
                              }
                            : {
                                backgroundColor: 'rgb(236 232 232)',
                                cursor: 'pointer',
                                padding: 5,
                              };
                          return (
                            <div
                              id="address"
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={suggestion.description}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
            </div>
          </div>
        </div>

        <div className={styles.button__container}>
          <button
            type="button"
            className={styles.add__more}
            onClick={handleCancel}
            style={{ float: 'unset', marginRight: '10px' }}
          >
            Cancel
          </button>
          <button type="button" className={styles.close} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModifyAccountModal;
