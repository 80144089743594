/* eslint-disable camelcase */
import { all, takeEvery, put, call } from "redux-saga/effects";
import { fetchTripsList, fetchPrograms } from "../../services/trips";
import { types } from "./actions";
import { types as commonTypes } from "../common/actions";

// function compare(a, b) {
//   const genreA = a.pickup_time.unix_epoch;
//   const genreB = b.pickup_time.unix_epoch;

//   let comparison = 0;
//   if (genreA < genreB) {
//     comparison = 1;
//   } else if (genreA > genreB) {
//     comparison = -1;
//   }
//   return comparison;
// }

export function* FETCH_TRIPS_LIST({ payload }) {
  // console.log(payload);
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchTripsList, payload);
  // console.log(response);
  if (response) {
    if (response.status === "success") {
      yield put({
        type: types.SET_STATE,
        payload: {
          tripList: response.data.trip_history
        }
      });
    } else {
      yield put({
        type: commonTypes.SET_STATE,
        payload: {
          isError: true,
          loading: false,
          errContent: response.message.message
        }
      });
    }
  }
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* RESET_TRIP_LIST() {
  yield put({
    type: types.SET_STATE,
    payload: {
      tripList: []
    }
  });
}

export function* FETCH_PROGRAMS() {
  const response = yield call(fetchPrograms);

  if (response.data.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        programs: response.data.data.programs
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.data.message
      }
    });
  }
}

export function* SET_SPIN() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });
}

export function* REMOVE_SPIN() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_TRIPS_LIST, FETCH_TRIPS_LIST),
    takeEvery(types.RESET_TRIP_LIST, RESET_TRIP_LIST),
    takeEvery(types.FETCH_PROGRAMS, FETCH_PROGRAMS),
    takeEvery(types.SET_SPIN, SET_SPIN),
    takeEvery(types.REMOVE_SPIN, REMOVE_SPIN),
    null
  ]);
}
