import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchMapInfo: [],
    fetchTripListInfo: null,
    fetchLatestShuttleLocation: null,
    zoomInOut: ["payload"],
    setCenter: ["payload"],
    setSpinner: null,
    unsetSpinner: null,
    initDataReceive: null
  },
  { prefix: "MAP_" }
);

export const types = Types;
export default Creators;
