/* eslint-disable camelcase */
import parse from "../_parse";

export async function fetchUsersList() {
  try {
    const lists = await parse.Cloud.run("v2_webapp_get_employer_admins");
    return lists;
  } catch (error) {
    console.log(
      "services/account-management/users.js::fetchUsersList() = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function fetchUserInfoByID(param) {
  try {
    const response = await parse.Cloud.run("", param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}
