import React from "react";

export default class Fullscreen extends React.Component {
  launchFullscreen = element => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  toggleFullscreen = () => {
    const fullscreenEnabled =
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.webkitFullscreenEnabled;

    if (fullscreenEnabled) {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        this.launchFullscreen(document.documentElement);
      } else {
        this.exitFullscreen();
      }
    }
  };

  render() {
    return (
      <a
        className="nav-link waves-light waves-effect"
        href="#"
        onClick={this.toggleFullscreen}
      >
        <i className="mdi mdi-crop-free noti-icon" />
      </a>
    );
  }
}
