import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CircleClose(props) {
  return (
    <SvgIcon viewBox="0 0 50 50" {...props}>
      <path d="M42.6851 7.31414C32.9329 -2.43805 17.0657 -2.43805 7.31398 7.31414C-2.43777 17.0663 -2.43821 32.9335 7.31398 42.6853C12.1901 47.5614 18.595 49.9994 25.0001 49.9994C31.4048 49.9994 37.8103 47.5614 42.6862 42.6853C52.4379 32.9331 52.4379 17.0659 42.6862 7.31414H42.6851ZM40.9966 40.9978C32.1757 49.8186 17.8234 49.8186 9.0016 40.9978C0.17983 32.1769 0.180703 17.8245 9.0016 9.00274C13.412 4.5923 19.2052 2.38707 24.9991 2.38707C30.793 2.38707 36.5857 4.5923 40.9966 9.00274C49.8175 17.8236 49.8175 32.176 40.9966 40.9978Z" />
      <path d="M33.1838 16.8149C32.7178 16.3489 31.9618 16.3489 31.4953 16.8149L24.9982 23.3117L18.5011 16.8146C18.035 16.3486 17.2791 16.3486 16.8126 16.8146C16.3461 17.2806 16.3465 18.0366 16.8126 18.5031L23.3097 25.0002L16.8126 31.4974C16.3465 31.9634 16.3465 32.7193 16.8126 33.1859C17.0458 33.4191 17.3511 33.5355 17.6568 33.5355C17.9625 33.5355 18.2678 33.4191 18.501 33.1859L24.9982 26.6887L31.4953 33.1859C31.7285 33.4191 32.0338 33.5355 32.3395 33.5355C32.6453 33.5355 32.9505 33.4191 33.1838 33.1859C33.6498 32.7198 33.6498 31.9639 33.1838 31.4974L26.6866 25.0002L33.1838 18.5031C33.6498 18.0371 33.6498 17.2811 33.1838 16.815L33.1838 16.8149Z" />
    </SvgIcon>
  );
}
