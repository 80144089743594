/* eslint-disable */

import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import CustomInput from '../../../components/Elements/TextInput/CustomInput';
import TimeInput from '../../../components/Elements/TextInput/TimeInput';
import Close from '../../../components/SvgIcons/Close';
import { addMinutes, secondsToMinutes, setDBTime } from '../../../lib/common';
import displayNotification from '../../../utils/notification';
import styles from './styles.module.scss';
import { createSharedTrips } from '../../../services/dispatch';

dayjs.extend(isToday);
dayjs.extend(customParseFormat);

const { host, protocol } = window.location;
const dropOffUrl = `${protocol}//${host}/resources/images/dropoff_pin_same-size.png`;
const pickupOffUrl = `${protocol}//${host}/resources/images/pickup_pin_same-size.png`;

const OptimizeTrip = ({
  close,
  optimizedTime,
  handelBack,
  programId,
  driver,
  unsetSpinner,
  setSpinner,
  dispatchDate,
  updateBookingAfterSharedRide,
}) => {
  const [trips, setTrips] = useState([]);
  const [startTime, setStartTime] = useState();
  const [loadTime, setLoadTime] = useState(0);
  const [computedTime, setComputedTime] = useState();
  const [updatedPickupTime, setUpdatedPickupTime] = useState();

  const handleRecomputeTime = () => {
    if (!startTime || !loadTime || loadTime < !1) {
      return displayNotification('Invalid values provided', 'Error', 'error');
    }

    setComputedTime(computeTimeDifferences(trips));
  };

  const handleLockThisRoute = async () => {
    if (!updatedPickupTime) {
      return displayNotification('Please make sure to update time by clicking update time button');
    }
    setSpinner();
    // recompute to make sure that changes applied are correct.
    const computedTime = computeAllPickupTime(false);
    try {
      // get all booking ids
      const bookingIds = trips.map((trip) => {
        return trip.bookings[0].id;
      });

      // convert time to db Type and to db format
      const updateTrips = trips.map((trip, index) => {
        if (trip.bookings[0].stop_type === 'pickup') {
          trip.bookings[0].scheduled_pickup_time = dayjs(
            setDBTime(computedTime[index], new Date(trip.bookings[0].scheduled_pickup_time))
          ).format('YYYY-MM-DDTHH:mm:ss');
        } else {
          trip.bookings[0].scheduled_dropoff_time = dayjs(
            setDBTime(computedTime[index], new Date(trip.bookings[0].scheduled_dropoff_time))
          ).format('YYYY-MM-DDTHH:mm:ss');
        }

        return { ...trip, bookings: [...trip.bookings] };
      });

      const params = {
        stop_order: Object.values(
          updateTrips.reduce((acc, { address, bookings, id, account_id, place_id, geoLocation }) => {
            if (!acc[address]) {
              acc[address] = { address, bookings: [], id, account_id, place_id, geoLocation };
            }
            acc[address].bookings = acc[address].bookings.concat(bookings);

            return acc;
          }, {})
        ),
        bookings: [...new Set(bookingIds)],
        travel_distance: optimizedTime?.travel_distance,
        travel_time: optimizedTime?.travel_time,
        load_unload_time: parseInt(loadTime) * 60,
        shuttle_id: driver.shuttle_id,
        driver_id: driver.driver_id,
        driver_team_id: driver.driver_team_id,
      };

      const resp = await createSharedTrips(params);

      if (resp.status === 'success') {
        updateBookingAfterSharedRide(resp.data);
        close();
        displayNotification('Successfully created shared trips', 'Success', 'success');
      } else {
        if (res.message.error_code === 'MANUALDISPATCHPROGRAMERROR') {
          displayNotification(
            'The booking can’t be dispatched because the vehicle doesn’t belong to this program anymore.',
            'Error',
            'error'
          );
        } else displayNotification('Failed to create shared trip', 'Error', 'error');
      }
    } catch (e) {
      console.error(e);
    }

    unsetSpinner();
  };

  const computeTimeDifferences = (trips) => {
    let results = [];

    for (let i = 0; i < trips.length - 1; i++) {
      const currentStop = trips[i];
      const nextStop = trips[i + 1];

      // Extract the single booking from each stop
      const currentBooking = currentStop.bookings[0];
      const nextBooking = nextStop.bookings[0];

      // Extract all possible times and find the first valid time
      const currentTime = currentBooking.scheduled_pickup_time || currentBooking.scheduled_dropoff_time;
      const nextTime = nextBooking.scheduled_pickup_time || nextBooking.scheduled_dropoff_time;

      if (currentTime && nextTime) {
        // Calculate the time difference in seconds
        const timeDifference = (new Date(nextTime) - new Date(currentTime)) / 1000;
        const bookingId = nextStop.bookings[0].id;

        results.push({
          bookingId,
          passenger: `${currentBooking.passenger_firstname} ${currentBooking.passenger_lastname}`,
          from: currentStop.address,
          to: nextStop.address,
          timeDifferenceSeconds: timeDifference,
        });
      }
    }

    return results;
  };

  const computeAllPickupTime = (showMessage = true) => {
    const adjustedTime = [startTime];

    computedTime.forEach((time, index) => {
      adjustedTime.push(
        addMinutes(adjustedTime[index], secondsToMinutes(time.timeDifferenceSeconds) + parseInt(loadTime))
      );
    });

    setUpdatedPickupTime(adjustedTime);
    if (showMessage) displayNotification('Successfully updated all pickup time', 'Success', 'success');

    return adjustedTime;
  };

  useEffect(() => {
    if (optimizedTime && optimizedTime.stop_order) {
      setTrips(optimizedTime.stop_order);
    }
  }, [optimizedTime]);

  useEffect(() => {
    if (computedTime) {
      computeAllPickupTime();
    }
  }, [computedTime]);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.tripReorderContainer}>
            <div className={styles.mapContainer}>
              <div className={styles.header}>Shared Trip Setup</div>
              <div className={`mt-4 ${styles.subHeader}`}>Optimize route</div>
              <div className={`pl-4 mt-4 ${styles.paragraph}`}>
                Update Arrival Time at Pickup including load/unload time
              </div>
              <ul className={`${styles.tripList}`}>
                {trips.map((trip, index) => {
                  const booking = trip.bookings[0];
                  const isPickup = booking.stop_type === 'pickup';
                  let time = isPickup ? booking.scheduled_pickup_time : booking.scheduled_dropoff_time;
                  const tripTime = new Date(time).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  });

                  let adjustedPT = tripTime;

                  if (updatedPickupTime?.[index]) {
                    adjustedPT = updatedPickupTime[index];
                  }

                  return (
                    <li key={trip.id} className={styles.optimizeTime}>
                      <div className={styles.pickupTime}>{adjustedPT}</div>
                      <div className={styles.tripItem}>
                        <div className={styles.tripIcon}>
                          <img src={isPickup ? pickupOffUrl : dropOffUrl} alt="tripIcon" />
                        </div>
                        <div className={styles.tripDetails}>
                          <div className={styles.tripName}>{trip.address}</div>
                          <div className={styles.tripAddress}>
                            {tripTime} {booking.passenger_firstname} {booking.passenger_lastname}{' '}
                            {booking.mobility_assistance.length
                              ? booking.mobility_assistance.map((mob) => `, ${mob}`)
                              : ''}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={styles.tripsContainer}>
              <div className={styles.header}>
                <div>&nbsp;</div>
                <div className={styles.closeIcon}>
                  <IconButton onClick={close}>
                    <Close className={styles['close-icon']} />
                  </IconButton>
                </div>
              </div>

              <div className={`mt-3 ${styles.tripReorder}`}>
                <div className={styles.subHeader}>Step 2: Optimize pickup time</div>
                <p className={`mt-1 ${styles.paragraph}`}>
                  Based on the estimate travel time, adjust the loading and unloading time, and the first pickup time to
                  ensure the on-time performance before creating the shared trip.
                </p>

                <div className={`mt-4 ${styles.tripList}`}>
                  <div className={styles.inputsContainer}>
                    <CustomInput
                      label="Estimated total travel time"
                      value={`${secondsToMinutes(optimizedTime?.travel_time)} minutes`}
                    />
                    <CustomInput
                      inputCustomClass={styles.inputColor}
                      inputType="number"
                      readOnly={false}
                      isEdit
                      label="Load or unload time, minutes"
                      value={loadTime}
                      onChangeEvent={(e) => setLoadTime(e.target.value)}
                      icon={<span style={{ background: '#fff', padding: '1px' }}>minutes</span>}
                      iconType="component"
                    />
                  </div>
                  <div className={styles.inputsContainer}>
                    <TimeInput
                      inputCustomClass={styles.inputColor}
                      label="Set the first pickup time"
                      subLabel=""
                      value={startTime}
                      icon="/resources/images/Time_Square.png"
                      isEdit
                      placeHolder=""
                      readOnly={false}
                      onChangeEvent={(value) => setStartTime(value)}
                      programId={programId}
                      useAPITime
                      selectedDate={new Date(dispatchDate)}
                    />

                    <button
                      style={{ marginTop: '-10px' }}
                      className="btn btn-outline-primary"
                      onClick={handleRecomputeTime}
                    >
                      Update all pickup time
                    </button>
                  </div>
                </div>
              </div>
              <div className={`mt-4 text-right ${styles.optimizeButtons}`}>
                <button className="btn btn-secondary" onClick={handelBack}>
                  Back
                </button>
                <button className="ml-2 btn btn-primary" onClick={handleLockThisRoute}>
                  Create shared trip
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptimizeTrip;
