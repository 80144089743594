import { all, takeEvery, put, call } from "redux-saga/effects";
import {
  fetchFleetShuttleList,
  createNewShuttle,
  uploadImageToS3,
  fetchFleetShuttleInfoByID,
  fetchLabel,
  fetchPrograms,
  updateExistShuttle
} from "../../services/vehicle";
import { types } from "./actions";
import { types as commonTypes } from "../common/actions";

export function* FETCH_FLEET_SHUTTLE_LIST() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchFleetShuttleList);
  if (response.status === "success") {
    // if (Array.isArray(response)) {
    yield put({
      type: types.SET_STATE,
      payload: {
        fleetShuttleList: response.data,
        newFleetStatus: false
      }
    });
    // }
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* FETCH_LABEL() {
  const response = yield call(fetchLabel);

  if (response.status === 1) {
    if (response !== "Fleet not found!") {
      yield put({
        type: types.SET_STATE,
        payload: {
          labelError: false,
          labelErrContent: "",
          label: response.data.fleet_name
        }
      });
    }
  } else {
    yield put({
      type: types.SET_STATE,
      payload: {
        labelError: true,
        labelErrContent: response.data
      }
    });
  }
}

export function* FETCH_PROGRAMS() {
  const response = yield call(fetchPrograms);

  if (response !== "Program not found!") {
    yield put({
      type: types.SET_STATE,
      payload: {
        programItems: response
      }
    });
  }
}

export function* FETCH_FLEET_SHUTTLE_INFO_BY_ID({ payload }) {
  const response = yield call(fetchFleetShuttleInfoByID, payload);

  yield put({
    type: types.SET_STATE,
    payload: {
      fleetShuttleInfoById: response.data
    }
  });
}

export function* UPDATE_EXIST_SHUTTLE({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  if (payload.photo_replaced) {
    const respForS3 = yield call(uploadImageToS3, payload.photoObj);
    if (respForS3.status) {
      payload = { ...payload, photo: respForS3.desc.Key };
    }
  } else {
    payload = { ...payload, photo: "" };
  }

  const response = yield call(updateExistShuttle, payload);
  if (response) {
    // console.log("update exist shuttle success");
    yield put({
      type: types.SET_STATE,
      payload: {
        newFleetStatus: true
      }
    });
  } else {
    // console.log("failed sagas::UPDATE_EXIST_SHUTTLE functoin = ");
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* CREATE_NEW_SHUTTLE({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const respForS3 = yield call(uploadImageToS3, payload.photoObj);

  if (respForS3.status) {
    payload = { ...payload, photo: respForS3.desc.Key };
    const response = yield call(createNewShuttle, payload);

    if (response) {
      // console.log("create new shuttle success");
      yield put({
        type: types.SET_STATE,
        payload: {
          newFleetStatus: true
        }
      });
    } else {
      // console.log("failed create_new_shuttle functoin = ");
    }

    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        loading: false
      }
    });
  }
}

export function* RESET_SHUTTLE_INFO() {
  yield put({
    type: types.SET_STATE,
    payload: {
      fleetShuttleInfoById: {}
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_FLEET_SHUTTLE_LIST, FETCH_FLEET_SHUTTLE_LIST),
    takeEvery(types.CREATE_NEW_SHUTTLE, CREATE_NEW_SHUTTLE),
    takeEvery(types.FETCH_LABEL, FETCH_LABEL),
    takeEvery(types.FETCH_PROGRAMS, FETCH_PROGRAMS),
    takeEvery(types.UPDATE_EXIST_SHUTTLE, UPDATE_EXIST_SHUTTLE),
    takeEvery(types.RESET_SHUTTLE_INFO, RESET_SHUTTLE_INFO),
    takeEvery(
      types.FETCH_FLEET_SHUTTLE_INFO_BY_ID,
      FETCH_FLEET_SHUTTLE_INFO_BY_ID
    ),
    null
  ]);
}
