import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    logout: null,
    login: ["payload"],
    loadCurrentAccount: null,
    checkEmail: ["payload"]
  },
  { prefix: "AUTH_" }
);

export const types = Types;
export default Creators;
