import React from 'react';
// import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import './style.module.scss';

class AuthLayout extends React.PureComponent {
  render() {
    const { children } = this.props;
    const isLoginPage = window.location?.pathname && window.location.pathname.includes('auth/login');

    return (
      <div className="wrapper-page" style={{}}>
        {!isLoginPage && (
          <div className="text-center">
            <a href="https://www.duetinc.com" className="logo-lg" target="_new">
              <img
                src={`${process.env.PUBLIC_URL}/resources/images/logo.png`}
                style={{ width: '45%', marginBottom: '75px' }}
                alt=""
              />
            </a>
          </div>
        )}
        {children}
      </div>
    );
  }
}

export default withRouter(AuthLayout);
