/* eslint-disable camelcase */
import { all, takeEvery, put } from "redux-saga/effects";
import { types } from "./actions";

export function* INIT_ERROR_STATUS() {
  yield put({
    type: types.SET_STATE,
    payload: {
      isError: false,
      errContent: ""
    }
  });
}

export default function* rootSaga() {
  yield all([takeEvery(types.INIT_ERROR_STATUS, INIT_ERROR_STATUS), null]);
}
