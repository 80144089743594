import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
// import { Menu } from "antd";
import authActions from "redux/auth/actions";
import IdleTimer from "react-idle-timer";
import commonActioins from "../../../../redux/common/actions";
import { TIMEOUT } from "../../../../config";

@connect(
  ({ auth, common }) => ({ auth, common }),
  {
    logout: authActions.logout,
    initErrorStatus: commonActioins.initErrorStatus
  }
)
class ProfileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      modalShowFlag: false
    };
  }

  componentDidMount() {
    // Activate the event listener
    this.setupBeforeUnloadListener();
  }

  componentWillReceiveProps(newProps) {
    const { common } = newProps;

    this.setState({
      modalShowFlag: common.isError
    });
  }

  handleClose = () => {
    const { initErrorStatus } = this.props;
    this.setState({ modalShowFlag: false });
    initErrorStatus();
  };

  onIdle = () => {
    // console.log("timeout");
    this.clickLogout();
  };

  doSomethingBeforeUnload = () => {
    // Do something
    // const { logout } = this.props;
    // logout();
  };

  setupBeforeUnloadListener = () => {
    // window.addEventListener("beforeunload", ev => {
    //   ev.preventDefault();
    //   return this.doSomethingBeforeUnload();
    // });
  };

  clickLogout = () => {
    const { logout } = this.props;
    window.unLoadZendeskJS();
    logout();
  };

  render() {
    const { auth, common } = this.props;
    const { modalShowFlag } = this.state;

    return (
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          onIdle={this.onIdle}
          timeout={TIMEOUT}
          startOnLoad
        />
        <a
          className="nav-link dropdown-toggle waves-effect waves-light nav-user"
          data-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="false"
          aria-expanded="false"
        >
          {auth.avatar !== undefined ? (
            <img src={auth.avatar} alt="user" className="rounded-circle" />
          ) : (
            <i className="fa fa-user-circle-o fa-2x rounded-circle" />
          )}
        </a>
        <div
          className="dropdown-menu dropdown-menu-right profile-dropdown "
          aria-labelledby="Preview"
        >
          <div className="dropdown-item noti-title">
            <h5 className="text-overflow">
              <small>Welcome ! {auth.name}</small>
            </h5>
          </div>
          <a className="dropdown-item notify-item">
            <i className="mdi mdi-account" /> <span>Profile</span>
          </a>
          <a className="dropdown-item notify-item">
            <i className="mdi mdi-settings" /> <span>Settings</span>
          </a>
          <a className="dropdown-item notify-item">
            <i className="mdi mdi-lock-open" /> <span>Lock Screen</span>
          </a>
          <a
            href="javascript:void(0);"
            className="dropdown-item notify-item"
            onClick={this.clickLogout}
          >
            <i className="mdi mdi-logout" /> <span>Logout</span>
          </a>
        </div>
        <Modal show={modalShowFlag} onHide={this.handleClose}>
          <Modal.Header style={{ background: "#c71612" }}>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>{common.errContent}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ProfileMenu;
