/* eslint-disable no-underscore-dangle */

import ReactGA from 'react-ga4';

const env = window._env_.DEPLOYMENT_PROFILE;

const TRACKING_ID =
  env === 'dev' || env === 'preDev' ? process.env.REACT_APP_DevGAnalytic : process.env.REACT_APP_ProdGAnalytic;

ReactGA.initialize(TRACKING_ID);

export const setUserId = (userId) => {
  ReactGA.set({ user_id: userId });
  ReactGA.set({ userId });
};

export const logPageView = (userId, duration) => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
    title: window.location.pathname + window.location.search,
  });

  ReactGA.event({
    category: 'User',
    action: 'time_on_page',
    title: window.location.pathname + window.location.search,
    label: userId,
    value: Math.round(duration / 60000),
  });
};
