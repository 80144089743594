/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const env = window._env_.DEPLOYMENT_PROFILE;

const firebaseConfigDev = {
  apiKey: process.env.REACT_APP_DevFirebaseApiKey,
  authDomain: process.env.REACT_APP_DevAuthDomain,
  databaseURL: process.env.REACT_APP_DevDatabaseUrl,
  projectId: process.env.REACT_APP_DevProjectId,
  storageBucket: process.env.REACT_APP_DevStorageBucket,
  messagingSenderId: process.env.REACT_APP_DevMessagingSenderId,
  appId: process.env.REACT_APP_DevAppId,
  measurementId: process.env.REACT_APP_DevMeasurementId
};

const firebaseConfigProd = {
  apiKey: process.env.REACT_APP_ProdFirebaseApiKey,
  authDomain: process.env.REACT_APP_ProdAuthDomain,
  databaseURL: process.env.REACT_APP_ProdDatabaseUrl,
  projectId: process.env.REACT_APP_ProdProjectId,
  storageBucket: process.env.REACT_APP_ProdStorageBucket,
  messagingSenderId: process.env.REACT_APP_ProdMessagingSenderId,
  appId: process.env.REACT_APP_ProdAppId,
  measurementId: process.env.REACT_APP_ProdMeasurementId
};

const firebaseConfig =
  env === "dev" || env === "preDev" ? firebaseConfigDev : firebaseConfigProd;

const app = initializeApp(firebaseConfig);

const appCheckKey =
  env === "dev" || env === "preDev"
    ? process.env.REACT_APP_DevAppCheckSiteKey
    : process.env.REACT_APP_ProdAppCheckSiteKey;

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(appCheckKey),

  // Optional arguement. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export const auth = getAuth(app);
export const database = getDatabase(app);
export const firestore = getFirestore(app);

export default app;
