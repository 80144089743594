/* eslint-disable */

const heatMapColors = [
  ["000000", "000000", "002230", "004560", "115790", "1169c0", "118bf0"],
  ["aa1100", "aa1100", "883332", "775564", "666896", "557ac8", "449cfa"],
  ["bb2200", "bb2200", "aa4432", "996665", "997998", "889bcb", "77bdfd"],
  ["cc4400", "cc4400", "cc6633", "bb7766", "bb9999", "aaabcb", "aacdfd"],
  ["dd5500", "dd5500", "dd7733", "dd9966", "ccaa99", "cccccc", "cceefe"],
  ["ee6600", "ee6600", "ee77aa", "ee99bb", "ffbbdd", "ffddee", "ffffff"],
  ["ee6600", "ee6600", "ee77aa", "ee99bb", "ffbbdd", "ffddee", "ffffff"]
];

const heatMapLabel = [
  ["F1", "F1", "E1", "D1", "C1", "B1", "A1"],
  ["F2", "F2", "E2", "D2", "C2", "B2", "A2"],
  ["F3", "F3", "E3", "D3", "C3", "B3", "A3"],
  ["F4", "F4", "E4", "D4", "C4", "B4", "A4"],
  ["F5", "F5", "E5", "D5", "C5", "B5", "A5"],
  ["F6", "F6", "E6", "D6", "C6", "B6", "A6"],
  ["F6", "F6", "E6", "D6", "C6", "B6", "A6"]
];
/*
  expected values 
  NW = {
    long: float,
    lat: float
  }
  SW = {
    long: float,
    lat: float
  }

  formula to get valueToSubtractLong = (NW.long - SW.long) / 6;
  formula to get valueToSubtractLat =  (NW.lat - SW.lat) / 6;

*/
// generateHeatmapArrayCoordinates({long: 41.488413, lat: -84.548209},{long: 40.67376, lat: -85.504019})
const coordinateCount = 6;

export const generateHeatmapArrayCoordinates = heatBounds => {
  try {
    const { NE, SW } = heatBounds;
    const valueToSubtractLong = (NE.longitude - SW.longitude) / coordinateCount;
    const valueToSubtractLat = (NE.latitude - SW.latitude) / coordinateCount;

    const heatMapValue = [];

    let long = NE.longitude;

    // generate all x coordinates
    for (let i = 0; i <= 6; i++) {
      let lat = NE.latitude; // reset lat value
      for (let j = 0; j <= 6; j++) {
        const coordinates = {
          longitude: long,
          latitude: lat,
          color: heatMapColors[i][j] || "notfound",
          label: heatMapLabel[i][j] || ""
        };
        if (!heatMapValue[i]) {
          heatMapValue[i] = [];
        }
        heatMapValue[i].push(coordinates);
        lat = lat - valueToSubtractLat;
      }
      long = long - valueToSubtractLong;
    }

    return heatMapValue;
  } catch (e) {
    console.error(e);
  }
};

// need to refactor its a duplicate cood in heatmap-info.jsx file
export const getHeatmapColor = (boundData, tripCoordinates) => {
  const { longitude, latitude } = tripCoordinates;

  let result = {};

  [1, 2, 3, 4, 5, 6].map(index => {
    [1, 2, 3, 4, 5, 6].map(index2 => {
      const topRight = {
        lat: boundData[index - 1][index2 - 1].latitude,
        lng: boundData[index - 1][index2 - 1].longitude
      };
      const bottomRight = {
        lat: boundData[index - 1][index2].latitude,
        lng: boundData[index - 1][index2 - 1].longitude
      };
      const bottomLeft = {
        lat: boundData[index][index2].latitude,
        lng: boundData[index][index2].longitude
      };
      const topLeft = {
        lat: boundData[index][index2 - 1].latitude,
        lng: boundData[index][index2 - 1].longitude
      };

      const polygonCoords = [
        [topRight.lat, topRight.lng],
        [bottomRight.lat, bottomRight.lng],
        [bottomLeft.lat, bottomLeft.lng],
        [topLeft.lat, topLeft.lng]
      ];

      const isWithinPolygon = inside([latitude, longitude], polygonCoords);

      if (isWithinPolygon) {
        result = boundData[index2 - 1][index];
      }
    });
  });

  // this code will check the nearest coordinate to the trip
  if (Object.keys(result).length === 0) {
    let closestCoords = boundData[0][0];
    let shortestDistance = DistSquared({ longitude, latitude }, closestCoords);

    for (let j = 0; j < boundData.length; j++) {
      for (let i = 0; i < boundData[j].length; i++) {
        var d = DistSquared({ latitude, longitude }, boundData[j][i]);

        if (d < shortestDistance) {
          closestCoords = boundData[j][i];
          shortestDistance = d;
          if (closestCoords.label === "") {
            closestCoords.label = "Outside";
          }
        }
      }
    }

    [1, 2, 3, 4, 5, 6].map(index => {
      [1, 2, 3, 4, 5, 6].map(index2 => {
        const topRight = {
          lat: boundData[index - 1][index2 - 1].latitude,
          lng: boundData[index - 1][index2 - 1].longitude
        };
        const bottomRight = {
          lat: boundData[index - 1][index2].latitude,
          lng: boundData[index - 1][index2 - 1].longitude
        };
        const bottomLeft = {
          lat: boundData[index][index2].latitude,
          lng: boundData[index][index2].longitude
        };
        const topLeft = {
          lat: boundData[index][index2 - 1].latitude,
          lng: boundData[index][index2 - 1].longitude
        };

        const polygonCoords = [
          [topRight.lat, topRight.lng],
          [bottomRight.lat, bottomRight.lng],
          [bottomLeft.lat, bottomLeft.lng],
          [topLeft.lat, topLeft.lng]
        ];

        const isWithinPolygon = polygonCoords.findIndex(
          coor =>
            coor[0] === closestCoords.latitude &&
            coor[1] === closestCoords.longitude
        );

        if (isWithinPolygon > -1 && result.label !== "Outside") {
          result = boundData[index2 - 1][index];
          result.label = "Outside";
        }
      });
    });
  }

  return result;
};

const inside = (point, vs) => {
  let x = point[0];
  let y = point[1];

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0],
      yi = vs[i][1];
    const xj = vs[j][0],
      yj = vs[j][1];

    const intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

const DistSquared = (pt1, pt2) => {
  const diffX = pt1.longitude - pt2.longitude;
  const diffY = pt1.latitude - pt2.latitude;
  return diffX * diffX + diffY * diffY;
};
