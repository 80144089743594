import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Close(props) {
  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <path
        d="M16 1L8.50652 8.5M8.50652 8.5L16 16M8.50652 8.5L1 1M8.50652 8.5L1 16"
        stroke="#4A525F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
