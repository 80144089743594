/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

const Loader = ({ spinning = true, msg = '', fullScreen }) => (
  <div
    className={classNames(styles.loader, {
      [styles.hidden]: !spinning,
      [styles.fullScreen]: fullScreen,
    })}
  >
    <div
      style={{
        display: 'block',
        width: '100vw',
        top: '900px',
        fontWeight: 'bold',
      }}
    >
      {msg}
    </div>
    <img
      src={`${process.env.PUBLIC_URL}/resources/images/bar_spinner.gif`}
      alt=""
      style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
    />
  </div>
);

export default Loader;
