/* eslint-disable camelcase */
import { all, takeEvery, put, call } from "redux-saga/effects";
import { fetchBillingsList } from "../../../services/account-management/billing";
import { types } from "./actions";
import { types as commonTypes } from "../../common/actions";

export function* FETCH_BILLINGS_LIST() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchBillingsList);

  if (response) {
    if (response.status === "success") {
      yield put({
        type: types.SET_STATE,
        payload: {
          billingList: response.data
        }
      });
    } else {
      yield put({
        type: commonTypes.SET_STATE,
        payload: {
          isError: true,
          errContent: response.message.message
        }
      });
    }
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* RESET_BILLINGS_LIST() {
  yield put({
    type: types.SET_STATE,
    payload: {
      billingList: []
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_BILLINGS_LIST, FETCH_BILLINGS_LIST),
    takeEvery(types.RESET_BILLINGS_LIST, RESET_BILLINGS_LIST),
    null
  ]);
}
