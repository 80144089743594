/* eslint-disable camelcase */
import parse from '../_parse';

export async function fetchBookingHistoryList(param) {
  try {
    const lists = await parse.Cloud.run('v2_webapp_get_booking_history', param);
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log('reports/fetchBookingHistoryList() = ', JSON.stringify(error));
    return error;
  }
}

export async function fetchBookingsList(param) {
  try {
    const lists = await parse.Cloud.run('v2_webapp_get_booking_list', param);
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log('fetchBookingsList() = ', JSON.stringify(error));
    return error;
  }
}

export async function fetchUserProgramName() {
  try {
    const lists = await parse.Cloud.run('v1_webapp_get_user_program_name');
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log('reports/fetchUserProgramName() = ', JSON.stringify(error));
    return error;
  }
}

export async function fetchBookingInfoByID(param) {
  try {
    const response = await parse.Cloud.run('v2_webapp_get_booking_info_by_id', param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function getDailyRideshipData(param) {
  try {
    const response = await parse.Cloud.run('v1_webapp_get_daily_ridership_data', param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function editAccountReportConfig(param) {
  try {
    const response = await parse.Cloud.run('v1_webapp_edit_account_report_config', param);

    return response;
  } catch (error) {
    console.log('editAccountReportConfig() = ', JSON.stringify(error));
    return error;
  }
}

export async function getMultipleAccountReportConfig(param) {
  try {
    const response = await parse.Cloud.run('v1_webapp_get_multiple_account_report_config', param);

    return response;
  } catch (error) {
    console.log('getMultipleAccountReportConfig() = ', JSON.stringify(error));
    return error;
  }
}
