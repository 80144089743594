import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import auth from "./auth/reducers";
import settings from "./settings/reducers";
import menu from "./menu/reducers";
import map from "./map/reducers";
import vehicle from "./vehicle/reducers";
import drivers from "./drivers/reducers";
import common from "./common/reducers";
import bookings from "./rider-services/bookings/reducers";
import nem from "./rider-services/NEM_Transport/reducers";
import services from "./programs/services/reducers";
import users from "./account-management/users/reducers";
import billings from "./account-management/billing/reducers";
import bookinghistory from "./reports/history/reducers";
import trips from "./trips/reducers";
import members from "./rider-services/members/reducers";

export default history =>
  combineReducers({
    form: formReducer,
    router: connectRouter(history),
    auth,
    settings,
    menu,
    map,
    vehicle,
    drivers,
    common,
    bookings,
    nem,
    services,
    users,
    billings,
    bookinghistory,
    trips,
    members
  });
