import React from "react";
import TextInput from "../../../components/Elements/TextInput/index";

const textInputClasses = {
  label: { cursor: "text" },
  rootInput: {
    border: "1px solid #D7DCE5 !important",
    borderRadius: "4px",
    fontWeight: 400
  },
  rootInputDisabled: {
    padding: 0,
    background: "#ffffff",
    color: "#4A525F"
  },
  input: {
    height: "16px",
    padding: "11px 36px 11px 10px"
  }
};

const CustomTextInput = React.forwardRef(
  (
    { disabled, loading, clickable, className, style, icon, onClick, ...props },
    ref
  ) => {
    return (
      <div
        className={className}
        style={{
          width: "100%",
          position: "relative",
          ...style
        }}
      >
        <TextInput
          {...props}
          disabled={disabled}
          loading={loading}
          classes={textInputClasses}
          ref={ref}
        />
        {icon && !loading && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              height: "40px",
              width: "100%",
              background: "none",
              border: "none",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "10px"
            }}
          >
            {icon}
          </div>
        )}
        {disabled && clickable && !loading && (
          <button
            type="button"
            onClick={onClick}
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              height: "40px",
              width: "100%",
              background: "none",
              border: "none",
              cursor: "pointer"
            }}
          />
        )}
      </div>
    );
  }
);

export default CustomTextInput;
