import { createReducer } from "reduxsauce";

import { types } from "./actions";

const INITIAL_STATE = {
  location: {
    lat: 37.3,
    lng: -121.9013695
  },
  zoom: 10,
  loadingMap: 0
};

export default createReducer(INITIAL_STATE, {
  [types.SET_STATE]: (state, { payload }) => ({ ...state, ...payload })
});
