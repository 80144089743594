import React, { createContext, useEffect, useState } from 'react';

export const ToggleSwipeContext = createContext();

export const ToggleSwipeProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSwipe, setIsSwipe] = useState(true);

  const toggleSwipe = (forceValue = !isSwipe) => {
    setIsSwipe(forceValue);
  };

  useEffect(() => {
    try {
      document.createEvent('TouchEvent');
      setIsMobile(true);
    } catch (e) {
      setIsMobile(false);
    }
  }, []);

  return (
    <ToggleSwipeContext.Provider value={{ isMobile, toggleSwipe, isSwipe }}>{children}</ToggleSwipeContext.Provider>
  );
};
