const province = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "AA",
  "AE",
  "AP",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
];

export default province;
export const TIMEOUT = 30 * 60 * 1000;
export const STATUSCODE = ["Active", "Suspended", "Dismissed"];
export const termsUrl = "https://www.duetinc.com/legal.html";
export const arrProgramColor = [
  "#3ddcf7",
  "#3bafda",
  "#009886",
  "#ffaa00",
  "#f76397",
  "#c71612",
  "#7266ba"
];
