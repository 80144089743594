/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-useless-return,react/no-array-index-key,consistent-return, react-hooks/exhaustive-deps, prefer-template, jsx-a11y/tabindex-no-positive, no-restricted-globals, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, camelcase  */

import React from 'react';
import './driver-schedule.css';

const TripMenu = ({ show, xyPosition, clickOption }) => {
  return (
    <div
      id="right-click-menu-container"
      className="right-click-menu-container"
      style={{ display: show ? 'block' : 'none' }}
      onClick={(e) => clickOption.handleRightClick(e)}
    >
      <div className="right-click-content">
        <div
          className="right-click-menu"
          style={{
            top: xyPosition?.y,
            left: xyPosition?.x,
          }}
        >
          <div className="right-click-menu-item" onClick={() => clickOption.shiftSchedule()}>
            Shift schedule
          </div>
          <div className="right-click-menu-item" onClick={() => clickOption.timeBlock()}>
            Time block
          </div>
          <div className="right-click-menu-item" onClick={() => clickOption.lunchBreak()}>
            Lunch break
          </div>
          <div className="right-click-menu-item" onClick={() => clickOption.driverAction()}>
            Driver action
          </div>
          <div className="right-click-menu-item" onClick={() => clickOption.multiLoad()}>
            Shared trip setup
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripMenu;
