import { createReducer } from "reduxsauce";
import store from "store";

import { types } from "./actions";

const STORED_SETTINGS = storedSettings => {
  const settings = {};
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`);
    settings[key] = typeof item !== "undefined" ? item : storedSettings[key];
  });
  return settings;
};

const INITIAL_STATE = {
  ...STORED_SETTINGS({
    isMobileView: false,
    isMobileMenuOpen: false,
    isSettingsOpen: false,
    isMenuCollapsed: true,
    isFixedWidth: false,
    locale: "en-US"
  })
};

export default createReducer(INITIAL_STATE, {
  [types.SET_STATE]: (state, { payload }) => ({ ...state, ...payload })
});
