/* eslint-disable */
import { useState, useCallback, useEffect } from "react";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";

export const useServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [showReload, setShowReload] = useState(false);
  // called when a service worker
  // updates. this function is a callback
  // to the actual service worker
  // registration onUpdate.
  const onSWUpdate = useCallback(registration => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
    // localStorage.setItem("newAppVersion", true);
    localStorage.setItem("newAppVersion", false);
  }, []);

  // simply put, this tells the service
  // worker to skip the waiting phase and then reloads the page
  const reloadPage = useCallback(async () => {
    if (waitingWorker) {
      waitingWorker?.postMessage({ type: "SKIP_WAITING" });
      setShowReload(false);
      localStorage.setItem("newAppVersion", false);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      await navigator.serviceWorker.ready.then(async worker => {
        await worker?.waiting?.postMessage({ type: "SKIP_WAITING" });
        setShowReload(false);
        // localStorage.setItem("newAppVersion", false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
    }
  }, [waitingWorker]);
  // register the service worker
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate
    });
  }, [onSWUpdate]);

  return { showReload, waitingWorker, reloadPage };
};
