/* eslint-disable */
import React, { PureComponent } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import Loader from './components/LayoutComponents/Loader';
import NotFound from './pages/404';
import IndexLayout from './layouts';
import getIPDetails from './services/ipapi';
import { logPageView } from './analytics';
import { currentAccount } from './services/auth';

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });

const routes = [
  // Auth pages
  {
    path: '/auth/login',
    component: loadable(() => import('./pages/auth/login/index')),
    privilege: [],
    exact: true,
  },
  {
    path: '/auth/signup',
    // component: Signup,
    component: loadable(() => import('./pages/auth/signup')),
    privilege: [],
    exact: true,
  },
  {
    path: '/auth/forgotpassword',
    // component: Signup,
    component: loadable(() => import('./pages/auth/forgotPassword')),
    privilege: [],
    exact: true,
  },

  // Dashboard
  {
    path: '/dashboard',
    component: loadable(() => import('./pages/dashboard')),
    privilege: [1, 3],
    exact: true,
  },
  {
    path: '/dashboard/task-panel',
    component: loadable(() => import('./pages/dashboard/taskPanel')),
    privilege: [1, 3],
    exact: true,
  },
  {
    path: '/dashboard/change/:booking_id/:trip_id',
    component: loadable(() => import('./pages/dashboard/change')),
    privilege: [1, 3],
    exact: true,
  },
  {
    path: '/dashboard/bookings-view/:id',
    component: loadable(() => import('./pages/dashboard/viewBookingInfo')),
    privilege: [1, 3],
    exact: true,
  },
  {
    path: '/reports/graphs',
    component: loadable(() => import('./pages/reports')),
    privilege: [3, 4],
    exact: true,
  },
  // {
  //   path: "/reports/history/view/:id",
  //   component: loadable(() => import("./pages/history/viewBookingInfo")),
  //   privilege: [3, 4],
  //   exact: true
  // },
  {
    path: '/reports/history/changePaymentStatus/:booking_id',
    component: loadable(() => import('./pages/history/changePaymentStatus')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/reports/map',
    component: loadable(() => import('./pages/odmapdisplay')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/reports/history',
    component: loadable(() => import('./pages/history')),
    privilege: [3, 4],
    exact: true,
  },
  // {
  //   path: '/reports/pdfviewer',
  //   component: loadable(() => import('./pages/pdf-viewer')),
  //   privilege: [3, 4],
  //   exact: true,
  // },
  {
    path: '/blank',
    component: loadable(() => import('./pages/blank')),
    privilege: [],
    exact: true,
  },
  // Fleet/Vehicles
  {
    path: '/fleet/vehicles',
    component: loadable(() => import('./pages/fleet/vehicles')),
    privilege: [3, 4],
    exact: false,
  },
  // Fleet/drivers
  {
    path: '/fleet/drivers/list',
    component: loadable(() => import('./pages/fleet/drivers')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/fleet/drivers/schedule',
    component: loadable(() => import('./pages/fleet/drivers/schedule')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/fleet/drivers/report',
    component: loadable(() => import('./pages/fleet/drivers/ShiftReport')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/fleet/drivers',
    component: loadable(() => import('./pages/fleet/drivers')),
    privilege: [3, 4],
    exact: false,
  },
  {
    path: '/fleet/trips',
    component: loadable(() => import('./pages/fleet/trips')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/fleet/dispatch',
    component: loadable(() => import('./pages/fleet/drivers/dispatch')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/fleet/dispatchtemplate',
    component: loadable(() => import('./pages/fleet/drivers/templateIndex')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/fleet/dispatchtemplate/view/templateId/:id',
    component: loadable(() => import('./pages/fleet/drivers/savedTemplateDispatch')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/fleet/subscription',
    component: loadable(() => import('./pages/fleet/subscription')),
    privilege: [3, 4],
    exact: true,
  },
  // rider service
  {
    path: '/rider-services/reservations',
    component: loadable(() => import('./pages/rider-services/reservations')),
    privilege: [1, 2],
    exact: false,
  },
  {
    path: '/rider-services/bookings',
    component: loadable(() => import('./pages/rider-services/bookings')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/bookings/futurebookinglist',
    component: loadable(() => import('./pages/rider-services/bookings/futureBookingList')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/bookings/prepareNew',
    component: loadable(() => import('./pages/rider-services/bookings/PrepareNewPage')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/bookings/newBooking',
    component: loadable(() => import('./pages/rider-services/bookings/newBooking')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/bookings/assess',
    component: loadable(() => import('./pages/rider-services/bookings/assess')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/bookings/assess/first-trip',
    component: loadable(() => import('./pages/rider-services/bookings/firsttrip')),
    privilege: [1, 2],
    exact: true,
  },
  // NEM Transport /rider-services/nem-transport/newInsurance
  {
    path: '/rider-services/nem-transport',
    component: loadable(() => import('./pages/rider-services/nem-transport/listIndex')),
    privilege: [1, 2],
    exact: true,
  },

  {
    path: '/rider-services/nem-transport/select',
    component: loadable(() => import('./pages/rider-services/nem-transport')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/nem-transport/newInsurance',
    component: loadable(() => import('./pages/rider-services/nem-transport/newInsurance')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/nem-transport/fileUpload',
    component: loadable(() => import('./pages/rider-services/nem-transport/fileUpload')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/broker-integration',
    component: loadable(() => import('./pages/rider-services/broker-integration/')),
    privilege: [1, 2],
    exact: true,
  },

  // {
  //   path: "/rider-services/bookings/prepareNew",
  //   component: loadable(() =>
  //     import("./pages/rider-services/bookings/prepareNew")
  //   ),
  //   exact: true
  // },
  {
    exact: true,
    path: '/rider-services/bookings/view/:id/',
    component: loadable(() => import('./pages/rider-services/bookings/viewBookingInfo')),
    privilege: [1, 2],
  },
  {
    path: '/rider-services/bookings/newsubscription/:id',
    component: loadable(() => import('./pages/rider-services/bookings/NewSubscription')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/bookings/change/:id',
    component: loadable(() => import('./pages/rider-services/bookings/prepareChange')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/bookings/modify/:booking_id/:passenger_id/:historySource?',
    component: loadable(() => import('./pages/rider-services/bookings/modifyBookingInfo')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/bookings/changePaymentStatus/:booking_id',
    component: loadable(() => import('./pages/rider-services/bookings/changePaymentStatus')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/members',
    component: loadable(() => import('./pages/rider-services/members')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/members/prepareNew',
    component: loadable(() => import('./pages/rider-services/members/prepareNew')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/members/view/:id',
    component: loadable(() => import('./pages/rider-services/members/viewMemberInfo')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/members/modify/:user_id',
    component: loadable(() => import('./pages/rider-services/members/modifyMemberInfo')),
    privilege: [1, 2],
    exact: true,
  },
  {
    path: '/rider-services/riders',
    component: loadable(() => import('./pages/rider-services/riders')),
    privilege: [1, 2],
    exact: true,
  },
  // programs/services
  {
    path: '/programs/services',
    component: loadable(() => import('./pages/programs/services')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/programs/accounts',
    component: loadable(() => import('./pages/programs/accounts')),
    privilege: [3, 4],
    exact: true,
  },
  // Account Management

  {
    path: '/programs/pricing',
    component: loadable(() => import('./pages/programs/pricing')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/account_mngm/users',
    component: loadable(() => import('./pages/account-mngm/users')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/account_mngm/plan-billing',
    component: loadable(() => import('./pages/account-mngm/billing')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/account_mngm/payment-method',
    component: loadable(() => import('./pages/account-mngm/payment')),
    privilege: [3, 4],
    exact: true,
  },
  {
    path: '/account_mngm/payment-method/stripe-payment',
    component: loadable(() => import('./pages/account-mngm/payment/stripePayment')),
    privilege: [3, 4],
    exact: true,
  },
  // contact info
  {
    path: '/contact/contact-info',
    component: loadable(() => import('./pages/contact')),
    privilege: [],
    exact: true,
  },
];

@connect(({ auth }) => ({ auth }))
class Router extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      startTime: Date.now(),
    };
  }

  async componentDidMount() {
    const env = window._env_.DEPLOYMENT_PROFILE;

    try {
      if (env !== 'dev' && env !== 'preDev') {
        const { data } = await getIPDetails();
        if (data) {
          const { longitude, latitude, country_code } = data;
          if (country_code.toUpperCase() === 'US') {
            if (
              (latitude > 37.001716 || latitude < 37.001716) &&
              longitude < -94.618903 &&
              !window.location.href.startsWith('https://usnorthwest.duetride.com') &&
              !window.location.href.startsWith('https://duetride.com') &&
              !window.location.href.startsWith('https://duetride.io') &&
              !window.location.href.startsWith('https://predev.duetride.io')
            ) {
              window.location.href = 'https://usnorthwest.duetride.com/';
            } else if (
              (latitude > 37.001716 || latitude < 37.001716) &&
              longitude > -94.618903 &&
              !window.location.href.startsWith('https://duetride.com') &&
              !window.location.href.startsWith('https://duetride.io') &&
              !window.location.href.startsWith('https://predev.duetride.io')
            ) {
              window.location.href = 'https://duetride.com/';
            }
          } else if (
            !window.location.href.startsWith('https://apacaus.duetride.com') &&
            !window.location.href.startsWith('https://duetride.io') &&
            !window.location.href.startsWith('https://predev.duetride.io') &&
            !window.location.href.startsWith('https://localhost')
          ) {
            window.location.href = 'https://apacaus.duetride.com/';
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  componentDidUpdate(prevProps) {
    const logUserPageView = async (pageViewStartTime, pageViewEndTime) => {
      try {
        const user = await currentAccount();
        logPageView(user?.id, pageViewEndTime - pageViewStartTime);
      } catch (e) {
        if (e.message !== 'Not logged in.') {
          console.error(e);
        }
      }
    };

    if (this.props.location !== prevProps.location) {
      // Log the page view on route change
      const { startTime } = this.state;
      const endTime = Date.now();
      logUserPageView(startTime, endTime);
      this.setState({ startTime: endTime });
    }
  }

  generateRoute = (route) => {
    const { auth } = this.props;

    if (!auth.authorized || route.privilege.length === 0)
      return <Route path={route.path} component={route.component} key={route.path} exact={route.exact} />;

    for (let index = 0; index < route.privilege.length; index++) {
      const element = route.privilege[index];
      if (auth.is_admin && auth.is_admin.includes(element)) {
        return <Route path={route.path} component={route.component} key={route.path} exact={route.exact} />;
        break;
      }
    }
  };

  render() {
    const { history, auth } = this.props;

    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" key="/" render={() => <Redirect to="/dashboard" />} />
            {routes.map((route) => this.generateRoute(route))}

            {/* <Route path="/reports/map" component={ODMapPage}  /> */}
            {/* <Route path="/reports/history" component={HistoryDisplay} /> */}
            {/* <Route path="/reports/history/view/:id" component={bookingHistoryView} /> */}

            <Route component={NotFound} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    );
  }
}

export default withRouter(Router);
