import { all } from "redux-saga/effects";

import auth from "./auth/sagas";
import menu from "./menu/sagas";
import map from "./map/sagas";
import settings from "./settings/sagas";
import vehicle from "./vehicle/sagas";
import drivers from "./drivers/sagas";
import common from "./common/sagas";
import bookings from "./rider-services/bookings/sagas";
import nem from "./rider-services/NEM_Transport/sagas";
import services from "./programs/services/sagas";
import users from "./account-management/users/sagas";
import billings from "./account-management/billing/sagas";
import bookinghistory from "./reports/history/sagas";
import trips from "./trips/sagas";
import members from "./rider-services/members/sagas";

export default function* rootSaga() {
  yield all([
    auth(),
    menu(),
    settings(),
    map(),
    vehicle(),
    drivers(),
    common(),
    bookings(),
    nem(),
    services(),
    users(),
    billings(),
    bookinghistory(),
    trips(),
    members()
  ]);
}
