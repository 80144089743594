/* eslint-disable camelcase */
import { all, takeEvery, put, call } from "redux-saga/effects";
import {
  fetchNEMList,
  fetchNEMInfoByID
} from "../../../services/rider-services/nemBookings";
import { types } from "./actions";
import { types as commonTypes } from "../../common/actions";

export function* FETCH_NEMS_LIST() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchNEMList);

  if (response) {
    if (response.status === "success") {
      yield put({
        type: types.SET_STATE,
        payload: {
          nemList: response.data.prebookings
        }
      });
      // } else {
      //   yield put({
      //     type: commonTypes.SET_STATE,
      //     payload: {
      //       isError: true,
      //       errContent: response.message.message
      //     }
      //   });
    }
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* FETCH_NEM_INFO_BY_ID({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchNEMInfoByID, payload);
  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        nemInfoById: response.data
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* RESET_NEM_LIST() {
  yield put({
    type: types.SET_STATE,
    payload: {
      nemList: []
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_NEMS_LIST, FETCH_NEMS_LIST),
    takeEvery(types.FETCH_NEM_INFO_BY_ID, FETCH_NEM_INFO_BY_ID),
    takeEvery(types.RESET_NEM_LIST, RESET_NEM_LIST),
    null
  ]);
}
