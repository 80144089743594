/* eslint-disable react/no-array-index-key */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./style.module.scss";

const Route = require("route-parser");

// const route = new Route('/my/fancy/route/page/:page/:id');
// const a = route.match('/my/fancy/route/page/7/s')
// console.log(a);

const mapStateToProps = ({ menu }) => ({
  menuLeftData: menu.menuLeftData,
  topData: menu.topData
});

@withRouter
@connect(mapStateToProps)
@connect(
  ({ auth }) => ({ auth }),
  null
)
class Breadcrumbs extends React.Component {
  state = {
    breadcrumb: [],
    activeMenuItem: []
  };

  componentDidMount() {
    // console.log("componentDidMount");
    this.setBreadcrumbs(this.props);
  }

  componentWillReceiveProps(newProps) {
    // console.log("componentWillReceiveProps=============", newProps);
    this.setBreadcrumbs(newProps);
  }

  setBreadcrumbs = props => {
    const { menuLeftData } = this.props;

    this.setState({
      breadcrumb: this.getBreadcrumb(props, menuLeftData)
    });
  };

  getPath(data, url, parents = []) {
    const cleanUrl = url.replace(/\/+$/, ""); // clean tailing slash

    const items = _.reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result;
        }

        if (entry.url !== undefined) {
          const route = new Route(entry.url);
          const bisMatch = route.match(cleanUrl);

          if (bisMatch !== false) {
            return [entry].concat(parents);
          }
        }

        if (entry.children) {
          const nested = this.getPath(
            entry.children,
            url,
            [entry].concat(parents)
          );
          return (result || []).concat(nested.filter(e => !!e));
        }
        return result;
      },
      []
    );
    return items.length > 0 ? items : [false];
  }

  getBreadcrumb = (props, items) => {
    const [activeMenuItem, ...path] = this.getPath(
      items,
      props.location.pathname
    );

    this.setState({
      activeMenuItem
    });

    if (activeMenuItem && path.length) {
      return path.reverse().map((item, index) => {
        if (index === path.length - 1) {
          return [
            <li className="breadcrumb-item" key={item.key}>
              {item.url !== "/" ? (
                <Link to={item.url}>{item.title}</Link>
              ) : (
                item.title
              )}
            </li>,
            <li className="breadcrumb-item" key={activeMenuItem.key}>
              {activeMenuItem.title}
            </li>
          ];
        }
        return (
          <li className="breadcrumb-item" key={index}>
            <a className="text-muted">{item.title}</a>
          </li>
        );
      });
    }

    if (!activeMenuItem) {
      return <li className="breadcrumb-item">Error</li>;
    }

    return <li className="breadcrumb-item active">{activeMenuItem.title}</li>;
  };

  render() {
    const { breadcrumb, activeMenuItem } = this.state;
    const { auth } = this.props;
    // console.log(breadcrumb);
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className={`${styles.breadcrumb} page-title-box`}>
            <h4 className="page-title">{activeMenuItem.title}</h4>
            <ol className="breadcrumb float-right">
              <li key="duet" className="breadcrumb-item">
                {auth.is_admin.includes(1) || auth.is_admin.includes(3) ? (
                  <Link to="/dashboard">Duet</Link>
                ) : (
                  <Link to="/rider-services/bookings">Duet</Link>
                )}
              </li>
              {breadcrumb}
            </ol>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumbs;
