import React, { useState } from "react";

import styles from "./style.module.scss";

import customInput from "../../../components/Elements/TextInput/customInput.module.scss";

const AddNewContact = ({ number = 1, handleClose, handleSave }) => {
  const [contact, setContact] = useState("");

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="card-box">
          <div className={styles["inputs-container"]}>
            <div className={customInput.input}>
              <div className={customInput.label}>Contact {number}</div>
              <input
                className={customInput["text-input"]}
                type="text"
                onChange={e => setContact(e.target.value.trim())}
              />
            </div>
          </div>
        </div>
        <div className={styles.button__container}>
          <button
            type="button"
            className={styles.add__more}
            style={{ float: "unset" }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={styles.close}
            onClick={() => handleSave(contact)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewContact;
