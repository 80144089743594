/* eslint-disable camelcase */
import parse from "../_parse";

export async function fetchBillingsList() {
  try {
    const lists = await parse.Cloud.run("v2_webapp_get_employer_admins");
    return lists;
  } catch (error) {
    console.log(
      "services/account-management/billing.js::fetchBillingsList() = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function getInvoiceById(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_invoice_by_id",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function getInvoice(param) {
  try {
    const response = await parse.Cloud.run("v1_webapp_get_invoices", param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}
