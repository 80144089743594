/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import aws from "aws-sdk";
import parse from "../_parse";
import "../../config";

require("dotenv").config();

const env = window._env_.DEPLOYMENT_PROFILE;

aws.config.update({
  region:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevRegion
      : process.env.REACT_APP_ProdRegion,
  accessKeyId:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevAccessKeyId
      : process.env.REACT_APP_ProdAccessKeyId,
  secretAccessKey:
    env === "dev" || env === "preDev"
      ? process.env.REACT_APP_DevSecretAccessKey
      : process.env.REACT_APP_ProdSecretAccessKey
});
const s3 = new aws.S3();

export async function fetchLabel() {
  try {
    const data = await parse.Cloud.run("v1_webapp_get_user_fleet_name");
    return { status: 1, data };
  } catch (error) {
    console.log("fetchLabel() = ", JSON.stringify(error));
    return { status: 0, data: error };
  }
}

export async function uploadImageToS3(params) {
  const s3Params = {
    // Bucket: env === "dev" ? devBucket : prodBucket,
    Bucket:
      env === "dev" || env === "preDev"
        ? process.env.REACT_APP_DevBucket
        : process.env.REACT_APP_ProdBucket,
    Body: params,
    Key: params.name,
    Expires: 500,
    ContentType: params.type,
    ACL: "public-read"
  };

  // console.log(s3Params);

  return new Promise(resolve => {
    // eslint-disable-next-line func-names
    s3.upload(s3Params, function(err, data) {
      if (err) {
        // console.log("file upload fail");
        // console.log(err, err.stack); // an error occurred
        // console.log(JSON.stringify(err));
        resolve({ status: false, desc: err });
      } else {
        // console.log("file upload success", JSON.stringify(data));
        resolve({ status: true, desc: data });
      }
    });
  });
}

export async function fetchMemberInfoByID(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_community_member_data",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

// export async function getAvailablePrograms() {
//   try {
//     const response = await parse.Cloud.run("v1_webapp_get_available_programs");
//     // console.log(JSON.stringify(response));
//     return response;
//   } catch (error) {
//     console.log(
//       "Service members::getAvailablePrograms = ",
//       JSON.stringify(error)
//     );
//     return error;
//   }
// }

export async function createNewMember(param) {
  try {
    const response = await parse.Cloud.run("v1_webapp_create_new_", param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("Service members::createNewMember = ", JSON.stringify(error));
    return error;
  }
}

export async function editMember(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_update_community_member",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("Service members::editMember = ", JSON.stringify(error));
    return error;
  }
}

export async function getCommunityMemberList() {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_community_member_list"
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service members::getCommunityMemberList = ",
      JSON.stringify(error)
    );
    return error;
  }
}

export async function updateCommunityMember(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_update_community_member",
      param
    );
    console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(
      "Service members::updateCommunityMember = ",
      JSON.stringify(error)
    );
    return error;
  }
}
