/* eslint-disable */

import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { useServiceWorker } from "./useServiceWorker";

const AppVersionChecker = ({ setShowNotification, showNotification }) => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  useEffect(() => {
    if (showReload && waitingWorker) {
      setShowNotification(true);
      // localStorage.setItem("newAppVersion", true);
      localStorage.setItem("newAppVersion", false);
    }
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <>
      <div
        className={styles.update__notificaiton__container}
        style={{ display: showNotification ? "block" : "none" }}
      >
        <div>
          <span>A new version of the app is ready for download.</span>
          <button onClick={() => reloadPage()} type="button">
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default AppVersionChecker;
