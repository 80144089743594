import { createReducer } from "reduxsauce";

import { types } from "./actions";

const INITIAL_STATE = {
  loading: false,
  driverList: [],
  driverInfoById: {},
  newDriverStatus: null,
  existedDriverUpdate: false,
  newDriverId: null,
  label: "",
  driverObj: {},
  bExistEmail: null,
  bNewEmail: null,
  bErrorForNewEmail: null,
  sErrorMsgForNewEmail:
    "We are having problem of sending a confirmation to this number. Please check the number entered.",
  bVerificationCodeError: null,
  userObj: {},
  isPasswordUpdate: false
};

export default createReducer(INITIAL_STATE, {
  [types.SET_STATE]: (state, { payload }) => ({ ...state, ...payload })
});
