import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchUsersList: ["payload"],
    resetUsersList: null
  },
  { prefix: "USERS_" }
);

export const types = Types;
export default Creators;
