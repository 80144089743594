/* eslint-disable import/prefer-default-export */

import { signInAnonymously } from "firebase/auth";
import { auth } from "../lib/firebase";

export const anonymousSignin = async () => {
  try {
    await signInAnonymously(auth);

    return {
      success: true,
      error: false
    };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      error
    };
  }
};
