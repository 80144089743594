import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    changeSetting: ["payload"]
  },
  { prefix: "SETTINGS_" }
);

export const types = Types;
export default Creators;
