/* eslint-disable camelcase */
import parse from "../_parse";

export async function fetchProgramsList() {
  try {
    const lists = await parse.Cloud.run("v2_webapp_get_fleet_programs ");
    return lists;
  } catch (error) {
    console.log("fetchDriversList() = ", JSON.stringify(error));
    return error;
  }
}

export async function getProgramFeePlans(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_program_fee_plans",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("getProgramPricings = ", JSON.stringify(error));
    return error;
  }
}

export async function getProgramPricings(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_program_pricing_info",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log("getProgramPricings = ", JSON.stringify(error));
    return error;
  }
}

export async function createFeePlan(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_create_new_fee_plan",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function saveFeePlan(param) {
  try {
    const response = await parse.Cloud.run("v1_webapp_save_fee_plan", param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}
export async function saveFeePlanInfo(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_save_fee_plan_info",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function saveNewAdditionFee(param) {
  try {
    const resp = await parse.Cloud.run("v1_webapp_save_new_addl_fee", param);

    return resp;
  } catch (error) {
    console.log("saveNewAdditionFee() = ", JSON.stringify(error));
    return error;
  }
}

export async function editAdditionalFee(param) {
  try {
    const resp = await parse.Cloud.run("v1_webapp_edit_addl_fee", param);

    return resp;
  } catch (error) {
    console.log("editAdditionalFee() = ", JSON.stringify(error));
    return error;
  }
}

export async function addNewAdditionalFeeLabel(param) {
  try {
    param.type = 1; // type additional Fee
    const resp = await parse.Cloud.run(
      "v1_webapp_add_new_addl_info_label",
      param
    );

    return resp;
  } catch (error) {
    console.log("addNewAdditionalFeeLabel() = ", JSON.stringify(error));
    return error;
  }
}

export async function getAdditionalFeeLabel(param) {
  try {
    const resp = await parse.Cloud.run("v1_webapp_get_addl_info_labels", param);

    return resp;
  } catch (error) {
    console.log("getAdditionalFeeLabel() = ", JSON.stringify(error));
    return error;
  }
}

export async function getFeePlanList(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_fee_plan_list",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function fetchBookingInfoByID(param) {
  try {
    const response = await parse.Cloud.run(
      "v2_webapp_get_booking_info_by_id",
      param
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}

export async function fetchProgramPricingInfo(params) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_get_program_pricing_info",
      params
    );
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}
