import React from 'react';
import { Button } from "react-bootstrap";
import DuetModal, { DuetFullButtons } from '../../../components/Modals/modal';
import modalStyle from '../../../components/Modals/modal.module.scss';
import style from './driver-info.module.scss';


const DriverActionInfo = ({ show, close }) => {
  return (
    <DuetModal show={show} dialogClassName={modalStyle.modal_width_660} close={close}>
      <div className="edit-modal-content">
        <div className={style.header}>
          <strong>How does Driver Action work?</strong>
        </div>
        <div className={style.content}>
          <div style={{ marginTop: '20px' }}>
            <strong>Driver Action</strong> is your instruction and assignment to drivers to perform other than trips.
          </div>
          <div style={{ marginTop: '20px' }}>
            Driver action will be displayed just like a trip in the driver app but under the &quot;rider&quot; name of <strong>From Office.</strong> Please ask your drivers to tag and complete it like a trip.
          </div>
          <div style={{ marginTop: '20px' }}>
            Examples of Driver Action:
          </div>
          <div style={{ marginTop: '20px' }}>
            <ul>
              <li>Take the van to the shop</li>
              <li>Pick up the van at the parking lot</li>
              <li>Pick up John Smith to collaborate on a trip</li>
              <li>Pick up a wheelchair at the office</li>
            </ul>
          </div>
          <div style={{ marginTop: '20px' }}>
            <strong>Note:</strong> Driver Action is different from Lunch Break. Driver Action is <strong>Not</strong> tracked or recorded in driver shift time report. Whereas Lunch Break time is tracked and recorded separately in the driver shift report.
          </div>
        </div>
      </div>
      <DuetFullButtons>
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="primary"
            className={modalStyle.button}
            style={{ marginLeft: "10px" }}
            onClick={close}
          >
            Close
          </Button>
        </div>
      </DuetFullButtons>
    </DuetModal>
  )
}

export default DriverActionInfo