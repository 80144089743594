/* eslint-disable camelcase */
import { all, takeEvery, put, call } from "redux-saga/effects";
import {
  fetchMemberInfoByID,
  editMember,
  getCommunityMemberList
} from "../../../services/rider-services/members";
import { types } from "./actions";
import { types as commonTypes } from "../../common/actions";

// function compare(a, b) {
//   const genreA = a.adjusted_pickup_time.unix_epoch;
//   const genreB = b.adjusted_pickup_time.unix_epoch;

//   let comparison = 0;
//   if (genreA < genreB) {
//     comparison = 1;
//   } else if (genreA > genreB) {
//     comparison = -1;
//   }
//   return comparison;
// }

export function* FETCH_COMMUNITY_MEMBER_LIST() {
  // console.log(Date.now());
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(getCommunityMemberList);

  if (response) {
    if (response.status === "success") {
      // const memberList = response.data.sort(compare);
      const memberList = response.data;
      yield put({
        type: types.SET_STATE,
        payload: {
          memberList,
          newMemberStatus: false,
          memberInfoById: {},
          bUpdateStatus: null,
          errorMsg: ""
        }
      });
    }
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* FETCH_MEMBER_INFO_BY_ID({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchMemberInfoByID, payload);
  // console.log(response);
  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        memberInfoById: response.data
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* UPDATE_EXIST_MEMBER({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(editMember, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bUpdateStatus: true,
        errorMsg: ""
      }
    });
  } else {
    yield put({
      type: types.SET_STATE,
      payload: {
        bUpdateStatus: false,
        errorMsg: response.message.message
      }
    });
    // yield put({
    //   type: commonTypes.SET_STATE,
    //   payload: {
    //     isError: true,
    //     errContent: response.message.message
    //   }
    // });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}
/*
export function* CREATE_NEW_BOOKING({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  reactLocalStorage.setObject("newUserInfo", {});

  const respForS3 = yield call(uploadImageToS3, payload.photoObj);
  const respForDLS3 = yield call(uploadImageToS3, payload.dl_photoObj);
  // console.log("respForS3 = ", JSON.stringify(respForS3));
  // console.log("respForDLS3 = ", JSON.stringify(respForDLS3));
  if (respForS3.status && respForDLS3.status) {
    payload = {
      ...payload,
      booking_photo: respForS3.desc.Key,
      dl_photo: respForDLS3.desc.Key
    };
    // console.log(JSON.stringify(payload));
    const response = yield call(createNewBooking, payload);

    if (response.status === "success") {
      yield put({
        type: types.SET_STATE,
        payload: {
          newBookingStatus: true
        }
      });
    } else {
      yield put({
        type: types.SET_STATE,
        payload: {
          newBookingStatus: false
        }
      });

      yield put({
        type: commonTypes.SET_STATE,
        payload: {
          isError: true,
          errContent: response.message.message
        }
      });
    }
  }
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}
*/
export function* RESET_MEMBER_INFO() {
  yield put({
    type: types.SET_STATE,
    payload: {
      memberInfoById: {}
    }
  });
}

export function* RESET_MEMBER_LIST() {
  yield put({
    type: types.SET_STATE,
    payload: {
      memberList: []
    }
  });
}

export function* SET_SPINNER() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });
}

export function* UNSET_SPINNER() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_COMMUNITY_MEMBER_LIST, FETCH_COMMUNITY_MEMBER_LIST),
    takeEvery(types.RESET_MEMBER_INFO, RESET_MEMBER_INFO),
    takeEvery(types.UPDATE_EXIST_MEMBER, UPDATE_EXIST_MEMBER),
    takeEvery(types.FETCH_MEMBER_INFO_BY_ID, FETCH_MEMBER_INFO_BY_ID),
    takeEvery(types.RESET_MEMBER_LIST, RESET_MEMBER_LIST),
    takeEvery(types.RESET_MEMBER_INFO, RESET_MEMBER_INFO),
    takeEvery(types.SET_SPINNER, SET_SPINNER),
    takeEvery(types.UNSET_SPINNER, UNSET_SPINNER),
    null
  ]);
}
