/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import DuetModal2, { DuetLeftRightButtons, DuetIcon } from '../../../components/Modals/modal2';
import modalStyles from '../../../components/Modals/modal2.module.scss';

export default function TrackingLinkModal({
  show,
  TrackingDomain,
  showTrackingLink,
  onClickTrackingLink,
  onClickCopyIcon,
  onClickClose,
}) {
  return (
    <DuetModal2 show={show} onClickClose={onClickClose}>
      <TrackingLink
        TrackingDomain={TrackingDomain}
        showTrackingLink={showTrackingLink}
        onClickTrackingLink={onClickTrackingLink}
        onClickCopyIcon={onClickCopyIcon}
        onClickClose={onClickClose}
      />
    </DuetModal2>
  );
}

const TrackingLink = ({ TrackingDomain, showTrackingLink, onClickTrackingLink, onClickCopyIcon, onClickClose }) => {
  return (
    <>
      <div style={{ fontSize: '15px' }}>
        <DuetIcon type="info" />

        <div style={{ paddingBottom: '16px' }}>
          Copy the vehicle tracking url below, and share it via email or text message so that riders or other users can
          track their vehicles on their own.
        </div>

        <div className="py-1 url" onClick={onClickTrackingLink}>
          <span>{`${TrackingDomain}${showTrackingLink}`}</span>

          <div className="copy-button">
            <div
              onClick={onClickCopyIcon}
              variant="secondary"
              style={{ marginLeft: 10, fill: '#118bf0' }}
              className="fa fa-copy"
            />{' '}
            <span>&nbsp;Copy url</span>
          </div>
        </div>

        <div style={{ paddingTop: '16px' }}>
          <strong className="text-bold">Note:</strong>&nbsp; Tracking starts 30 minutes before scheduled pickup time,
          and ends once the trip is completed or the trip date ends.
        </div>

        <DuetLeftRightButtons>
          <Button
            variant="primary"
            className={classNames(modalStyles.button, modalStyles['second-button'], 'w-80', 'm-l-12')}
            onClick={onClickClose}
          >
            Close
          </Button>
        </DuetLeftRightButtons>
      </div>
    </>
  );
};
