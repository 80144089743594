import { createReducer } from "reduxsauce";
import { types } from "./actions";

export const INITIAL_STATE = {
  userId: "",
  name: "",
  role: "",
  email: "",
  avatar: "",
  is_admin: [],
  authorized: false,
  loading: false,
  employer_programs: [],
  bSuccessSectionVisible: false,
  bErrorSectionVisible: false
  // savedUserInfo: {
  //   email: "",
  //   password: ""
  // }
};

export default createReducer(INITIAL_STATE, {
  [types.SET_STATE]: (state, { payload }) => ({ ...state, ...payload })
});
