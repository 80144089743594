import React from 'react';
import { connect } from 'react-redux';
import settingsAction from 'redux/settings/actions';
import classNames from 'classnames';
import Logo from './Logo';
import Fullscreen from './Fullscreen';
// import SettingMenu from './SettingMenu';
import NotificationMenu from './NotificationMenu';
import ProfileMenu from './ProfileMenu';
import SidebarCollapseTrigger from './SidebarCollapseTrigger';
import styles from './style.module.scss';
import NewReservationButton from './NewReservation';
import ToggleSwipe from './ToogleSwipe';
import togleStyle from './ToogleSwipe/style.module.scss';
import TripScheduleButton from './TripSchedule';

const mapStateToProps = ({ settings }) => ({
  isMenuCollapsed: settings.isMenuCollapsed,
  isSettingsOpen: settings.isSettingsOpen,
});
const mapDispatchToProps = {
  changeSetting: settingsAction.changeSetting,
};

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class TopBar extends React.Component {
  onSidebarCollapseTrigger = () => {
    const { changeSetting, isMenuCollapsed } = this.props;
    changeSetting({
      setting: 'isMenuCollapsed',
      value: !isMenuCollapsed,
    });
  };

  onSettingTrigger = () => {
    const { changeSetting, isSettingsOpen } = this.props;

    changeSetting({
      setting: 'isSettingsOpen',
      value: !isSettingsOpen,
    });
  };

  render() {
    const { isMenuCollapsed } = this.props;

    return (
      <div className="topbar">
        <Logo />
        {/* Button mobile view to collapse sidebar menu  */}
        <nav className="navbar-custom">
          <ul className="list-inline float-right mb-0">
            <li className="list-inline-item notification-list hide-phone">
              <Fullscreen />
            </li>
            {/* <li className="list-inline-item notification-list">
              <SettingMenu onToggle={this.onSettingTrigger} />
            </li> */}
            <li className="list-inline-item notification-list dropdown hide-phone">
              <NotificationMenu />
            </li>
            <li className="list-inline-item notification-list dropdown">
              <ProfileMenu />
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left" style={{ lineHeight: 1.5 }}>
              <SidebarCollapseTrigger onTrigger={this.onSidebarCollapseTrigger} />
            </li>
            <li
              className={classNames(styles['new-reservation-button-container'], {
                [styles['open-menu']]: !isMenuCollapsed,
                [styles['close-menu']]: isMenuCollapsed,
              })}
            >
              <NewReservationButton />
            </li>
            <li
              className={classNames(styles['new-reservation-button-container'], {
                [styles['open-menu']]: !isMenuCollapsed,
                [styles['close-menu']]: isMenuCollapsed,
              })}
            >
              {' '}
              <TripScheduleButton />
            </li>
            <li className={`list-inline-item notification-list dropdown ${togleStyle.toggle__container}`}>
              <ToggleSwipe />
            </li>
            {/* <li className={`list-inline-item notification-list dropdown ${togleStyle.toggle__container}`}>
              <ToggleSwipe />
            </li> */}
          </ul>
        </nav>
      </div>
    );
  }
}

export default TopBar;
