/* eslint-disable import/prefer-default-export, radix, no-unneeded-ternary, operator-assignment,   prefer-template , no-nested-ternary */
/* eslint-disable */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const willOverflowNextDay = (date, minutesToAdd) => {
  // Create a new Date object to avoid modifying the original date
  var newDate = new Date(date);

  // Add the specified number of minutes
  newDate.setMinutes(newDate.getMinutes() + minutesToAdd);

  // Get the day before and after adding minutes
  var dayBefore = date.getDate();
  var dayAfter = newDate.getDate();

  // Check if the day has changed
  return dayAfter !== dayBefore;
};

export const diffInMinutes = (date1, date2) => {
  // Convert dates to milliseconds
  var diffInMilliseconds = date2.getTime() - date1.getTime();

  // Convert milliseconds to minutes
  var diffInMin = Math.abs(diffInMilliseconds) / (1000 * 60);

  // Check the sign of the difference
  if (diffInMilliseconds < 0) {
    // Negative difference
    return -diffInMin;
  } else {
    // Positive difference
    return diffInMin;
  }
};

export const getAMPMFromDate = (date) => {
  // Get the hour from the date
  var hour = date.getHours();

  // Determine whether it's AM or PM based on the hour
  var period = hour < 12 ? 'AM' : 'PM';

  return period;
};
export const NumbersOnly = (str) => {
  return /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(str);
};

export const LettersAndNumbersOnly = (str) => {
  return /^[A-Za-z0-9 ]*$/.test(str);
};

export const convertToMiles = (value) => {
  const mile = parseFloat(value, 10) / 1609.34;
  if (mile === 0) {
    return 0;
  }
  return mile.toFixed(2);
};

export const convertToMeters = (value) => {
  const meters = parseFloat(value, 10) * 1609.34;
  if (meters === 0) {
    return 0;
  }
  return meters;
};

export const convertToDollar = (value) => {
  return (value / 100).toFixed(2);
};

export const convertToCents = (value) => {
  return Math.abs(value * 100);
};

export const convertTo12HourFormat = (time) => {
  // Split the input string into hours and minutes
  let [hours, minutes] = time.split(':').map(Number);

  // Determine AM or PM suffix
  let suffix = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour format to 12-hour format
  hours = hours % 12 || 12; // Convert '0' to '12' for 12 AM and handle other conversions

  // Format the result as "h:mm AM/PM" without leading zero in hours
  let formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${suffix}`;

  return formattedTime;
};

export const addMinutes = (timeStr, minutesToAdd) => {
  // Parse the time string (e.g., "12:00 AM")
  const timeParts = timeStr.match(/^(\d{1,2}):(\d{2}) (AM|PM)$/);

  if (!timeParts) {
    throw new Error("Invalid time format. Please use 'hh:mm AM/PM'.");
  }

  let hours = parseInt(timeParts[1], 10);
  let minutes = parseInt(timeParts[2], 10);
  const period = timeParts[3];

  // Convert time to 24-hour format for easier manipulation
  if (period === 'AM' && hours === 12) {
    hours = 0; // Midnight is 00:xx in 24-hour format
  } else if (period === 'PM' && hours !== 12) {
    hours += 12; // Convert PM to 24-hour format
  }

  // Add the minutes
  const totalMinutes = hours * 60 + minutes + minutesToAdd;

  // Ensure minutes wrap around within a 24-hour period
  const newHours24 = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;

  // Normalize for negative minutes or hours
  const normalizedHours24 = (newHours24 + 24) % 24;
  const normalizedMinutes = (newMinutes + 60) % 60;

  // Convert back to 12-hour format
  const newPeriod = normalizedHours24 >= 12 ? 'PM' : 'AM';
  const newHours12 = normalizedHours24 % 12 || 12; // Convert 0 to 12 for 12-hour format

  // Format the time back to "hh:mm AM/PM"
  const newTime = `${newHours12}:${normalizedMinutes.toString().padStart(2, '0')} ${newPeriod}`;

  return newTime;
};

export const addSeconds = (time, secondsToAdd) => {
  // Split the input string into hours and minutes
  let [hours, minutes] = time.split(':').map(Number);

  // Convert the total time to seconds
  let totalSeconds = hours * 3600 + minutes * 60 + secondsToAdd;

  // Calculate the new hours, minutes, and seconds
  let newHours = Math.floor(totalSeconds / 3600) % 24;
  let newMinutes = Math.floor((totalSeconds % 3600) / 60);
  let newSeconds = totalSeconds % 60;

  // Format the result as "hh:mm:ss"
  let newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes
    .toString()
    .padStart(2, '0')}:${newSeconds.toString().padStart(2, '0')}`;

  return newTime;
};

export const ConvertHours = (hour) => {
  const h = parseInt(hour.split(':')[0]);
  const min = parseInt(hour.split(':')[1].split(' ')[0]);
  const period = hour
    .split(':')[1]
    .split(' ')[1]
    .toLowerCase();

  return [h, min, period];
};

export const setDBTime = (val, date = new Date()) => {
  const convertToArray = ConvertHours(val);
  const hour =
    convertToArray[2].toLowerCase() === 'pm' && convertToArray[0] !== 12
      ? convertToArray[0] + 12
      : convertToArray[2].toLowerCase() === 'am' && convertToArray[0] === 12
      ? '0'
      : convertToArray[0];
  return dayjs(date)
    .set('hour', hour)
    .set('minute', convertToArray[1]);
};

export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const secondsToMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60);

  return minutes;
};

export const removeString = (value) => {
  return parseFloat(JSON.stringify(value).replace(/[^0-9.]/g, ''), 10);
};

export const mobilities = [
  {
    id: 0,
    tag: 'amb_tier',
    name: 'Ambulatory',
  },
  {
    id: 1,
    tag: 'wc_tier',
    name: 'Wheelchair',
  },
  {
    id: 2,
    tag: 'gur_tier',
    name: 'Stretcher/Gurney',
  },
  {
    id: 3,
    tag: 'd2d_tier',
    name: 'Door to door',
  },
];

// convert time 1:00 AM to minutes
export const parseTime = (time) => {
  const [hoursMinutes, modifier] = time.split(' ');
  let [hours, minutes] = hoursMinutes.split(':').map(Number);

  // Convert 12-hour time to 24-hour format
  if (modifier === 'PM' && hours !== 12) hours += 12;
  if (modifier === 'AM' && hours === 12) hours = 0;

  return hours * 60 + minutes; // Convert time to total minutes
};

export const zeroPointOneMile = 16.0934;

export const ChargedBy = ['Fixed Amount', 'Percentage'];

export const UnitFeePercent = ['of ride fee', 'of subtotal fee'];

export const UnitFee = [
  'per hour',
  'per liter',
  'per minute',
  'per 5 minutes',
  'per 15 minutes',
  'per 30 minutes',
  'per person',
  'per trip',
  'per use',
];

export const cleanAmountValue = (value) => {
  let newAmount = value.toString().replace(/\s/g, '');
  newAmount = newAmount.replaceAll('$', '');
  newAmount = newAmount.replaceAll('%', '');

  return newAmount;
};

export const computeTotalFee = (booking) => {
  return Number.parseFloat((booking.ride_fee + booking.total_addl_fee_usd_cents) / 100).toFixed(2);
};

/*
 * start value ex: 12:30 AM
 * end value ex: 12:30 AM
 * changed values are start or end
 * date is a value used to convert the times into javascript datetime object ex: 2024-04-15
 * result 12:30 AM -> 1:30 AM
 * this whill also return "reset" whill will reset to 12:00 AM -> 1:00 AM if value input is invalid
 */

export const adjustTimeRange = (
  start,
  end,
  changed = 'start',
  date,
  originalTime = null // unchanged time from DB
) => {
  try {
    if (start && end) {
      let sTime = setDBTime(start, date);
      let eTime = setDBTime(end, date);
      if (changed === 'start') {
        if (originalTime) {
          // this is the original time before changes

          const pastStartTime = new Date(originalTime.start);
          const pastEndTime = new Date(originalTime.end);

          sTime = new Date(sTime);
          let minutes = diffInMinutes(pastStartTime, sTime);

          while (willOverflowNextDay(pastEndTime, minutes)) {
            minutes -= 1;
          }

          pastEndTime.setMinutes(pastEndTime.getMinutes() + minutes);

          const min = pastEndTime.getMinutes() < 10 ? `0${pastEndTime.getMinutes()}` : pastEndTime.getMinutes();
          const hr = pastEndTime.getHours() % 12;

          return `${hr === 0 ? '12' : hr}:${min} ${getAMPMFromDate(pastEndTime)}`; // hh:mm AM/PM
        }
      } else if (sTime >= eTime) {
        if (changed === 'start') {
          eTime = new Date(eTime);
          sTime = new Date(sTime);

          // we need to check if start time is over 11 PM
          // we can't add 1hr to end time in this case
          if (sTime.getHours() === 23) {
            eTime.setHours(sTime.getHours());
            if (sTime.getMinutes() >= 45) {
              eTime.setMinutes(59);
            } else {
              eTime.setMinutes(sTime.getMinutes() + 15);
            }
          } else {
            eTime.setMinutes(sTime.getMinutes() + 15);
          }

          const min = eTime.getMinutes() < 10 ? `0${eTime.getMinutes()}` : eTime.getMinutes();
          const hr = eTime.getHours() % 12;
          return `${hr === 0 ? '12' : hr}:${min} ${getAMPMFromDate(eTime)}`; // hh:mm AM/PM
        }
        // else if (changed === 'end') {
        //   eTime = new Date(eTime);
        //   sTime = new Date(sTime);
        //   console.log(sTime);
        //   if (eTime.getHours() === 0) {
        //     sTime.setHours(0);
        //     sTime.setMinutes(0);

        //     return 'reset';
        //   } else {
        //     if (eTime.getHours() === 23) {
        //       sTime.setHours(eTime.getHours());
        //       if (eTime.getMinutes() >= 45) {
        //         eTime.setMinutes(59);
        //       } else {
        //         eTime.setMinutes(eTime.getMinutes() - 15);
        //       }
        //     } else {
        //       eTime.setMinutes(eTime.getMinutes() - 15);
        //     }
        //   }

        //   const min = eTime.getMinutes() < 10 ? `0${eTime.getMinutes()}` : eTime.getMinutes();
        //   const hr = eTime.getHours() % 12;
        //   return `${hr === 0 ? '12' : hr}:${min} ${getAMPMFromDate(eTime)}`; // hh:mm AM/PM
        // }
      }
    }
    return null;
  } catch (e) {
    console.log(e);
    return 'reset';
  }
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');

  const normalized = cleaned.length === 11 && cleaned.startsWith('1') ? cleaned.slice(1) : cleaned;

  if (normalized.length === 10) {
    // Format the cleaned number as (XXX) XXX-XXXX
    const formatted = normalized.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return formatted;
  }

  return phoneNumber;
};
