/* eslint-disable react/no-array-index-key, consistent-return, camelcase */

import React from 'react';
import { convertTo12HourFormat } from '../../../lib/common';
import styles from './driver-info.module.scss';

const DriverInfo = ({ driver, uniqueKey, onMouseEnter, onMouseLeave }) => {
  const handleMouseEnter = () => {
    onMouseEnter(uniqueKey);
  };

  const handleMouseLeave = () => {
    onMouseLeave(uniqueKey);
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id={uniqueKey}
      className={styles.driverInfoContainer}
    >
      <div className={styles.row} style={{ justifyContent: 'space-between' }}>
        <div>
          <div className={styles.row}>
            <div className={styles.bold}>Name:&nbsp;</div>
            <div>{`${driver.driver_firstname} ${driver.driver_lastname}`}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.bold}>Driver license:&nbsp;</div>
            <div>{driver.driver_license}</div>
          </div>
          <div className={styles.row} style={{ marginBottom: 0 }}>
            <div className={styles.bold}>Training:&nbsp;</div>
            <div>{driver.driver_training}</div>
          </div>
        </div>
        {driver.driver_photo && (
          <div className={styles.img}>
            <img src={driver.driver_photo} alt={driver.driver_firstname} />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <div className={styles.bold}>Credential:&nbsp;</div>
        <div>
          {driver.driver_broker_certification?.map(({ insurance_name, is_active }, key) => {
            if (is_active) return `${key > 0 ? ', ' : ''}${insurance_name}`;
            return null;
          })}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.bold}>Schedule:&nbsp;</div>
        <div className="text-left">
          <DriverScheduel schedules={driver.driver_schedule} />
        </div>
      </div>
    </div>
  );
};

const DriverScheduel = ({ schedules }) => {
  if (!schedules) return null;
  return (
    <>
      {schedules?.map((sched, index) => {
        if (sched.schedule.length === 0) return null;
        return (
          <div key={index}>
            {sched.day}:{' '}
            {sched.schedule?.map((time, int) => {
              return (
                <span key={int}>
                  {convertTo12HourFormat(time.start_time)} to {convertTo12HourFormat(time.end_time)}
                  {sched.schedule[int + 1] && ', '}
                </span>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default DriverInfo;
