// import './App.css';
/* eslint-disable */
import * as React from "react";
import {
  //   PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  // Canvas,
  StyleSheet,
  usePDF,
  Font
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf"
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: "bold"
    }
  ]
});

const styles = StyleSheet.create({
  rowFlexDirection: {
    flexDirection: "row"
  },
  paddingTop10pt: {
    paddingTop: "10pt"
  },
  paddingTop20pt: {
    paddingTop: "20pt"
  },
  paddingTop30pt: {
    paddingTop: "30pt"
  },
  paddingLeft10pt: {
    paddingLeft: "10pt"
  },
  paddingBottom20pt: {
    paddingBottom: "20pt"
  },
  paddingBottom10pt: {
    paddingBottom: "10pt"
  },
  paddingLeft20pt: {
    paddingLeft: "20pt"
  },
  paddingRight20pt: {
    paddingRight: "20pt"
  },
  paddingRight10pt: {
    paddingRight: "10pt"
  },
  padding5pt: {
    padding: "5px"
  },
  bold: {
    fontFamily: "Open Sans",
    fontWeight: "bold"
  },
  normalFont: {
    fontStyle: "normal"
  },
  fontSize11pt: {
    fontSize: "11pt"
  },
  fontSize9pt: {
    fontSize: "9pt"
  }
});

const MyDoc = props => {
  const [groupTrip] = React.useState([
    ...(props.tripList.reduce(
      (r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r,
      []
    ) || [])
  ]);
  let tripCount = 0;
  return (
    <Document>
      {groupTrip.map((group, pageNum) => (
        <React.Fragment key={Math.random()}>
          <Page
            pageNumber={pageNum}
            size="A4"
            style={[
              styles.paddingTop20pt,
              styles.paddingBottom20pt,
              styles.paddingRight20pt,
              styles.paddingLeft20pt,
              styles.fontSize11pt
            ]}
          >
            <View style={{ textAlign: "center" }}>
              <Text style={{ fontSize: "16pt" }}>
                {props.date} Driver Manifest
              </Text>
              <Text style={{ fontSize: "12pt" }}>
                {props.driverObj.driver_team_name ? (
                  <>
                    Drivers:{" "}
                    {props.driverObj.driver_team.map(
                      ({ driver_firstname, driver_lastname }, index) =>
                        `${driver_firstname} ${driver_lastname}${
                          index < props.driverObj.driver_team.length - 1
                            ? ", "
                            : " "
                        }`
                    )}
                  </>
                ) : (
                  <>Driver: {props.driverObj.name}, </>
                )}
                Vehicle:{" "}
                {`${props.shuttleObj.make} ${props.shuttleObj.model} ${
                  props.shuttleObj.license_plate
                }`}
              </Text>
              <Text
                style={
                  ({ fontWeight: "normal", fontSize: "12pt" },
                  styles.paddingBottom20pt)
                }
              >
                {props.data}
              </Text>
            </View>

            {group.map((tripListObj, index) => {
              if (!tripListObj.trip.bookings[0]) {
                tripCount = tripCount + 1;
                return;
              } else {
              }
              return (
                <React.Fragment key={Math.random()}>
                  <React.Fragment key={Math.random()}>
                    <View style={[{ height: "30%" }, { display: "flex" }]}>
                      <View
                        style={[
                          styles.paddingBottom20pt,
                          { textAlign: "center" }
                        ]}
                      >
                        <Text style={[styles.bold, { fontSize: "13pt" }]}>
                          TRIP {index - tripCount + pageNum * 3 + 1}
                        </Text>
                      </View>
                      <View style={[styles.rowFlexDirection]}>
                        <View
                          style={[
                            styles.paddingRight20pt,
                            { width: "50%", lineHeight: "1.5" }
                          ]}
                        >
                          <View style={[styles.normalFont, styles.fontSize9pt]}>
                            <View>
                              <Text>
                                <Text style={styles.bold}>
                                  {`${
                                    tripListObj.trip.bookings[0]
                                      .scheduled_pickup_time
                                  } pickup`}
                                </Text>
                                {`, ${
                                  tripListObj.trip.bookings[0].pickup_address
                                }`}
                              </Text>
                            </View>
                            <View>
                              <Text>
                                <Text style={styles.bold}>
                                  {`${
                                    tripListObj.trip.bookings[0]
                                      .scheduled_dropoff_time
                                  } drop-off`}
                                </Text>
                                {`, ${
                                  tripListObj.trip.bookings[0].dropoff_address
                                }`}
                              </Text>
                            </View>
                            <View>
                              <Text>
                                <Text style={styles.bold}>Rider: </Text>
                                {`${
                                  tripListObj.trip.bookings[0]
                                    .passenger_firstname
                                } ${
                                  tripListObj.trip.bookings[0]
                                    .passenger_lastname
                                }, ${tripListObj.trip.bookings[0].mobility_assistance.join(
                                  ","
                                )}`}
                                {tripListObj.trip.bookings[0]
                                  .additional_passenger
                                  ? `, +${
                                      tripListObj.trip.bookings[0]
                                        .additional_passenger
                                    }`
                                  : ""}
                              </Text>
                            </View>
                            <View>
                              <Text>
                                <Text style={styles.bold}>Phone: </Text>
                                {tripListObj.trip.bookings[0].passenger_phone}
                              </Text>
                            </View>
                            <View style={[styles.rowFlexDirection]}>
                              <Text style={[{ display: "flex" }, styles.bold]}>
                                Arrival time:
                              </Text>
                              <View
                                style={{
                                  flex: 1,
                                  borderBottom: "0.5pt solid black",
                                  borderTop: "0pt",
                                  borderLeft: "0pt",
                                  borderRight: "0pt"
                                }}
                              />
                            </View>
                            <View style={[styles.rowFlexDirection]}>
                              <Text style={[{ display: "flex" }, styles.bold]}>
                                Actual pickup time:
                              </Text>
                              <View
                                style={{
                                  flex: 1,
                                  borderBottom: "0.5pt solid black",
                                  borderTop: "0pt",
                                  borderLeft: "0pt",
                                  borderRight: "0pt"
                                }}
                              />
                            </View>
                            <View style={[styles.rowFlexDirection]}>
                              <Text style={[{ display: "flex" }, styles.bold]}>
                                Odometer reading at pickup:
                              </Text>
                              <View
                                style={{
                                  flex: 1,
                                  borderBottom: "0.5pt solid black",
                                  borderTop: "0pt",
                                  borderLeft: "0pt",
                                  borderRight: "0pt"
                                }}
                              />
                            </View>

                            <View style={[styles.rowFlexDirection]}>
                              <Text style={[{ display: "flex" }, styles.bold]}>
                                Actual drop-off time:
                              </Text>
                              <View
                                style={{
                                  flex: 1,
                                  borderBottom: "0.5pt solid black",
                                  borderTop: "0pt",
                                  borderLeft: "0pt",
                                  borderRight: "0pt"
                                }}
                              />
                            </View>
                            <View style={[styles.rowFlexDirection]}>
                              <Text style={[{ display: "flex" }, styles.bold]}>
                                Odometer reading at drop-off:
                              </Text>
                              <View
                                style={{
                                  flex: 1,
                                  borderBottom: "0.5pt solid black",
                                  borderTop: "0pt",
                                  borderLeft: "0pt",
                                  borderRight: "0pt"
                                }}
                              />
                            </View>
                            <View style={[styles.rowFlexDirection]}>
                              <Text style={[{ display: "flex" }, styles.bold]}>
                                No-show time:
                              </Text>
                              <View
                                style={{
                                  flex: 1,
                                  borderBottom: "0.5pt solid black",
                                  borderTop: "0pt",
                                  borderLeft: "0pt",
                                  borderRight: "0pt"
                                }}
                              />
                            </View>
                            <View>
                              <Text>
                                <Text style={styles.bold}>
                                  Admin to driver note:{" "}
                                </Text>
                                {tripListObj.trip.bookings[0].admin_note}
                              </Text>
                            </View>
                          </View>
                        </View>

                        <View
                          style={[
                            styles.paddingLeft20pt,
                            styles.fontSize9pt,
                            { width: "50%" }
                          ]}
                        >
                          <View style={{ height: "100%" }}>
                            <View
                              style={{
                                width: "100%",
                                height: "38%",
                                border: "0.5pt solid #000000",
                                marginBottom: "10px"
                              }}
                            >
                              <Text style={styles.padding5pt}>
                                <Text style={styles.bold}>
                                  Rider Signature:
                                </Text>{" "}
                                ask rider to sign in the box
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "100%",
                                height: "38%",
                                border: "0.5pt solid #000000"
                              }}
                            >
                              <Text style={styles.padding5pt}>
                                <Text style={styles.bold}>Driver Note: </Text>if
                                needed, add a note about the trip below
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </React.Fragment>

                  <View
                    fixed
                    style={[
                      styles.fontSize9pt,
                      styles.paddingTop20pt,
                      { position: "absolute", bottom: 20, right: 20 }
                    ]}
                  >
                    <View>
                      <Text style={{ fontWeight: "normal", fontSize: "9pt" }}>
                        Page {pageNum + 1}
                      </Text>
                    </View>
                  </View>
                </React.Fragment>
              );
            })}
          </Page>
        </React.Fragment>
      ))}
    </Document>
  );
};

export function PdfTemplate(props) {
  const [instance, updateInstance] = usePDF({ document: MyDoc(props) });

  if (instance.loading) return <div />;

  if (instance.error) return <div>{error}</div>;

  setTimeout(() => {
    const aTag = document.getElementById("pdf-trip");
    if (aTag) {
      aTag.click();
      props.setIsDownloadTripPdf(false);
    }
  }, 200);

  return (
    <>
      {props.tripList.length > 0 ? (
        <a
          id="pdf-trip"
          href={instance.url}
          download={`${props.driverObj.driver_team_name ||
            props.driverObj.name}_${props.date}`}
        >
          <i className="fa fa-file-pdf-o pl-1" style={{ color: "#4c5667" }} />
        </a>
      ) : (
        <i
          onClick={() => {
            props.displayNotification("No trips found!");
          }}
          className="fa fa-file-pdf-o pl-1"
          style={{ color: "#4c5667" }}
        />
      )}
    </>
  );
}
