import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    setState: ["payload"],
    fetchServicesList: ["payload"],
    resetServicesList: null
  },
  { prefix: "SERVICES_" }
);

export const types = Types;
export default Creators;
