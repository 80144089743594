/* eslint-disable camelcase */
import { all, takeEvery, put, call } from "redux-saga/effects";

import {
  fetchBookingsList,
  fetchBookingInfoByID,
  checkValidateBookingEditTime,
  sendRequestPhoneVerifyCode,
  verifyUserPhone,
  cancelBooking,
  populateBookingEditTime,
  // uploadImageToS3,
  // createNewBooking,
  editBooking,
  fetchLabel,
  recreateBooking
} from "../../../services/rider-services/bookings";
import { types } from "./actions";
import { types as commonTypes } from "../../common/actions";

function compare(a, b) {
  const genreA = a.adjusted_pickup_time.unix_epoch;
  const genreB = b.adjusted_pickup_time.unix_epoch;

  let comparison = 0;
  if (genreA < genreB) {
    comparison = 1;
  } else if (genreA > genreB) {
    comparison = -1;
  }
  return comparison;
}

export function* FETCH_BOOKINGS_LIST({ payload }) {
  // console.log(Date.now());
  if (!payload.bIsIterate)
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        loading: true
      }
    });

  const response = yield call(fetchBookingsList, {
    future: payload.future,
    start_time: payload.start_time,
    end_time: payload.end_time
  });

  if (response) {
    if (response.status === "success") {
      const bookingList = response.data.sort(compare);
      yield put({
        type: types.SET_STATE,
        payload: {
          bookingList,
          newBookingStatus: false,
          bGotoBookingList: null
        }
      });
    }
  }

  if (!payload.bIsIterate)
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        loading: false
      }
    });
}

export function* FETCH_LABEL() {
  const response = yield call(fetchLabel);

  if (response.status === 1) {
    if (response !== "Fleet not found!") {
      yield put({
        type: types.SET_STATE,
        payload: {
          label: response.data.fleet_name
        }
      });
    }
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }
}

export function* FETCH_BOOKING_INFO_BY_ID({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(fetchBookingInfoByID, payload);
  // console.log(response);
  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bookingInfoById: response.data
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* UPDATE_EXIST_BOOKING({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(editBooking, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bGotoBookingList: true
      }
    });
  } else if (response.error_code === "PICKUPTIMEUNAVAILABLE") {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: `${response.message} Next available time is ${
          response.booking_pickup_date
        },  ${response.next_available_time}.`
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}
/*
export function* CREATE_NEW_BOOKING({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  reactLocalStorage.setObject("newUserInfo", {});

  const respForS3 = yield call(uploadImageToS3, payload.photoObj);
  const respForDLS3 = yield call(uploadImageToS3, payload.dl_photoObj);
  // console.log("respForS3 = ", JSON.stringify(respForS3));
  // console.log("respForDLS3 = ", JSON.stringify(respForDLS3));
  if (respForS3.status && respForDLS3.status) {
    payload = {
      ...payload,
      booking_photo: respForS3.desc.Key,
      dl_photo: respForDLS3.desc.Key
    };
    // console.log(JSON.stringify(payload));
    const response = yield call(createNewBooking, payload);

    if (response.status === "success") {
      yield put({
        type: types.SET_STATE,
        payload: {
          newBookingStatus: true
        }
      });
    } else {
      yield put({
        type: types.SET_STATE,
        payload: {
          newBookingStatus: false
        }
      });

      yield put({
        type: commonTypes.SET_STATE,
        payload: {
          isError: true,
          errContent: response.message.message
        }
      });
    }
  }
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}
*/
export function* RESET_BOOKING_INFO() {
  yield put({
    type: types.SET_STATE,
    payload: {
      bookingInfoById: {}
    }
  });
}

export function* CHECK_VALIDATE_BOOKING_EDIT_TIME({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(checkValidateBookingEditTime, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bCancelQuestionSection: !response.data.valid_booking_edit_time
      }
    });
  } else {
    // eslint-disable-next-line no-lonely-if
    if (response.message.error_code === "VALIDATIONFAIL") {
      yield put({
        type: types.SET_STATE,
        payload: {
          bCancelQuestionSection: true
        }
      });
    } else {
      yield put({
        type: commonTypes.SET_STATE,
        payload: {
          isError: true,
          errContent: response.message.message
        }
      });
    }
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* RESET_BOOKING_CHANGE_STATUS() {
  yield put({
    type: types.SET_STATE,
    payload: {
      bCancelQuestionSection: null,
      bPhoneVerifyQuestionSection: null,
      bCancelComfirmSection: null,
      bGotoBookingList: null,
      bCancelBookingForReschedule: null,
      bookingIDForReschedule: null
    }
  });
}

export function* SEND_REQUEST_PHONE_VERIFY_CODE({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(sendRequestPhoneVerifyCode, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bCancelQuestionSection: null,
        bPhoneVerifyQuestionSection: true,
        bCancelComfirmSection: null
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* VERIFY_USER_PHONE({ payload }) {
  console.log(payload);
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const param = {
    phone: payload.phone,
    phone_verification_code: payload.phone_verification_code,
    booking_id: payload.booking_id
  };

  const response = yield call(verifyUserPhone, param);
  // console.log(response);
  if (response.status === "success") {
    if (payload.bookingStatus === 0) {
      yield put({
        type: types.SET_STATE,
        payload: {
          bCancelQuestionSection: null,
          bPhoneVerifyQuestionSection: null,
          bCancelComfirmSection: true
        }
      });
    } else {
      const respForCancelBooking = yield call(cancelBooking, payload);
      if (respForCancelBooking.status === "success") {
        yield put({
          type: types.SET_STATE,
          payload: {
            bCancelQuestionSection: null,
            bPhoneVerifyQuestionSection: null,
            bCancelComfirmSection: true
          }
        });
      } else {
        yield put({
          type: commonTypes.SET_STATE,
          payload: {
            isError: true,
            errContent: respForCancelBooking.message.message
          }
        });
      }
    }
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* POPULATE_BOOKING_EDIT_TIME({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const response = yield call(populateBookingEditTime, payload);

  if (response.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bookingInfoById: response.data
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: response.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* RESET_BOOKING_LIST() {
  yield put({
    type: types.SET_STATE,
    payload: {
      bookingList: []
    }
  });
}

export function* CANCEL_BOOKING({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const respForCancelBooking = yield call(cancelBooking, payload);

  if (respForCancelBooking.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bGotoBookingList: true,
        bCancelComfirmSection: true
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: respForCancelBooking.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* CANCEL_BOOKING_FOR_RESCHEDULE({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const respForCancelBooking = yield call(cancelBooking, payload);

  if (respForCancelBooking.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bCancelBookingForReschedule: true
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: respForCancelBooking.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* RECREATE_BOOKING({ payload }) {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });

  const res = yield call(recreateBooking, payload);
  // console.log("RECREATE_BOOKING : ", res);
  if (res.status === "success") {
    yield put({
      type: types.SET_STATE,
      payload: {
        bookingIDForReschedule: res.data.booking_id,
        bCancelBookingForReschedule: null
      }
    });
  } else {
    yield put({
      type: commonTypes.SET_STATE,
      payload: {
        isError: true,
        errContent: res.message.message
      }
    });
  }

  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* SET_SPINNER() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: true
    }
  });
}

export function* UNSET_SPINNER() {
  yield put({
    type: commonTypes.SET_STATE,
    payload: {
      loading: false
    }
  });
}

export function* SET_USER_INFO({ payload }) {
  // console.log(payload);
  const { existingUserInfo, selectedMobilityAssistance } = payload;
  yield put({
    type: types.SET_STATE,
    payload: {
      existingUserInfo,
      selectedMobilityAssistance
    }
  });
}

export function* RESET_GOTO_BOOKINGLIST_FLAG() {
  yield put({
    type: types.SET_STATE,
    payload: {
      bGotoBookingList: null
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.FETCH_BOOKINGS_LIST, FETCH_BOOKINGS_LIST),
    // takeEvery(types.CREATE_NEW_BOOKING, CREATE_NEW_BOOKING),
    takeEvery(types.FETCH_LABEL, FETCH_LABEL),
    takeEvery(types.RESET_BOOKING_INFO, RESET_BOOKING_INFO),
    takeEvery(
      types.CHECK_VALIDATE_BOOKING_EDIT_TIME,
      CHECK_VALIDATE_BOOKING_EDIT_TIME
    ),
    takeEvery(types.RESET_BOOKING_CHANGE_STATUS, RESET_BOOKING_CHANGE_STATUS),
    takeEvery(
      types.SEND_REQUEST_PHONE_VERIFY_CODE,
      SEND_REQUEST_PHONE_VERIFY_CODE
    ),
    takeEvery(types.VERIFY_USER_PHONE, VERIFY_USER_PHONE),
    takeEvery(types.POPULATE_BOOKING_EDIT_TIME, POPULATE_BOOKING_EDIT_TIME),
    takeEvery(types.UPDATE_EXIST_BOOKING, UPDATE_EXIST_BOOKING),

    takeEvery(types.FETCH_BOOKING_INFO_BY_ID, FETCH_BOOKING_INFO_BY_ID),
    takeEvery(types.RESET_BOOKING_LIST, RESET_BOOKING_LIST),
    takeEvery(types.CANCEL_BOOKING, CANCEL_BOOKING),
    takeEvery(types.SET_SPINNER, SET_SPINNER),
    takeEvery(types.UNSET_SPINNER, UNSET_SPINNER),
    takeEvery(
      types.CANCEL_BOOKING_FOR_RESCHEDULE,
      CANCEL_BOOKING_FOR_RESCHEDULE
    ),
    takeEvery(types.RECREATE_BOOKING, RECREATE_BOOKING),
    takeEvery(types.SET_USER_INFO, SET_USER_INFO),
    takeEvery(types.RESET_GOTO_BOOKINGLIST_FLAG, RESET_GOTO_BOOKINGLIST_FLAG),
    null
  ]);
}
