import { createReducer } from "reduxsauce";

import { types } from "./actions";

const INITIAL_STATE = {
  loading: false,
  memberList: [],
  communityList: [],
  memberInfoById: {},
  memberObj: {},
  newMemberStatus: false,
  bUpdateStatus: null,
  errorMsg: ""
};

export default createReducer(INITIAL_STATE, {
  [types.SET_STATE]: (state, { payload }) => ({ ...state, ...payload })
});
